import { useEffect } from "react"
import { formatDateAndTime } from "../../utils/helpers"
import './surpriseTrips.scss'
import '../../css/common.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function SurpriseCheckoutSummary({ surpriseTripPayload }) {
    return (
        <div className="surprise_checkout_wrapper">
            <div className="input_section_title" style={{ margin: '0' }}>Order Summary</div>
            <div className="surprise_section_subtitle">Please review the information below</div>
            <div className="surprise_checkout_title">Selected Dates</div>
            <div className="surprise_checkout_value">{formatDateAndTime(surpriseTripPayload.firstDateRange[0], 'Date')} - {formatDateAndTime(surpriseTripPayload.firstDateRange[1], 'Date')}</div>
            {surpriseTripPayload.secondDateRange ? <div className="surprise_checkout_value">{formatDateAndTime(surpriseTripPayload.secondDateRange[0], 'Date')} - {formatDateAndTime(surpriseTripPayload.secondDateRange[1], 'Date')}</div> : null}
            {surpriseTripPayload.thirdDateRange ? <div className="surprise_checkout_value">{formatDateAndTime(surpriseTripPayload.thirdDateRange[0], 'Date')} - {formatDateAndTime(surpriseTripPayload.thirdDateRange[1], 'Date')}</div> : null}
            <div className="surprise_divider" />
            <div className="surprise_checkout_title">Trip Details</div>
            <div className='icon_subtext_card_info' style={{ marginBottom: '12px' }}>
                <FontAwesomeIcon icon="fa-regular fa-user" />
                <div><strong>Adults: </strong> {surpriseTripPayload.adult}</div>
            </div>
            <div className='icon_subtext_card_info' style={{ marginBottom: '12px' }}>
                <FontAwesomeIcon icon="fa-regular fa-user" />
                <div><strong>Children: </strong> {surpriseTripPayload.child}</div>
            </div>
            <div className='icon_subtext_card_info' style={{ marginBottom: '12px' }}>
                <FontAwesomeIcon icon="fa-solid fa-bed" />
                <div><strong>Rooms: </strong> {surpriseTripPayload.rooms}</div>
            </div>
            <div className='icon_subtext_card_info' style={{ marginBottom: '12px' }}>
                <FontAwesomeIcon icon="fa-solid fa-location-dot" />
                <div><strong>Origin: </strong> {surpriseTripPayload.originName}</div>
            </div>
            <div className='icon_subtext_card_info' style={{ marginBottom: '12px' }}>
                <FontAwesomeIcon icon="fa-solid fa-hand-holding-dollar" />
                <div><strong>Trip Budget: </strong> ${surpriseTripPayload.budget}</div>
            </div>
            <div className="surprise_divider" />
            <div className="surprise_checkout_title">Trip Preferences</div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ fontWeight: '600', fontSize: '14px' }}>Receive Trip Details:</div>
                <div className="surprise_tag_checkout" style={{ margin: '0 0 0 5px', color: '#FD905E', border: '1px solid #FD905E' }}>{surpriseTripPayload.receiveDetailsDate}</div>
            </div>
            <div style={{ fontSize: '12px', marginBottom: '12px' }}>This is when we will email you all the details regarding your trip</div>
            <div style={{ fontWeight: '600', fontSize: '15px', marginBottom: '5px' }}>Additional Preferences</div>
            {surpriseTripPayload.preferences ? <div className="surprise_checkout_value" style={{ marginBottom: '12px', whiteSpace: 'pre-line' }}>"{surpriseTripPayload.preferences}"</div> : null}
            <div className="surprise_tags_container">
                {surpriseTripPayload.tags.map((tag, i) => {
                    return (
                        <div key={i} className="surprise_tag_checkout">{tag}</div>
                    )
                })}
            </div>
        </div>
    )
}

export default SurpriseCheckoutSummary;
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from '../../components/Navbar/navbar';
import UserTripCard from '../../components/UserTripCard/userTripCard';
import { fetchItinerary, fetchPreviousTrips, getBookedFlightData, getBookedHotelData } from '../../context/api';
import { Context } from '../../context/context';
import { sendGACustomEvent, sendGAPageEvent } from '../../utils/helpers';
import './account.scss';
import Footer from '../../components/Footer/footer';

function Account() {
    const { state, setState } = useContext(Context)
    const [loading, setLoading] = useState(false)
    const [userData, setUserData] = useState(state.userInfo)
    const [previousTrips, setPreviousTrips] = useState([])
    const [userFirstName, setUserFirstName] = useState('')
    const navigate = useNavigate()

    useEffect(() => {
        sendGAPageEvent({ hitType: 'pageview', page: '/account', title: 'Landing on account page' })
        setLoading(true)
        const user = JSON.parse(sessionStorage.getItem('user'))
        user && setUserData(user)
        setUserFirstName(user.given_name)
        const payload = { user_id: sessionStorage.getItem('user_id') }
        fetchPreviousTrips(payload)
            .then((res) => {
                let tripData = res.filter((trip) => (new Date(trip.start_date) <= new Date()) && (trip.hotel_booking_id || trip.flight_booking_id))
                setPreviousTrips(tripData)
                setLoading(false)
            })
    }, [])

    const handleOnCellClick = (trip) => {
        sendGACustomEvent({
            category: 'Button Click',
            action: 'Navigates user to precreated itinerary',
            label: 'Itinerary Cell Click'
        })

        setLoading(true)
        setState(prevState => ({
            ...prevState,
            flightDetails: {},
            hotelDetails: {},
        }))
        // save trip details in session storage
        sessionStorage.setItem('tripDetails', JSON.stringify(trip))
        setState(prevState => ({ ...prevState, tripDetails: trip }))
        fetchItinerary({ trip_id: trip.trip_id })
            .then(itRes => {
                if (itRes) {
                    setState(prevState => ({ ...prevState, tripItinerary: itRes }))
                    setLoading(false)
                    if (trip.flight_booking_id) {
                        getBookedFlightData({ id: trip.flight_booking_id })
                            .then((flightResponseData) => {
                                setState(prevState => ({
                                    ...prevState,
                                    flightDetails: flightResponseData,
                                }))
                            })
                    }
                    if (trip.hotel_booking_id) {
                        getBookedHotelData({ id: trip.hotel_booking_id })
                            .then((hotelResponseData) => {
                                setState(prevState => ({
                                    ...prevState,
                                    hotelDetails: hotelResponseData,
                                }))
                            })
                    }
                    navigate(`/trip/${trip.trip_id}`)
                }
            })
    }

    return (
        <div className='account_page'>
            <NavBar getLoadingState={val => setLoading(val)} />
            <div className='container py-5'>
                <p className='fs-1 fw-bolder mb-1'>My Account</p>

                <div className='card info_card user_info_card'>
                    <p className='fs-2 fw-bold'>User Information</p>
                    <div className='row mx-2'>
                        <div className='col-sm-12 col-lg-6 mb-3'>
                            <div className="input-group-lg">
                                <label className="form-label fw-bold">First Name</label>
                                <input
                                    type="text"
                                    value={userData.given_name}
                                    disabled
                                    className="form-control"
                                    aria-label="Sizing example input"
                                    aria-describedby="inputGroup-sizing-lg"
                                />
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-6 mb-3'>
                            <div className="input-group-lg">
                                <label className="form-label fw-bold">Last Name</label>
                                <input
                                    type="text"
                                    value={userData.family_name}
                                    disabled
                                    className="form-control"
                                    aria-label="Sizing example input"
                                    aria-describedby="inputGroup-sizing-lg"
                                />
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-6 mb-3'>
                            <div className="input-group-lg">
                                <label className="form-label fw-bold">Email</label>
                                <input
                                    type="text"
                                    value={userData.email}
                                    disabled
                                    className="form-control"
                                    aria-label="Sizing example input"
                                    aria-describedby="inputGroup-sizing-lg"
                                />
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-6 mb-3'>
                            <div className="input-group-lg">
                                <label className="form-label fw-bold">Phone Number</label>
                                <input
                                    type="text"
                                    value={userData.phone_number}
                                    disabled
                                    className="form-control"
                                    aria-label="Sizing example input"
                                    aria-describedby="inputGroup-sizing-lg"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='card info_card trip_info_card'>
                    <p className='fs-2 fw-bold'>Previous Trips</p>
                    <div className='row mx-2'>
                        <div className='row'>
                            {previousTrips.length > 0 ?
                                previousTrips.map((trip, index) => (
                                    <div className='col-lg-6 p-2' key={index}>
                                        <UserTripCard trip={trip} handleOnClick={() => { handleOnCellClick(trip) }} />
                                    </div>
                                )) :
                                <p className='fs-5 fw-bold text-center my-5'>KICKSTART YOUR VOYAGE WITH THE POWER OF AI</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Account
import { useNavigate, useSearchParams } from "react-router-dom";
import HeaderLayout from "../../layouts/headerLayout"

import './internationalGuidedTours.scss'
import GuidedToursForm from "./guidedTourForm";
import surpriseBanner from "../../assets/surprise_banner.jpg"
import DefaultButton from "../../components/DefaultButton/defaultButton";
import { isUserLoggedIn, sendGACustomEvent } from "../../utils/helpers";
import CustomModal from "../../components/CustomModal/customModal";
import { GoogleLogin } from "@react-oauth/google";
import Logo from "../../assets/FullLogo_NoBuffer.png"
import { useState, useContext } from "react";
import { login } from "../../context/api";
import { PropagateLoader } from 'react-spinners'
import { Context } from '../../context/context'

function InternationalGuidedTours() {
    const [searchParams, setSearchParams] = useSearchParams()
    const step = searchParams.get('step') || ''
    const [loginLoading, setLoginLoading] = useState(false)
    const { state, setState } = useContext(Context)
    const [showLoginModal, setShowLoginModal] = useState(false)
    const navigate = useNavigate()

    const onLogin = (credentialResponse) => {
        setLoginLoading(true)
        const payload = {
            token: credentialResponse.credential,
            client_id: credentialResponse.clientId,
            session_id: sessionStorage.getItem('user_session_id'), // this should only be present when user is unregistered
            trip_id: sessionStorage.getItem('user_session_id') ? state.tripDetails.trip_id : null
        }
        login(payload)
            .then((res) => {
                // save user details in session storage
                sessionStorage.setItem('user', JSON.stringify(res))
                setState({ ...state, userInfo: res })

                // save the user id in session storage:
                sessionStorage.setItem('user_id', res.user_id)

                // we need to update the trip id here b/c before login we had an "unregistered trip_id", and this is an updated one:
                let tripSession = JSON.parse(sessionStorage.getItem('tripDetails'))

                if (tripSession) {
                    tripSession['trip_id'] = res.trip_id
                    // update session storage:
                    sessionStorage.setItem('tripDetails', JSON.stringify(tripSession))
                }

                setLoginLoading(false)
                setShowLoginModal(false)
                navigate('/international_guided_tours')
                setSearchParams({ step: 'form' })
            })
    }

    return (
        <HeaderLayout>
            <div className="guided_tours_trips_wrapper">
                {step === '' ?
                    <div className="guided_tours_trip_info_wrapper">
                        <div>
                            <div className="guided_tours_page_title" style={{ fontSize: '40px' }}>International Guided Tours</div>
                        </div>
                        <div className="guided_tours_info_bullets">
                            <ol>
                                <li>Share your travel dreams and preferences to kickstart your journey with us!</li>
                                <li>Our partners will work with you to create your perfect, customized trip from start to finish.</li>
                                <li>Relax while we handle every detail of your trip, ensuring a perfect and unique experience for you.</li>
                            </ol>
                        </div>
                        <DefaultButton
                            buttonLabel="Plan my trip"
                            buttonStyle={{ padding: '10px', width: '10rem' }}
                            onClick={() => {
                                isUserLoggedIn() ?
                                    setSearchParams({ step: 'form' }) :
                                    setShowLoginModal(true)
                                sendGACustomEvent({ category: 'Button Click', action: `Navigates user into international_guided_tours flow`, label: 'On International Trips Form' })
                            }}
                        />
                        <div>
                            <img src={surpriseBanner} height='285px' />
                        </div>
                    </div>
                    : step === 'form' ? <GuidedToursForm /> : null}
            </div>
            <CustomModal showModal={showLoginModal} centered={true} setShowModal={setShowLoginModal} onHide={() => setShowLoginModal(false)}>
                <div className='google_signin_modal'>
                    {loginLoading ? <PropagateLoader color="#FD905E" style={{ padding: '30px 0' }} /> :
                        <div className='google_signin_modal'>
                            <img src={Logo} width="auto" height="80px" style={{ marginLeft: '30px', marginBottom: '24px' }} />
                            <div className='google_signin_heading'>Sign in to continue </div>
                            <div style={{ fontSize: '18px', fontWeight: '300', color: '#636363', marginBottom: '24px' }}>Your perfect vacation awaits</div>
                            <GoogleLogin
                                onSuccess={credentialResponse => { onLogin(credentialResponse) }}
                                onError={() => { console.log('Login Failed') }}
                            />
                        </div>}
                </div>
            </CustomModal>
        </HeaderLayout>
    )
}

export default InternationalGuidedTours

import React from 'react'
import './tripCollectionCard.scss'
import defaultImage from '../../assets/default_placeholder.png'

function TripCollectionCard({ destination }) {
    return (
        <div className="card trip_collection_card">
            <img
                src={destination.destination_image_url ? destination.destination_image_url : defaultImage}
                alt={destination.destination_name}
                className="trip_collection_card_image"
            />
            <div className="card_body">
                <h5 className="card_title">{destination.destination_name}</h5>
            </div>
        </div>
    )
}

export default TripCollectionCard
import { useNavigate } from 'react-router-dom';
import cablePuppy from '../../assets/Charco - Pet.png'
import DefaultButton from '../../components/DefaultButton/defaultButton';
import HeaderLayout from '../../layouts/headerLayout';
import { useEffect } from 'react';
import { sendGAPageEvent } from '../../utils/helpers';

function ErrorPage() {
    const navigate = useNavigate()

    useEffect(() => {
        sendGAPageEvent({ hitType: 'pageview', page: '/error', title: 'Landing on error page' })
    }, [])

    return (
        <HeaderLayout>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 32px', height: '83vh' }}>
                <img src={cablePuppy} style={{ width: '20rem', marginTop: '5rem' }} />
                <div style={{ fontSize: '2.3rem', fontWeight: '600', margin: '20px' }}>Something went wrong!</div>
                <div style={{ fontSize: '1.5rem', fontWeight: '500', margin: '20px', color: 'gray' }}>Please try again later</div>
                <DefaultButton buttonLabel="Go Back" width='500px !important' onClick={() => navigate(-1)} />
            </div>
        </HeaderLayout>
    )
}

export default ErrorPage
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { RenderHotelCheckDetails, RenderRatingStars, calculateNights, formatDate } from '../../utils/helpers';

import './hotelReservationCard.scss'
import '../../css/common.scss'

function HotelReservationCard({ reservationDetails }) {
    const calculateFees = (tax, fees) => {
        let taxAmount = Number(tax)
        let feeAmount = Number(fees)
        let final = taxAmount + feeAmount
        let finalToString = final.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
        return finalToString
    }
    return (
        <div className='hotel_reservation_wrapper'>
            <div className='hotel_reservation_title'>{reservationDetails.name}</div>
            <RenderRatingStars rating={reservationDetails.rating} />
            <div className='hotel_reservation_desc'>{reservationDetails.description.slice(0, reservationDetails.description.indexOf('.') === -1 ? reservationDetails.description.length : reservationDetails.description.indexOf('.'))}.</div>
            <div className='reservation_info_cell'>
                <div style={{ fontWeight: 600 }}>Confirmation Code: {reservationDetails.booking_reference}</div>
                <div style={{ fontSize: '12px', marginBottom: '12px', color: '#636363' }}>Confirmation Date: {formatDate(reservationDetails.confirmed_at)}</div>
                <div className='icon_subtext_card_info'>
                    <FontAwesomeIcon icon="fa-solid fa-map-location-dot" style={{ marginRight: '8px' }} />
                    <div>{reservationDetails.location.line_one}, {reservationDetails.location.city_name}, {reservationDetails.location.postal_code}</div>
                </div>
                <div className='icon_subtext_card_info'>
                    <FontAwesomeIcon icon="fa-solid fa-calendar-days" style={{ marginRight: '8px' }} />
                    <div><strong>Check In : </strong><RenderHotelCheckDetails date={formatDate(reservationDetails.check_in_date)} time={reservationDetails.check_in_time} instruction="before" /></div>
                </div>
                <div className='icon_subtext_card_info' style={{ marginBottom: '12px' }}>
                    <FontAwesomeIcon icon="fa-solid fa-calendar-days" style={{ marginRight: '8px' }} />
                    <div><strong>Check Out : </strong><RenderHotelCheckDetails date={formatDate(reservationDetails.check_out_date)} time={reservationDetails.check_out_time} instruction="before" /></div>
                </div>
                <div className='icon_subtext_card_info' style={{ marginBottom: '12px' }}>
                    <FontAwesomeIcon icon="fa-solid fa-moon" />
                    <div><strong>Nights: </strong>{calculateNights(reservationDetails.check_in_date, reservationDetails.check_out_date)}</div>
                </div>
                <div className='icon_subtext_card_info' style={{ marginBottom: '12px' }}>
                    <FontAwesomeIcon icon="fa-regular fa-user" />
                    <div><strong>Guests: </strong> {reservationDetails.guests.length}</div>
                </div>
                <div className='icon_subtext_card_info'>
                    <FontAwesomeIcon icon="fa-solid fa-bed" />
                    <div><strong>Rooms: </strong> {reservationDetails.rooms}</div>
                </div>
                {
                    reservationDetails.key_collection &&
                    <div>
                        <div style={{ fontWeight: '700', marginBottom: '8px' }}>Key Collection Instructions</div>
                        <div style={{ fontSize: '14px', marginBottom: '12px' }}>{reservationDetails.key_collection.instructions}</div>
                    </div>
                }
            </div>
            <div className='reservation_info_cell'>
                <div className='reservation_info_cell_title'>Guest Details</div>
                <div className='icon_subtext_card_info'>
                    <FontAwesomeIcon icon="fa-regular fa-user" style={{ marginRight: '8px' }} />
                    <div><strong>Travellers: </strong></div>
                </div>
                <div style={{ marginBottom: '10px' }}>
                    {
                        reservationDetails.guests.map((guest) => (
                            <div style={{ marginLeft: '8px' }}>{guest.given_name}, {guest.family_name}</div>
                        ))
                    }
                </div>
                <div className='icon_subtext_card_info'>
                    <FontAwesomeIcon icon="fa-solid fa-phone" style={{ marginRight: '8px' }} />
                    <div><strong>Phone: </strong>{reservationDetails.phone_number}</div>
                </div>
                <div className='icon_subtext_card_info'>
                    <FontAwesomeIcon icon="fa-regular fa-envelope" style={{ marginRight: '8px' }} />
                    <div><strong>Email: </strong><span style={{ fontSize: '14px' }}>{reservationDetails.email}</span></div>
                </div>
            </div>
            <div className='reservation_info_cell'>
                <div className='reservation_info_cell_title'>Your Receipt</div>
                <div className='reservation_price_breakdown'>Price: ${reservationDetails.base_amount}</div>
                <div className='reservation_price_breakdown'>Fees & Taxes: ${calculateFees(reservationDetails.tax_amount, reservationDetails.fee_amount)}</div>
                <div className='reservation_price_breakdown'>Accomodation Fees (Due on site): ${reservationDetails.due_at_accommodation_amount}</div>
                <div style={{ fontWeight: 600 }}>Total Paid: ${reservationDetails.total_amount}</div>
            </div>
            <div style={{ marginTop: '18px' }}>
                <Accordion className='itinerary_accordian'>
                    {
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Terms & Policies</Accordion.Header>
                            <Accordion.Body>
                                {
                                    reservationDetails.cancellation_timeline.length > 0 &&
                                    <div style={{ marginBottom: '12px' }}>
                                        <div style={{ fontWeight: '800', marginBottom: '5px' }}>Cancelation Timeline</div>
                                        <div className="timeline">
                                            {reservationDetails.cancellation_timeline.map((timelineItem, index) => (
                                                <li key={index}>
                                                    <p className="timeline-date">{new Date(timelineItem.before).toLocaleDateString()}</p>
                                                    <div className="timeline-content">
                                                        <div className='refund_amount' style={{ fontSize: '14px' }}>
                                                            <strong>Refundable Amount : </strong>${timelineItem.refund_amount}
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </div>
                                    </div>
                                }
                                {reservationDetails.conditions.map((condition, i) => {
                                    return (
                                        <div key={i}>
                                            <div style={{ fontWeight: '800', marginBottom: '5px' }}>{condition.title}</div>
                                            <div style={{ fontSize: '12px', marginBottom: '16px' }}>{condition.description}</div>
                                        </div>
                                    )
                                })}
                            </Accordion.Body>
                        </Accordion.Item>
                    }
                </Accordion>
            </div>
        </div>
    )
}

export default HotelReservationCard;
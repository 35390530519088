import { useEffect, useState } from 'react';
import './activityBookingCard.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RenderRatingStars } from '../../utils/helpers';

function ActivityBookingCard({ activity }) {
    const [imgObject, setImgObject] = useState()
    const [activityDuration, setActivityDuration] = useState('')

    useEffect(() => {
        if (activity.images.variants.length) {
            setImgObject(activity.images.variants[7])
        }
        setActivityDuration(formatActivityDuration(activity.itinerary.duration))
    }, [])

    const formatActivityDuration = (duration) => {
        if (duration.fixed_duration_in_minutes) {
            let dur = Math.ceil(duration.fixed_duration_in_minutes / 60)
            let type = getTimeType(dur)
            return `${dur} ${type}`
        } else if (duration.variable_duration_from_minutes) {
            let from = Math.ceil(duration.variable_duration_from_minutes / 60)
            let to = Math.ceil(duration.variable_duration_to_minutes / 60)
            let fromType = getTimeType(from)
            let toType = getTimeType(to)
            if (from !== to) {
                return `${from} ${fromType} - ${to} ${toType}`
            } else {
                return `${from} ${fromType}`
            }
        }
    }

    const getTimeType = (val) => {
        return (val < 2) ? 'minutes' : val === 1 ? 'hour' : 'hours'
    }

    const truncateTitle = (title, maxLength) => {
        if (title.length > maxLength) {
            return title.substring(0, maxLength) + "...";
        }
        return title
    }

    return (
        <div className='activity_booking_wrapper' onClick={() => window.open(activity.product_url, "_blank")}>
            {imgObject ? <img src={imgObject.url} width={imgObject.width} height={imgObject.height} style={{ borderRadius: '8px 8px 0 0' }} /> : null}
            <div className='activity_info'>
                <div className='activity_card_title' onClick={() => window.open(activity.product_url, "_blank")}>{truncateTitle(activity.title, 40)}</div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                    <FontAwesomeIcon icon="fa-solid fa-clock" style={{ fontSize: '0.8rem', marginRight: '5px', color: '#035EDD' }} />
                    <div style={{ fontSize: '0.8rem' }}>{activityDuration}</div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: '0.8rem', marginRight: '5px', color: '#035EDD' }} /> */}
                    {/* <div style={{ fontSize: '0.8rem' }}>{activity.reviews.combined_average_rating}</div> */}
                    <div style={{ fontSize: '0.8rem' }}><RenderRatingStars rating={(activity.reviews.combined_average_rating).toFixed(1)} /></div>
                </div>
                <div style={{ fontSize: '12px', marginTop: 'auto' }}><span style={{ fontWeight: '600' }}>From ${(activity.from_price).toFixed(2)}</span> per person</div>
            </div>
        </div>
    )
}

export default ActivityBookingCard;
import { formatDateAndTime, formatDuration } from "../../utils/helpers";

import './flightJourneyCard.scss';
import '../../css/flightCommon.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function FlightJourneyCard({ flight }) {
    const DOTS_LENGTH = 5;
    const DOT_UNICODE = '\u{1B7C}'
    const BAGGAGE_TYPE = {
        'carry_on': 'carry-on',
        'checked': 'checked bag'
    }

    const showDestination = (timeValue, airportName, airlineName) => {
        return (
            <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '10px', height: '10px', backgroundColor: '#FD905E', borderRadius: '50%', marginRight: '10px' }} />
                    <div style={{ display: 'flex', fontWeight: '600' }}>
                        <div style={{ width: 'max-content' }}>{formatDateAndTime(timeValue, 'Time')}</div>
                        <div style={{ margin: '0 5px' }}>{DOT_UNICODE}</div>
                        <div className="journey_airport_name">{airportName}</div>
                    </div>
                </div>
                <div style={{ fontSize: '14px', color: '#636363', marginLeft: '20px' }}>{airlineName}</div>
            </div>
        )
    }

    const renderLayoverDurationBanner = (duration, airportName) => {
        return (
            <div className="layover_duration">
                {formatDuration(duration)} layover at {airportName}
            </div>
        )
    }

    const formatBaggage = (bag) => {
        let condition = ''
        if (bag.quantity > 0) {
            condition = `${bag.quantity} ${BAGGAGE_TYPE[bag.type]} allowed`
        } else {
            condition = `No ${BAGGAGE_TYPE[bag.type]} allowed`
        }
        return condition
    }

    const showJourney = (journey) => {
        return (
            <div>
                {journey.segments.map((segment, i) => {
                    return (
                        <div key={i}>
                            {<div>{segment.origin.layover_duration ? renderLayoverDurationBanner(segment.origin.layover_duration, segment.origin.name) : null}</div>}
                            <div style={{ display: 'flex' }} className="flight_journey_wrapper">
                                <div className="journey_logo_wrapper">
                                    <img src={segment.marketing_carrier.logo_symbol_url} className='airline_logo' />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div>
                                        <div>{showDestination(segment.departing_at, segment.origin.name, segment.marketing_carrier.name)}</div>
                                        {/* renders the vertical dots */}
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '2px' }}>
                                                {Array.from({ length: DOTS_LENGTH }).map(i => {
                                                    return <div key={i} style={{
                                                        width: '5px',
                                                        height: '5px',
                                                        marginBottom: (i === DOTS_LENGTH) ? '0' : '3px',
                                                        backgroundColor: '#eee',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }} />
                                                })}
                                            </div>
                                            <div style={{ fontSize: '14px', color: '#636363' }}>Travel Time: {formatDuration(segment.duration)}</div>
                                        </div>
                                        <div>{showDestination(segment.arriving_at, segment.destination.name)}</div>
                                        <div className="extra_details_container">
                                            <div style={{ display: 'flex' }}>
                                                <div>
                                                    <span>{journey.fare_brand_name}</span>
                                                    <span style={{ margin: '0 5px' }}>{DOT_UNICODE}</span>
                                                </div>
                                                {segment.aircraft ?
                                                    <div>
                                                        <span>{segment.aircraft.name}</span>
                                                        <span style={{ margin: '0 5px' }}>{DOT_UNICODE}</span>
                                                    </div> : null}
                                                <div>{segment.passengers[0].cabin_class_marketing_name}</div>
                                            </div>
                                            {segment.passengers[0].baggages.length ?
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>{segment.passengers[0].baggages.map((bag, i) => {
                                                    return (
                                                        <div key={i} style={{ display: 'flex', alignItems: 'center', marginTop: '2px' }}>
                                                            <FontAwesomeIcon icon="fa-solid fa-suitcase-rolling" style={{ marginRight: '5px' }} />
                                                            <div style={{ marginRight: '8px' }}>{formatBaggage(bag)}</div>
                                                        </div>
                                                    )
                                                })}
                                                </div> :
                                                <div>
                                                    <div key={i} style={{ display: 'flex', alignItems: 'center', marginTop: '2px' }}>
                                                        <FontAwesomeIcon icon="fa-solid fa-suitcase-rolling" style={{ marginRight: '5px' }} />
                                                        <div style={{ marginRight: '8px' }}>No carry-on included in fare</div>
                                                    </div>
                                                    <div key={i} style={{ display: 'flex', alignItems: 'center', marginTop: '2px' }}>
                                                        <FontAwesomeIcon icon="fa-solid fa-suitcase-rolling" style={{ marginRight: '5px' }} />
                                                        <div style={{ marginRight: '8px' }}>No checked bag included in fare</div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="flight_journey_top_cell">
                <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'space-evenly' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '30px' }}>
                        <div style={{ width: '100%', fontSize: '35px', fontWeight: '800' }}>{flight.outbound.segments[0].origin.iata_code}</div>
                        <div style={{ fontSize: '12px', fontWeight: '500' }}>{flight.outbound.segments[0].origin.name}</div>
                    </div>
                    <div className='airline_arrow_container' style={{ width: '25%' }}>
                        <div className="arrow_right" />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '30px' }}>
                        <div style={{ width: '100%', fontSize: '35px', fontWeight: '800' }}>{<div>{flight.outbound.segments[flight.outbound.segments.length - 1].destination.iata_code}</div>}</div>
                        <div style={{ fontSize: '12px' }}>{flight.inbound.segments[0].origin.name}</div>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'baseline', marginTop: '16px', paddingBottom: '8px' }}>
                    <div style={{ marginRight: '12px' }}>
                        <FontAwesomeIcon icon="fa-regular fa-circle-check" style={{ color: '#008000', marginRight: '5px' }} />
                        <span style={{ fontSize: '12px' }}>Flight Updates</span>
                    </div>
                    <div style={{ marginRight: '12px' }}>
                        <FontAwesomeIcon icon="fa-regular fa-circle-xmark" style={{ color: '#FF0000', marginRight: '5px' }} />
                        <span style={{ fontSize: '12px' }}>Refundable</span>
                    </div>
                    <div style={{ fontSize: '12px' }}>Carbon Emissions: {flight.total_emissions_kg} kg</div>
                </div>
            </div>
            <div className="flight_journey_bottom_cell">
                <div style={{ fontSize: '18px' }}>
                    <span style={{ marginRight: '5px', fontWeight: '600' }}>Departing Flight:</span><span>{formatDateAndTime(flight.inbound.segments[0].departing_at, 'Date')}</span>
                </div>
                <div style={{ borderBottom: '1px solid #D9D9D9', marginBottom: '16px' }}>{showJourney(flight.outbound)}</div>
                <div style={{ fontSize: '18px' }}>
                    <span style={{ marginRight: '5px', fontWeight: '600' }}>Arriving Flight:</span><span>{formatDateAndTime(flight.outbound.segments[0].arriving_at, 'Date')}</span>
                </div>
                <div>{showJourney(flight.inbound)}</div>
            </div>
        </div>
    )
}

export default FlightJourneyCard;
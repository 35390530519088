import ErrorPage from "../pages/ErrorPage/errorPage"
import Home from "../pages/Home/home"
import UserDashboard from "../pages/UserDashboard/userDashboard"
import Itinerary from "../pages/Itinerary/itinerary"
import Blogs from "../pages/Blogs/blogs"
import Flight from "../pages/Flight/flight"
import Hotel from "../pages/Hotel/hotel"
import Contact from "../pages/Contact/contact"
import Account from "../pages/Account/account"
import About from "../pages/About"
import Privacy from "../pages/Privacy"
import TermsOfuse from "../pages/TermsOfUse"
import SurpriseTrips from "../pages/SurpriseTrips/surpriseTrips"
import InternationalGuidedTours from "../pages/InternationalGuidedTours/internationalGuidedTours"

const routes = [
    {
        path: '/',
        element: <Home />
    },
    {
        path: '/account',
        element: <Account />
    },
    {
        path: '/contact',
        element: <Contact />
    },
    {
        path: '/blogs',
        element: <Blogs />
    },
    {
        path: '/blogs/:blogId',
        element: <Blogs />
    },
    {
        path: 'trip/:tripId',
        element: <Itinerary />
    },
    {
        path: '/my_trips',
        element: <UserDashboard />
    },
    {
        path: '/flights/:step',
        element: <Flight />
    },
    {
        path: '/hotels/:step',
        element: <Hotel />
    },
    {
        path: '/error',
        element: <ErrorPage />
    },
    {
        path: '/about',
        element: <About />
    },
    {
        path: '/privacy',
        element: <Privacy />
    },
    {
        path: '/terms',
        element: <TermsOfuse />
    },
    {
        path: '/surprise',
        element: <SurpriseTrips />
    },
    {
        path: '/international_guided_tours',
        element: <InternationalGuidedTours />
    }
]

export default routes
import React from 'react'
import ClipLoader from "react-spinners/ClipLoader";

const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "#FFFFFF",
};

function ReactLoader({ loading, size }) {
    return (
        <ClipLoader
            loading={loading}
            cssOverride={override}
            size={size}
            aria-label="Loading Spinner"
            data-testid="loader"
        />
    )
}

export default ReactLoader
import { useNavigate, useSearchParams } from "react-router-dom";
import SurpriseTripsExploreCard from "../../components/SurpriseTripsExploreCard/surpriseTripsExploreCard"
import HeaderLayout from "../../layouts/headerLayout"

import './surpriseTrips.scss'
import Surprise from "./surprise";
import surpriseBanner from "../../assets/surprise_banner.jpg"
import DefaultButton from "../../components/DefaultButton/defaultButton";
import CustomModal from "../../components/CustomModal/customModal";
import { GoogleLogin } from "@react-oauth/google";
import Logo from "../../assets/FullLogo_NoBuffer.png"
import { useContext, useState } from "react";
import { PropagateLoader } from 'react-spinners'
import { Context } from '../../context/context'
import { login } from "../../context/api"
import { isUserLoggedIn, sendGACustomEvent } from "../../utils/helpers";

function SurpriseTrips() {
    const [searchParams, setSearchParams] = useSearchParams()
    const typeType = searchParams.get('type') || ''
    const step = searchParams.get('step') || ''
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [loginLoading, setLoginLoading] = useState(false)
    const { state, setState } = useContext(Context)
    const navigate = useNavigate()

    const onLogin = (credentialResponse) => {
        setLoginLoading(true)
        const payload = {
            token: credentialResponse.credential,
            client_id: credentialResponse.clientId,
            session_id: sessionStorage.getItem('user_session_id'), // this should only be present when user is unregistered
            trip_id: sessionStorage.getItem('user_session_id') ? state.tripDetails.trip_id : null
        }
        login(payload)
            .then((res) => {
                // save user details in session storage
                sessionStorage.setItem('user', JSON.stringify(res))
                setState({ ...state, userInfo: res })

                // save the user id in session storage:
                sessionStorage.setItem('user_id', res.user_id)

                // we need to update the trip id here b/c before login we had an "unregistered trip_id", and this is an updated one:
                let tripSession = JSON.parse(sessionStorage.getItem('tripDetails'))

                if (tripSession) {
                    tripSession['trip_id'] = res.trip_id
                    // update session storage:
                    sessionStorage.setItem('tripDetails', JSON.stringify(tripSession))
                }

                setLoginLoading(false)
                setShowLoginModal(false)
                navigate('/surprise')
                setSearchParams({ type: 'complete' })
            })
    }

    return (
        <HeaderLayout>
            <div className="surprise_trips_wrapper" style={{ maxWidth: step === 'checkout' ? '1200px' : '' }}>
                {typeType === '' ?
                    <div className="surprise_trip_info_wrapper">
                        <div>
                            <div className="surprise_page_title" style={{ fontSize: '40px' }}>Let's plan your surprise trip</div>
                        </div>
                        <div className="surprise_info_bullets">
                            <ol>
                                <li>Provide us with the necessary details and preferences, then get ready for an exhilarating surprise.</li>
                                <li>At your chosen time before departure, receive your flight tickets, accommodation details, and a complete itinerary.</li>
                                <li>We will drop some clues on how you can prepare for your extraordinary adventure</li>
                                <li>Experience the thrill of a mystery destination, knowing every detail has been meticulously planned for you.</li>
                            </ol>
                        </div>
                        <DefaultButton
                            buttonLabel="Start Planning"
                            buttonStyle={{ padding: '10px', width: '10rem' }}
                            onClick={() => {
                                isUserLoggedIn() ?
                                    setSearchParams({ type: 'complete' }) :
                                    setShowLoginModal(true)
                                sendGACustomEvent({ category: 'Button Click', action: `Navigates user into surprise flow`, label: 'On Explore Surprise Trips' })
                            }}
                        />
                        <div>
                            <img src={surpriseBanner} height='285px' />
                        </div>
                    </div>
                    : typeType === 'complete' ? <Surprise /> : null}
            </div>
            <CustomModal showModal={showLoginModal} centered={true} setShowModal={setShowLoginModal} onHide={() => setShowLoginModal(false)}>
                <div className='google_signin_modal'>
                    {loginLoading ? <PropagateLoader color="#FD905E" style={{ padding: '30px 0' }} /> :
                        <div className='google_signin_modal'>
                            <img src={Logo} width="auto" height="80px" style={{ marginLeft: '30px', marginBottom: '24px' }} />
                            <div className='google_signin_heading'>Sign in to continue </div>
                            <div style={{ fontSize: '18px', fontWeight: '300', color: '#636363', marginBottom: '24px' }}>Your perfect vacation awaits</div>
                            <GoogleLogin
                                onSuccess={credentialResponse => { onLogin(credentialResponse) }}
                                onError={() => { console.log('Login Failed') }}
                            />
                        </div>}
                </div>
            </CustomModal>
        </HeaderLayout>
    )
}

export default SurpriseTrips

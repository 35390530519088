import './itinerary.scss'

import { GoogleLogin } from '@react-oauth/google'
import { useContext, useEffect, useState } from "react"
import Accordion from 'react-bootstrap/Accordion'
import { useLocation, useNavigate } from 'react-router-dom'
import BookFlightGraphic from '../../assets/book_flight_graphic2.png'
import BookHotelGraphic from '../../assets/book_hotel_graphic1.png'
import BookedHotelCard from '../../components/BookedHotelCard/bookedHotelCard'
import Customizer from '../../components/Customizer/customizer'
import DefaultButton from '../../components/DefaultButton/defaultButton'
import EventCard from "../../components/EventCard/eventCard"
import FlightBookedCard from '../../components/FlightBookedCard/flightBookedCard'
import Footer from '../../components/Footer/footer'
import SimpleMapPage from '../../components/GoogleMaps/mapPage'
import NavBar from '../../components/Navbar/navbar'
import SearchAutoComplete from '../../components/SearchAutoComplete/searchAutoComplete'
import { customizeItinerary, fetchAllTourOptions, fetchCityAndAirports, fetchItinerary, getBookedFlightData, getBookedHotelData, login } from '../../context/api'
import { Context } from "../../context/context"
import { formatDate, isUserLoggedIn, sendGACustomEvent, sendGAPageEvent } from '../../utils/helpers'
import CustomModal from '../../components/CustomModal/customModal'
import Logo from "../../assets/FullLogo_NoBuffer.png"
import Loading from '../Loading/loading'

function Itinerary() {
    const { state, setState } = useContext(Context)
    const [itineraryTitle, setItineraryTitle] = useState('')
    const [loading, setLoading] = useState(false)
    const [tripDetails, setTripDetails] = useState(state.tripDetails)
    const [message, setMessage] = useState('')
    const [mapView, setMapView] = useState(false)
    const [originIataCode, setOriginIataCode] = useState('')
    const [searchOriginName, setSearchOriginName] = useState('')
    const [citiesAndAirports, setCitiesAndAirports] = useState([])
    const [customizing, setCustomizing] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [target, setTarget] = useState('')

    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (location.pathname.includes('trip')) {
            fetchCityAndAirports()
                .then((res) => {
                    setCitiesAndAirports(res)
                })
        }
    }, [])

    useEffect(() => {
        sendGAPageEvent({ hitType: 'pageview', page: '/trip/:tripId', title: 'Landing on the itinerary page' })
        let deets = {}
        // if there aren't any trip details then fetch them and reshow itinerary
        if (Object.keys(tripDetails).length) {
            let tripDays = tripDetails.trip_days
            let destination = tripDetails.destination_name
            setItineraryTitle(`${tripDays} day trip to ${destination}`)
        } else {
            deets = JSON.parse(sessionStorage.getItem('tripDetails'))
            setTripDetails(deets)
        }
        // if there is no itinerary then fetch it
        if (!Object.keys(state.tripItinerary).length && Object.keys(tripDetails).length) {
            setState(prevState => ({
                ...prevState,
                flightDetails: {},
                hotelDetails: {},
            }))

            setLoading(true)
            fetchItinerary({ trip_id: tripDetails.trip_id })
                .then(itRes => {
                    if (itRes) {
                        let toursPayload = {
                            "destination_id": tripDetails.destination_id,
                            "itinerary": itRes,
                            "members": { "ADULT": tripDetails.adult },
                            "budget": 5000
                        }
                        fetchAllTourOptions(toursPayload)
                            .then(toursRes => {
                                setState({ ...state, tripItinerary: itRes })
                                if (tripDetails.flight_booking_id) {
                                    getBookedFlightData({ id: tripDetails.flight_booking_id })
                                        .then((flightResponseData) => {
                                            setState(prevState => ({
                                                ...prevState,
                                                flightDetails: flightResponseData,
                                            }))
                                        })
                                }
                                if (tripDetails.hotel_booking_id) {
                                    getBookedHotelData({ id: tripDetails.hotel_booking_id, email: "", reference: "" })
                                        .then((hotelResponseData) => {
                                            setState(prevState => ({
                                                ...prevState,
                                                hotelDetails: hotelResponseData,
                                            }))
                                        })
                                }
                                setLoading(false)
                            })
                    }
                })
        }
    }, [tripDetails])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setMessage('')
        }, 10000)
        return () => clearTimeout(timeout)
    }, [message])

    const onLogin = (credentialResponse) => {
        setLoading(true)
        const payload = {
            token: credentialResponse.credential,
            client_id: credentialResponse.clientId,
            session_id: sessionStorage.getItem('user_session_id'), // this should only be present when user is unregistered
            trip_id: sessionStorage.getItem('user_session_id') ? state.tripDetails.trip_id : null
        }
        login(payload)
            .then((res) => {
                // save user details in session storage
                sessionStorage.setItem('user', JSON.stringify(res))
                setState({ ...state, userInfo: res })

                // save the user id in session storage:
                sessionStorage.setItem('user_id', res.user_id)

                // we need to update the trip id here b/c before login we had an "unregistered trip_id", and this is an updated one:
                let tripSession = JSON.parse(sessionStorage.getItem('tripDetails'))

                if (tripSession) {
                    tripSession['trip_id'] = res.trip_id
                    // update session storage:
                    sessionStorage.setItem('tripDetails', JSON.stringify(tripSession))
                }

                setShowModal(false)
                setLoading(false)

                // reroute user based on their current location
                if (window.location.pathname === '/') {
                    navigate('/my_trips')
                } else if (target) {
                    navigate(target)
                }
            })
    }

    const handleCustomizeClick = (value) => {
        setCustomizing(true)
        const payload = {
            user_id: tripDetails.user_id,
            trip_id: tripDetails.trip_id,
            start_date: tripDetails.start_date,
            end_date: tripDetails.end_date,
            text: value
        }
        customizeItinerary(payload)
            .then((res) => {
                if (!containsCustomizations(res)) {
                    setMessage('🤔 Looks like we could not find anything. Please try searching something else')
                }
                setState(prevState => ({ ...prevState, tripItinerary: res }))
                setCustomizing(false)
            })
    }

    // go through each day and see if there are customizations:
    const containsCustomizations = (data) => {
        for (let i in data) {
            return data[i].some(item => item.customized === true)
        }
    }

    const handleOnSelectOrigin = (item) => {
        setOriginIataCode(item.id)
        setSearchOriginName(item.airport_name)
        const originDetailsString = JSON.stringify({ origin_iata_code: item.iata_code, origin_airport_name: item.airport_name })
        sessionStorage.setItem('originDetails', originDetailsString)
        return `${item.airport_name}`
    }

    return (
        <div>
            <NavBar getLoadingState={val => setLoading(val)} />
            {
                loading ?
                    <Loading spinnerStyle="classic" /> :
                    <div className='itinerary_wrapper'>
                        {(Object.keys(state.tripItinerary).length && Object.keys(tripDetails).length) ?
                            <div>
                                <div className='itinerary_banner' style={tripDetails.trip_image && { backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${tripDetails.trip_image})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                                    <div className='itinerary_title text-center'>{itineraryTitle}</div>
                                    <div className='itinerary_date'>{formatDate(tripDetails.start_date)} - {formatDate(tripDetails.end_date)}</div>
                                    <div style={{ color: '#ffffff', fontSize: '12px', marginBottom: '5px' }}>✨ Customize your itinerary by talking to our AI agent ✨</div>
                                    <Customizer placeholder={"Customize with AI"} onCustomizeClick={handleCustomizeClick} loading={customizing} />
                                    {message ? <div style={{ fontWeight: '500', marginTop: '16px' }}>{message}</div> : null}
                                </div>
                                <div className="itinerary_bottom_half">
                                    <div className='itinerary_side_cell itinerary_card_spacing'>
                                        <div className='flight_card'>
                                            {
                                                Object.keys(state.flightDetails).length ?
                                                    <FlightBookedCard flight={state.flightDetails} /> :
                                                    <div className='action_img_card'>
                                                        <div className="itinerary_img_styling">
                                                            <img src={BookFlightGraphic} style={{ width: '100%', borderRadius: '8px 8px 0 0' }} />
                                                        </div>
                                                        <div className='flight_action_card_bottom'>
                                                            <SearchAutoComplete
                                                                placeholder='Your Origin'
                                                                items={citiesAndAirports}
                                                                onSelect={handleOnSelectOrigin}
                                                                autoFocus={true}
                                                                maxResults={5}
                                                                onClear={() => setOriginIataCode('')}
                                                                usage='origin_search'
                                                            />
                                                            <DefaultButton
                                                                buttonLabel="Search Flights"
                                                                disabled={originIataCode == ''}
                                                                customButtonClass="secondary_button_styling"
                                                                onClick={() => {
                                                                    isUserLoggedIn() ?
                                                                        navigate('/flights/search') :
                                                                        setShowModal(true); setTarget('/flights/search');
                                                                    sendGACustomEvent({ category: 'Button Click', action: `Navigates user to search flights page`, label: 'On Search Flights button click' })
                                                                }}

                                                            />
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                        <div className='hotel_card mobile_hotel_card_display'>
                                            {
                                                Object.keys(state.hotelDetails).length ?
                                                    <BookedHotelCard hotel={state.hotelDetails} /> :
                                                    <div className='action_img_card'>
                                                        <div className="itinerary_img_styling">
                                                            <img src={BookHotelGraphic} style={{ width: '100%', borderRadius: '8px 8px 0 0' }} />
                                                        </div>
                                                        <div className='hotel_action_card_bottom'>
                                                            <DefaultButton
                                                                buttonLabel="Search Hotels"
                                                                buttonStyle={{ width: '100%', width: '16rem' }}
                                                                customButtonClass="secondary_button_styling"
                                                                onClick={() => {
                                                                    isUserLoggedIn() ?
                                                                        navigate('/hotels/search') :
                                                                        setShowModal(true); setTarget('/hotels/search');
                                                                    sendGACustomEvent({ category: 'Button Click', action: `navigates user to search hotels`, label: 'On search hotels button click' })
                                                                }}

                                                            />
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                        <div>
                                            <div className='switch_styling'>
                                                <div className="form-check form-switch">
                                                    <label className="form-check-label" style={{ fontWeight: '600' }} for="flexSwitchCheckChecked">Show Map</label>
                                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={mapView} onClick={() => { setMapView(prevState => !prevState) }} />
                                                </div>
                                            </div>
                                            {
                                                !mapView ?
                                                    <div className='event_cards_wrapper'>
                                                        {Object.keys(state.tripItinerary).map((i, itineraryIndex) => {
                                                            let continuousCount = itineraryIndex > 0 ? Object.keys(state.tripItinerary[i - 1]).length : 0;
                                                            return (
                                                                <div key={i} >
                                                                    <Accordion defaultActiveKey={i} className='itinerary_accordian'>
                                                                        <Accordion.Item eventKey={i}>
                                                                            <Accordion.Header>Day {i}: {formatDate(state.tripItinerary[i][0].trip_date)}</Accordion.Header>
                                                                            <Accordion.Body>
                                                                                {state.tripItinerary[i].map((day, index) => {
                                                                                    // continuousCount++;
                                                                                    return <EventCard key={index} event={day} id={continuousCount + (index + 1)} />;
                                                                                })}
                                                                            </Accordion.Body>
                                                                        </Accordion.Item>
                                                                    </Accordion>
                                                                </div>
                                                            )
                                                        })}
                                                    </div> :
                                                    <div className='map_view_outer_container_mobile'>
                                                        <div className='map_view_wrapper_mobile'>
                                                            <SimpleMapPage places={state.tripItinerary} />
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='itinerary_side_cell'>
                                        <div className='hotel_card desktop_hotel_card_display'>
                                            {
                                                Object.keys(state.hotelDetails).length ?
                                                    <BookedHotelCard hotel={state.hotelDetails} /> :
                                                    <div className='action_img_card'>
                                                        <div className="itinerary_img_styling">
                                                            <img src={BookHotelGraphic} style={{ width: '100%', borderRadius: '8px 8px 0 0' }} />
                                                        </div>
                                                        <div className='hotel_action_card_bottom'>
                                                            <DefaultButton
                                                                buttonLabel="Search Hotels"
                                                                buttonStyle={{ width: '100%', width: '16rem' }}
                                                                customButtonClass="secondary_button_styling"
                                                                onClick={() => {
                                                                    isUserLoggedIn() ?
                                                                        navigate('/hotels/search') :
                                                                        setShowModal(true); setTarget('/hotels/search');
                                                                    sendGACustomEvent({ category: 'Button Click', action: "navigates user to hotel search page", label: 'On Search Hotels button click' })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                        <div className='map_view_outer_container'>
                                            <div className='map_view_wrapper'>
                                                <SimpleMapPage places={state.tripItinerary} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : null}
                    </div>
            }
            <CustomModal showModal={showModal} centered={true} setShowModal={setShowModal} onHide={() => setShowModal(false)}>
                <div className='google_signin_modal'>
                    <img src={Logo} width="auto" height="80px" style={{ marginLeft: '30px', marginBottom: '24px' }} />
                    <div className='google_signin_heading'>Sign in to continue </div>
                    <div style={{ fontSize: '18px', fontWeight: '300', color: '#636363', marginBottom: '24px' }}>Your perfect vacation awaits</div>
                    <GoogleLogin
                        onSuccess={credentialResponse => { onLogin(credentialResponse) }}
                        onError={() => { console.log('Login Failed') }}
                    />
                </div>
            </CustomModal>
            <Footer />
        </div>
    )
}

export default Itinerary;
import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion'
import { emailRegex } from '../../utils/helpers';
import './passengerForm.scss'
import { PhoneInput } from 'react-international-phone';

const FlightPassengerForm = ({ passengers, setPassengerDetails }) => {
    const [formData, setFormData] = useState(passengers.map(passenger => ({
        id: passenger.id,
        phone_number: passenger.phone_number ? passenger.phone_number : '',
        email: passenger.email ? passenger.email : '',
        born_on: passenger.born_on ? passenger.born_on : '',
        title: passenger.title ? passenger.title : 'mr',
        gender: passenger.gender ? passenger.gender : 'm',
        family_name: passenger.family_name ? passenger.family_name : '',
        given_name: passenger.given_name ? passenger.given_name : ''
    })));
    const [isValidPhone, setIsValidPhone] = useState(false);

    function isValidInput(inputValue, dialCode) {
        const digitsOnly = inputValue.replace(/\D/g, '');
        const dialCodedigitsOnly = dialCode.replace(/\D/g, '');
        const digitsAfterDialCode = digitsOnly.substring(dialCodedigitsOnly.length);

        // Check if the remaining value has exactly 10 digits
        return digitsAfterDialCode.length === 10;
    }

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        let updatedFormData = [...formData];

        // Check if the input is for email
        if (name === 'email') {
            const isValidEmail = emailRegex.test(value);
            updatedFormData[index] = { ...updatedFormData[index], email: value };
            setFormData(updatedFormData);

        } else {
            updatedFormData[index][name] = value;
            setFormData(updatedFormData);
        }
    };

    const handlePhoneChange = (phone, index, inputValue) => {
        const updatedFormData = [...formData];
        updatedFormData[index] = { ...updatedFormData[index], phone_number: phone };
        setIsValidPhone(isValidInput(inputValue.inputValue, inputValue.country.dialCode))
        setFormData(updatedFormData);
    };



    useEffect(() => {
        setPassengerDetails(formData)
    }, [formData])

    return (
        <div>
            <Accordion defaultActiveKey={0} className='itinerary_accordian'>
                {formData.map((passenger, index) => (
                    <Accordion.Item eventKey={index} key={index}>
                        <Accordion.Header>Passenger {index + 1}</Accordion.Header>
                        <Accordion.Body>
                            <div key={index}>
                                <div className='title_gender_dob'>
                                    <div className='passenger_form_item_spacing'>
                                        <label htmlFor={`title_${index}`} className="form-label">Title</label>
                                        <select className="form-select" id={`title_${index}`} name="title" value={passenger.title} onChange={e => handleInputChange(e, index)}>
                                            <option value="">Select Title</option>
                                            <option value="mr">Mr</option>
                                            <option value="ms">Ms</option>
                                            <option value="mrs">Mrs</option>
                                            <option value="miss">Miss</option>
                                            <option value="dr">Dr</option>
                                        </select>
                                    </div>
                                    <div className='passenger_form_item_spacing'>
                                        <label htmlFor={`gender_${index}`} className="form-label">Gender</label>
                                        <select className="form-select" id={`gender_${index}`} name="gender" value={passenger.gender} onChange={e => handleInputChange(e, index)}>
                                            <option value="">Select Gender</option>
                                            <option value="m">Male</option>
                                            <option value="f">Female</option>
                                        </select>
                                    </div>
                                    <div className='passenger_form_item_spacing'>
                                        <label htmlFor={`born_on_${index}`} className="form-label">Date of Birth</label>
                                        <input type="date" className="form-control" id={`born_on_${index}`} name="born_on" value={passenger.born_on} onChange={e => handleInputChange(e, index)} />
                                    </div>
                                </div>
                                <div className='title_gender_dob'>
                                    <div className='passenger_form_item_spacing'>
                                        <label htmlFor={`family_name_${index}`} className="form-label">Family Name</label>
                                        <input type="text" className="form-control" id={`family_name_${index}`} name="family_name" value={passenger.family_name} onChange={e => handleInputChange(e, index)} />
                                    </div>
                                    <div className='passenger_form_item_spacing'>
                                        <label htmlFor={`given_name_${index}`} className="form-label">Given Name</label>
                                        <input type="text" className="form-control" id={`given_name_${index}`} name="given_name" value={passenger.given_name} onChange={e => handleInputChange(e, index)} />
                                    </div>
                                </div>
                                <div className='title_gender_dob'>
                                    <div className='passenger_form_item_spacing'>
                                        <label htmlFor={`phone_number_${index}`} className="form-label">Phone Number</label>
                                        <PhoneInput
                                            defaultCountry="us"
                                            className="form-control"
                                            name="phone_number"
                                            value={passenger.phone_number}
                                            onChange={(phone, inputValue) => handlePhoneChange(phone, index, inputValue)}
                                            style={!isValidPhone ? { border: "1px solid red", padding: '0px' } : { border: 'none', padding: '0px' }}
                                        />
                                        {!isValidPhone && (
                                            <small className="text-danger" style={{ fontSize: '10px' }}>Phone is not valid</small>
                                        )}                                   </div>
                                    <div className='passenger_form_item_spacing'>
                                        <label htmlFor={`email_${index}`} className="form-label">Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id={`email_${index}`}
                                            name="email"
                                            value={passenger.email}
                                            onChange={e => handleInputChange(e, index)}
                                            style={(passenger.email.length > 1 && !emailRegex.test(passenger.email)) ? { border: "1px solid red" } : {}}
                                        />
                                        {/* Conditional rendering for error message (optional) */}
                                        {passenger.email.length > 1 && !emailRegex.test(passenger.email) && (
                                            <small className="text-danger">Invalid email format</small>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
    );
};

export default FlightPassengerForm;

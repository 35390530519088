/*
 * Base Google Map example
 */
import React, { useState, useEffect } from 'react';

import GoogleMap from 'google-map-react';
import MarkerCard from './markerCard.jsx';

export default function SimpleMapPage({ places }) {
    const [locationList, setLocationList] = useState([])

    const [defaultProps, setDefaultProps] = useState({
        center: {
            lat: 37.79822,
            lng: -122.407471
        },
        zoom: 14
    });

    useEffect(() => {
        let tempList = []
        Object.keys(places).map((i) => {
            places[i].map((day, index) => {
                let locObj = { lat: day.latitude, lon: day.longitude, title: day.title, category: day.category }
                tempList.push(locObj)
            })
        })
        setLocationList(tempList)
        setDefaultProps({
            center: {
                lat: tempList[0].lat,
                lng: tempList[0].lon
            },
            zoom: 14
        })
    }, [])


    return (
        <>
            {locationList.length > 0 &&
                <GoogleMap
                    bootstrapURLKeys={{ key: 'AIzaSyAGg-A0guVcGmruz-MlH_af-PiufGOnyfk' }}
                    center={defaultProps.center}
                    zoom={defaultProps.zoom}
                    hoverDistance={30}
                >
                    {
                        locationList.map((place, index) => (
                            <MarkerCard key={index} lat={place.lat} lng={place.lon} index={`${index + 1}`} text={`${place.title}`} category={place.category} />
                        ))
                    }
                </GoogleMap>
            }
        </>
    );
}
import React, { useState } from 'react';
import './markerCard.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalizeFirstLetter } from '../../utils/helpers';

const MyGreatPlace = ({ index, text, category }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div className='marker p-2' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {
                isHovered ?
                    <div>
                        <div>{index}. {text}</div>
                        <div style={{ fontSize: '12px', color: '#636363', marginTop: '8px' }}><FontAwesomeIcon icon={category == 'restaurant' ? 'fa-solid fa-bowl-rice' : 'fa-solid fa-fire'} style={{ fontSize: '12px', color: '#FD905E', marginRight: '3px' }} /> {capitalizeFirstLetter(category)}</div>
                    </div>
                    : index
            }
        </div>
    );
};

export default MyGreatPlace;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { RenderRatingStars, convertTo12HourFormat, formatDate, sendGACustomEvent } from '../../utils/helpers';
import DefaultButton from '../DefaultButton/defaultButton';
import './hotelCard.scss';
import { useNavigate } from 'react-router-dom';

function HotelCard({ hotel, setHotelDetails, setView, setFetching }) {
    const [showFullDescription, setShowFullDescription] = useState(false);

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };

    const renderDescription = () => {
        if (showFullDescription || hotel.description.length <= 100) {
            return hotel.description;
        } else {
            return hotel.description.substring(0, 100) + '...';
        }
    };

    const navigate = useNavigate()

    return (
        <div className='hotel_wrapper_card'>
            <div className='hotel_image_wrapper'>
                <img
                    className='hotel_image'
                    src={hotel.photo[0].url}
                    alt={hotel.name}
                    width="275px"
                    height="200px"
                />
            </div>
            <div className='hotel_card_info_container'>
                <div className='hotel_title'>{hotel.name}</div>
                <div style={{ marginBottom: '10px' }}>
                    <RenderRatingStars rating={hotel.rating} />
                </div>
                <div className='hotel_info'>
                    {
                        hotel.description &&
                        <div>
                            {renderDescription()} &nbsp;
                            {hotel.description.length > 100 && (
                                <a className="see-more" onClick={toggleDescription} style={{ cursor: 'pointer' }}>
                                    {showFullDescription ? "See less" : "See more"}
                                </a>
                            )}
                        </div>
                    }
                </div>
                <div className='hotel_info sub_info_with_icon '>
                    <FontAwesomeIcon icon="fa-solid fa-map-location-dot" style={{ marginRight: '5px' }} />
                    <div>{hotel.location.line_one} , {hotel.location.city_name}</div>
                </div>
                <div className='hotel_info sub_info_with_icon '>
                    <FontAwesomeIcon icon="fa-solid fa-calendar-days" style={{ marginRight: '5px' }} />
                    <div><strong>Check In : </strong>{formatDate(hotel.check_in_date)} {hotel.check_in_time ? <span>after {convertTo12HourFormat(hotel.check_in_time)}</span> : null}</div>
                </div>
                <div className='hotel_info sub_info_with_icon'>
                    <FontAwesomeIcon icon="fa-solid fa-calendar-days" style={{ marginRight: '5px' }} />
                    <div><strong>Check Out : </strong>{formatDate(hotel.check_out_date)} {hotel.check_out_time ? <span>before {convertTo12HourFormat(hotel.check_out_time)}</span> : null}</div>
                </div>
            </div>
            <div className='price_container'>
                <div style={{ fontSize: '10px', fontWeight: 300 }}>{hotel.adults} {hotel.adults === 1 ? 'Adult' : 'Adults'}, {hotel.rooms} {hotel.rooms === 1 ? 'Room' : 'Rooms'}</div>
                <div className='price_amount'>${hotel.total_amount}</div>
                <div style={{ fontSize: '10px', marginBottom: '12px' }}> Includes taxes and fees</div>
                <DefaultButton
                    buttonLabel={"Select"}
                    buttonStyle={{ width: '100%' }}
                    customButtonClass="secondary_button_styling"
                    onClick={() => {
                        setFetching(true)
                        setHotelDetails(hotel);
                        setView('details');
                        navigate('/hotels/details');
                        sendGACustomEvent({ category: 'Button Click', action: `Click on select button on hotel card`, label: 'Click on select button on hotel card' })
                    }}
                />
            </div>
        </div>
    )
}

export default HotelCard
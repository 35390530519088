import { useEffect, useState } from "react"
import DefaultButton from "../../components/DefaultButton/defaultButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function PersonCountDropdown({ getRoomPersonCountValues, surpriseTripPayload }) {
    const [adult, setAdult] = useState(1)
    const [child, setChild] = useState(0)
    const [rooms, setRooms] = useState(1)
    const [showRoomMoreThanAdultsMessage, setShowRoomMoreThanAdultsMessage] = useState(false)
    const [showPersonsDropdown, setShowPersonDropdown] = useState(false)

    // for then a user goes back in the flow, pre-populate data
    useEffect(() => {
        if (surpriseTripPayload.adult) setAdult(surpriseTripPayload.adult)
        if (surpriseTripPayload.child) setChild(surpriseTripPayload.child)
        if (surpriseTripPayload.rooms) setRooms(surpriseTripPayload.rooms)
    }, [])

    useEffect(() => {
        getRoomPersonCountValues('adult', adult)
        getRoomPersonCountValues('child', child)
        getRoomPersonCountValues('rooms', rooms)
    }, [adult, rooms, child])

    const increaseAdult = () => {
        setAdult(adult + 1);
    };

    const decreaseAdult = () => {
        if (adult > 1) {
            setAdult(adult - 1);
        }
    };

    const increaseChild = () => {
        setChild(child + 1);
    };

    const decreaseChild = () => {
        if (child > 0) {
            setChild(child - 1);
        }
    }

    const increaseRooms = () => {
        setRooms(rooms + 1);
    }

    const decreaseRooms = () => {
        if (rooms > 1) {
            setRooms(rooms - 1);
        }
    }

    const ITEMS = [
        {
            title: 'Adult',
            decreaseFunc: decreaseAdult,
            increaseFunc: increaseAdult,
            value: adult
        },
        {
            title: 'Children',
            decreaseFunc: decreaseChild,
            increaseFunc: increaseChild,
            value: child
        },
        {
            title: 'Rooms',
            decreaseFunc: decreaseRooms,
            increaseFunc: increaseRooms,
            value: rooms
        }
    ]

    const handleGuestsAndRoomsAdd = () => {
        if (rooms > (adult + child)) {
            setShowRoomMoreThanAdultsMessage(true)
            return
        }
        getRoomPersonCountValues('adult', adult)
        getRoomPersonCountValues('child', child)
        getRoomPersonCountValues('rooms', rooms)

        setShowRoomMoreThanAdultsMessage(false)
        setShowPersonDropdown(!showPersonsDropdown)
    }

    const renderShowPersonsDropdown = () => {
        return (
            <div className='person_count_wrapper'>
                {ITEMS.map((per, i) => {
                    return (
                        <div key={i} className='person_count_cell'>
                            <div className='person_text'>{per.title}</div>
                            <div style={{ display: 'flex' }}>
                                <div className='person_count_btn' onClick={per.decreaseFunc}>
                                    <FontAwesomeIcon icon="fa-solid fa-minus" />
                                </div>
                                <div className='person_count_val'>{per.value}</div>
                                <div className='person_count_btn' onClick={per.increaseFunc}>
                                    <FontAwesomeIcon icon="fa-solid fa-plus" />
                                </div>
                            </div>
                        </div>
                    )
                })}
                {showRoomMoreThanAdultsMessage ? <div style={{ fontSize: '10px', color: '#D2042D', marginBottom: '5px' }}>Room count cannot be more than guests</div> : null}
                <DefaultButton
                    customButtonClass="secondary_button_styling"
                    buttonLabel="Done"
                    buttonStyle={{ width: '100%' }}
                    onClick={() => handleGuestsAndRoomsAdd()}
                />
            </div>
        )
    }

    return (
        <div className='person_input_wrapper'>
            <input
                placeholder='Travelers'
                value={
                    (adult > 0 ? adult + (adult === 1 ? " Adult" : " Adults") : "") +
                    (adult > 0 && ((child > 0) || (rooms > 0)) ? ", " : "") +
                    (child > 0 ? child + (child === 1 ? " Child" : " Children") : "") +
                    (child > 0 && (rooms > 0) ? ", " : "") +
                    (rooms > 0 ? rooms + (rooms === 1 ? " Room" : " Rooms") : "")
                }
                onClick={() => setShowPersonDropdown(!showPersonsDropdown)}
                className='person_input'
            />
            {showPersonsDropdown ? renderShowPersonsDropdown() : null}
        </div>
    )
}

export default PersonCountDropdown
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GoogleMap from 'google-map-react'
import React, { useState } from 'react'
import "react-multi-carousel/lib/styles.css"
import { RenderHotelCheckDetails, RenderRatingStars, calculateNights, formatDate } from '../../utils/helpers'
import CustomCarousel from '../CustomCarousel/customCarousel'
import HotelRoomCard from '../HotelRoomCard/hotelRoomCard'
import './hotelDetailedOverview.scss'

function getAmenityIcon(type) {
    switch (type) {
        case 'wifi':
            return 'fa-solid fa-wifi';
        case 'parking':
            return 'fa-solid fa-parking';
        case 'pets_allowed':
            return 'fa-solid fa-dog';
        case 'restaurant':
            return 'fa-solid fa-utensils';
        // Add more cases as needed for other amenity types
        default:
            // Default icon if type is not recognized
            return 'fa-question'; // For example, a question mark icon
    }
}

export function HotelDetails({ hotel }) {
    const [showFullDescription, setShowFullDescription] = useState(false);

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };

    const renderDescription = () => {
        if (showFullDescription || hotel.description.length <= 300) {
            return hotel.description;
        } else {
            return hotel.description.substring(0, 300) + '...';
        }
    };

    return (
        <div>
            <p className='hotel_name'>{hotel.name}</p>
            <RenderRatingStars rating={hotel.rating} />
            <div className='hotel_description'>
                <p>{renderDescription()} &nbsp;
                    {hotel.description.length > 300 && (
                        <a className="see-more" onClick={toggleDescription} style={{ cursor: 'pointer' }}>
                            {showFullDescription ? "See less" : "See more"}
                        </a>
                    )}
                </p>
            </div>
            <div className='icon_subtext_card_info'>
                <FontAwesomeIcon icon="fa-solid fa-map-location-dot" />
                <div><strong>Address: </strong> {hotel.location.line_one}, {hotel.location.city_name}, {hotel.location.postal_code}</div>
            </div>
            <div className='checkin_information_container'>
                <div className='checkin_information'>
                    <FontAwesomeIcon icon="fa-solid fa-calendar-days" /> &nbsp;
                    <p className='mb-0 pb-0'><strong>Check In : </strong><RenderHotelCheckDetails date={formatDate(hotel.check_in_date)} time={hotel.check_in_time} instruction="after" /></p>
                </div>
                <div className='checkin_information'>
                    <FontAwesomeIcon icon="fa-solid fa-calendar-days" /> &nbsp;
                    <p className='mb-0 pb-0'><strong>Check Out : </strong><RenderHotelCheckDetails date={formatDate(hotel.check_out_date)} time={hotel.check_out_time} instruction="before" /></p>
                </div>
            </div>
            <div className='icon_subtext_card_info' style={{ marginBottom: '12px' }}>
                <FontAwesomeIcon icon="fa-solid fa-moon" />
                <div><strong>Nights: </strong>{calculateNights(hotel.check_in_date, hotel.check_out_date)}</div>
            </div>
            <div className='icon_subtext_card_info' style={{ marginBottom: '12px' }}>
                <FontAwesomeIcon icon="fa-regular fa-user" />
                <div><strong>Guests: </strong> {hotel.adults}</div>
            </div>
            <div className='icon_subtext_card_info'>
                <FontAwesomeIcon icon="fa-solid fa-bed" />
                <div><strong>Rooms: </strong> {hotel.rooms}</div>
            </div>
            {
                hotel.amenities && hotel.amenities.length > 0 &&
                <div className='hotel_facility_container'>
                    <div className='hotel_facilities'>
                        {
                            hotel.amenities.map((amenity) => (
                                <div className='hotel_facility'>
                                    <FontAwesomeIcon icon={getAmenityIcon(amenity.type)} style={{ width: '30px', marginRight: '5px', fontSize: '14px', color: '#035EDD' }} /> {amenity.description}
                                </div>
                            ))
                        }
                    </div>
                </div>
            }
        </div>
    );
}

function HotelDetailedOverview({ hotel, setRateId, setView, setHotelDetails }) {

    const defaultProps = {
        center: {
            lat: hotel.location.latitude,
            lng: hotel.location.longitude
        },
        zoom: 15
    };

    return (
        <div>
            <div className='hotel_detailed_wrapper'>
                <div className='hotel_overview'>
                    <div className='hotel_image_container'>
                        {hotel.photo.length && <img src={hotel.photo[0].url} alt={hotel.name} className='hotel_image' />}
                        <div class='small_images_row'>
                            {hotel.photo.slice(1, 5).map((photo, i) => {
                                return (
                                    <img key={i} src={photo.url} alt={hotel.name} class='hotel_image small_image' />
                                )
                            })}
                        </div>
                    </div>
                    <div className='hotel_detail_container'>
                        <div className='hotel_details'>
                            <HotelDetails hotel={hotel} />
                        </div>
                        <div className='hotel_contacts'>
                            <div className='hotel_location'>
                                <GoogleMap
                                    bootstrapURLKeys={{ key: 'AIzaSyAGg-A0guVcGmruz-MlH_af-PiufGOnyfk' }}
                                    center={defaultProps.center}
                                    zoom={defaultProps.zoom}
                                    hoverDistance={30}
                                >
                                    <div lat={hotel.location.latitude} lng={hotel.location.longitude}>
                                        <FontAwesomeIcon icon="fa-solid fa-location-dot" style={{ fontSize: '30px', color: '#FF0000' }} />
                                    </div>
                                </GoogleMap>
                            </div>
                            <div className='hotel_address'>
                                <p className='mb-0 pb-0'><FontAwesomeIcon icon="fa-solid fa-map-location-dot" /> &nbsp;<strong>Address : </strong>{hotel.location.line_one} , {hotel.location.city_name}. {hotel.location.country_code}, {hotel.location.postal_code}</p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='hotel_rooms_container'>
                        {hotel.room_details.map(room => (
                            <HotelRoomCard
                                room={room}
                                hotel={hotel}
                                setRateId={setRateId}
                                setView={setView}
                                setHotelDetails={setHotelDetails}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HotelDetailedOverview

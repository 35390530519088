import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ReactGA from "react-ga4"

export function formatDate(inputDate) {
  var parts = inputDate.split('-')
  var month = parseInt(parts[1], 10)
  var day = parseInt(parts[2], 10)
  var year = parseInt(parts[0], 10)
  var monthNames = [
    "Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.",
    "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."
  ];
  var monthName = monthNames[month - 1];
  var formattedDate = monthName + " " + day + ", " + year;

  return formattedDate;
}

export function isUserLoggedIn() {
  return sessionStorage.getItem('user_id') !== null
}

export function generateRandomId(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return 'unregistered-' + result;
}

export function sendGAPageEvent({ hitType, page, title }) {
  ReactGA.send({ hitType: hitType, page: page, title: title })
}

export function sendGACustomEvent({ category, action, label }) {
  ReactGA.event({
    category: category,
    action: action,
    label: label
  })
}

export function formatDuration(duration) {
  // Parse the duration string
  const match = duration.match(/PT(\d+)H(\d+)M/);
  if (!match) {
    return "Not Available";
  }

  // Extract hours and minutes
  const hours = parseInt(match[1]);
  const minutes = parseInt(match[2]);

  // Format the duration
  let formattedDuration = "";
  if (hours > 0) {
    formattedDuration += hours + " h ";
  }
  if (minutes > 0) {
    formattedDuration += minutes + " m";
  }

  return formattedDuration.trim(); // Trim any extra spaces
}

export function formatStops(stops) {
  let status = ''
  if (stops === 0) {
    status = '(Nonstop)'
  } else if (stops === 1) {
    status = `(${stops} stop)`
  }
  else {
    status = `(${stops} stops)`
  }
  return status
}

export function calculateNights(startDate, endDate) {
  // Parse the input dates
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calculate the difference in time
  const differenceInTime = end.getTime() - start.getTime();

  // Calculate the difference in days
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  return differenceInDays;
}

export function calculateFlightTotal(totalAmount) {
  let fees = Number((Math.ceil(totalAmount * 0.1 * 100) / 100))
  let total = fees + totalAmount
  return total.toFixed(2)
}

export function convertTo12HourFormat(dateTime) {
  // Split the timestamp into hours, minutes, and seconds
  let [hours, minutes, seconds] = dateTime.split(':').map(Number);

  // Determine if it's AM or PM
  let period = hours < 12 ? 'AM' : 'PM';

  // Convert hours from 24-hour to 12-hour format
  hours = hours % 12;
  if (hours === 0) {
    hours = 12;
  }

  // Return the formatted time without seconds
  return `${hours}:${minutes.toString().padStart(2, '0')} ${period}`;
}

export function formatDateAndTime(dateString, returnType) {
  // Parse the date string into a Date object
  const date = new Date(dateString)

  // Extract and format the date components
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = months[date.getMonth()]; // Get month name
  const day = date.getDate();
  const year = date.getFullYear();
  const formattedDate = `${month} ${day}, ${year}`;

  // Extract and format the time components with AM/PM
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const formattedTime = `${hours}:${minutes} ${ampm}`;

  if (returnType === 'Date') {
    return formattedDate
  } else if (returnType === 'Time') {
    return formattedTime
  } else {
    return formattedDate + " " + formattedTime
  }
}

export function convertTo24HourFormat(time) {
  // Splitting the time into hours, minutes, and seconds
  var splitTime = time.split(':');
  var hours = parseInt(splitTime[0]);
  var minutes = parseInt(splitTime[1]);

  var time24Hour = hours + ':' + minutes;

  return time24Hour;
}

export function RenderRatingStars({ rating }) {
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating - fullStars >= 0.5;

  const stars = [];
  for (let i = 0; i < fullStars; i++) {
    stars.push(<FontAwesomeIcon key={i} icon="fa-solid fa-star" color='#035EDD' />);
  }
  if (hasHalfStar) {
    stars.push(<FontAwesomeIcon key="half" icon="fa-solid fa-star-half" color='#035EDD' />);
  }

  return (
    <div>
      {stars}
    </div>
  );
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function RenderHotelCheckDetails({ date, time, instruction }) {
  return (
    <span>
      {date} {time ? <span>{instruction} {convertTo12HourFormat(time)}</span> : null}
    </span>
  )
}

export function formatFinalPrice(finalPrice) {
  let decimalIndex = finalPrice.indexOf('.')
  if (decimalIndex !== -1) {
    let trimmedStr = finalPrice.substring(0, decimalIndex + 3)
    return trimmedStr
  }
  return finalPrice
}

export function truncateDescription(description, maxLength) {
  // Check if the description length is already within the limit
  if (description.length <= maxLength) {
    return description;
  }

  // Find the closest full stop before the maxLength
  let truncatedDescription = description.substring(0, maxLength);
  let lastPeriodIndex = truncatedDescription.lastIndexOf('.');

  // If there is a full stop before the maxLength, truncate the string at that point
  if (lastPeriodIndex !== -1) {
    truncatedDescription = truncatedDescription.substring(0, lastPeriodIndex + 1);
  } else (
    truncatedDescription = truncatedDescription + "..."
  )

  return truncatedDescription;
}

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

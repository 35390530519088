import PersonCountDropdown from "../../components/PersonCountDropdown/personCountDropdown"
import DateRangePicker from "./dateRangePicker"
import SurpriseCheckoutSummary from "./surpriseCheckoutSummary"
import { PREFERENCE_TAGS } from "../../constants/constants"
import SearchAutoComplete from "../../components/SearchAutoComplete/searchAutoComplete"
import { useEffect, useState } from "react"
import { fetchCityAndAirports } from "../../context/api"
import './surpriseTrips.scss'
import { useNavigate, useSearchParams } from "react-router-dom"
import DefaultButton from "../../components/DefaultButton/defaultButton"
import InputField from "../../components/InputField/inputField"
import { Stepper } from 'react-form-stepper'
import HotelPassengerForm from "../../components/PassengerForm/hotelPassengerForm"
import { DuffelPayments } from '@duffel/components'
import { getPaymentIntent, createSurpriseRequest, bookingConfirmation } from '../../context/api'
import paymentProcessing from '../../assets/animations/processing.json'
import Lottie from 'lottie-react'
import { emailRegex, sendGACustomEvent } from '../../utils/helpers'
import paymentSuccess from '../../assets/animations/paymentSuccess.json'

const classNames = require('classnames')

function Surprise() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [citiesAndAirports, setCitiesAndAirports] = useState([])
    const step = searchParams.get('step') || 'details'
    const [surpriseTripPayload, setSurpriseTripPayload] = useState({
        receiveDetailsDate: ''
    })
    const [selectedTags, setSelectedTags] = useState([])
    const [clientToken, setClientToken] = useState('')
    const [alertMessage, setAlertMessage] = useState("")
    const [alertAnimation, setAlertAnimation] = useState("")
    const [processing, setProcessing] = useState(false)
    const [paymentInProgress, setPaymentInProgress] = useState(false)
    const [paymentComplete, setPaymentComplete] = useState(false)
    const [nextButtonDisabled, setNextButtonDisabled] = useState(false)
    const [paymentIntentId, setPaymentIntentId] = useState()

    const navigate = useNavigate()

    const stepperDetails = [
        {
            id: 0,
            label: "Details",
            title: "Details",
            step: "details",
        },
        {
            id: 1,
            label: "Preferences",
            title: "Preferences",
            step: "preferences",
        },
        {
            id: 2,
            label: "Passenger Details",
            title: "Passenger Details",
            step: "passengers",
        },
        {
            id: 3,
            label: "Checkout",
            title: "Checkout",
            step: "checkout",
        }
    ]
    const newSearchParams = new URLSearchParams(searchParams)

    useEffect(() => {
        newSearchParams.set('step', 'details')
        // Update the search parameters
        setSearchParams(newSearchParams)

        if (step === 'details') {
            fetchCityAndAirports()
                .then((res) => {
                    setCitiesAndAirports(res)
                })
        }

        // doing clean up
        return () => { setProcessing(false) }
    }, [])

    useEffect(() => {
        if (step === 'details') {
            setNextButtonDisabled(true)
            if ((surpriseTripPayload.firstDateRange && surpriseTripPayload.firstDateRange.length === 2) && surpriseTripPayload.budget && surpriseTripPayload.originName) {
                setNextButtonDisabled(false)
            }
        } else if (step === 'preferences') {
            setNextButtonDisabled(true)
            if (surpriseTripPayload.receiveDetailsDate) {
                setNextButtonDisabled(false)
            }
        } else if (step === 'passengers') {
            setNextButtonDisabled(true)
            if (surpriseTripPayload.passengerDetails && surpriseTripPayload.passengerDetails[0].given_name && surpriseTripPayload.passengerDetails[0].family_name && surpriseTripPayload.passengerDetails[0].phone_number && surpriseTripPayload.passengerDetails[0].email) {
                setNextButtonDisabled(false)
            }
        }
    }, [surpriseTripPayload, step])

    const handleOnSelectOrigin = (item) => {
        onPayloadInput('iata_code', item.iata_code)
        onPayloadInput('originName', item.airport_name)
        return `${item.airport_name}`
    }

    const handleOnNext = () => {
        if (step === 'details') {
            newSearchParams.set('step', 'preferences')
        } else if (step === 'preferences') {
            // add the selected tags to the payload
            onPayloadInput('tags', selectedTags)
            newSearchParams.set('step', 'passengers')
        } else if (step === 'passengers') {
            newSearchParams.set('step', 'checkout')
            handleCheckout()
        }
        setSearchParams(newSearchParams)
    }

    const onPayloadInput = (name, value) => {
        setSurpriseTripPayload(prevPayload => ({
            ...prevPayload,
            [name]: value
        }))
    }

    const onPreferenceSelection = (tag) => {
        setSelectedTags(prevTags => {
            if (prevTags.includes(tag)) {
                return prevTags.filter(t => t !== tag);
            } else {
                return [...prevTags, tag];
            }
        });
    }

    const setPassengerDetails = (details) => {
        const isInvalidInputs = details && details.some(passenger => {
            return (
                Object.values(passenger).some(value => value === "") ||
                !emailRegex.test(passenger.email) || passenger.phone_number.length !== 12 // Check for empty email and invalid format
            )
        })
        if (!isInvalidInputs) {
            onPayloadInput('passengerDetails', details)
        }
    }

    const handlePaymentSuccess = () => {
        setPaymentInProgress(true)
        setAlertAnimation(paymentProcessing)
        setAlertMessage("Payment in progress")
        bookingConfirmation({ id: paymentIntentId })
            .then((confirmationRes) => {
                surpriseTripPayload['paymentConfirmationId'] = confirmationRes.id
                let payload = { user_id: sessionStorage.getItem('user_id'), data: surpriseTripPayload }
                createSurpriseRequest(payload)
                    .then(() => {
                        setAlertAnimation(paymentSuccess)
                        setPaymentComplete(true)
                        setAlertMessage("Trip Booked Successfully! 🎉")
                    })
            })
            .catch(() => {
                setPaymentInProgress(false)
                setPaymentComplete(false)
            })
    }

    const handleCheckout = () => {
        // sendGACustomEvent({ category: 'Button Click', action: `Click on Checkout button for on hotel`, label: 'Hotel Checkout Button Click' })
        setProcessing(true)
        setAlertAnimation(paymentProcessing)
        setAlertMessage("Payment in progress")
        getPaymentIntent({ amount: surpriseTripPayload.budget, currency: "USD" })
            .then((res) => {
                setClientToken(res.client_token)
                setPaymentIntentId(res.id)
                setProcessing(false)
            })
            .catch((e) => {
                setProcessing(false)
            })
    }

    return (
        <div>
            <Stepper
                styleConfig={{
                    activeBgColor: '#FD905E',
                    completedBgColor: '#FD905E'
                }}
                connectorStyleConfig={{
                    activeColor: '#FD905E',
                    completedColor: '#FD905E'
                }}
                steps={stepperDetails.map(step => ({ label: step.label }))}
                activeStep={stepperDetails.find(item => item.step === step).id}
            />
            <div className="surprise_page_title" style={{ fontSize: '24px' }}>Let's plan your trip</div>
            <div className="surprise_page_subtitle" style={{ fontSize: '16px' }}>We just need a couple more details from you</div>
            {step === 'details' ?
                <div className="surprise_content_container">
                    <div className="input_section_title">Give us up to 3 date ranges that best work for you</div>
                    <DateRangePicker getDateRangeValues={onPayloadInput} surpriseTripPayload={surpriseTripPayload} />
                    <div className="input_section_title">Additional Trip Details</div>
                    <div className="surprise_row">
                        <SearchAutoComplete
                            placeholder='Your Origin'
                            items={citiesAndAirports}
                            onSelect={handleOnSelectOrigin}
                            autoFocus={true}
                            maxResults={5}
                            usage='origin_search'
                            surpriseTripPayload={surpriseTripPayload}
                        />
                        <div className="person_count_dropdown_wrapper">
                            <PersonCountDropdown getRoomPersonCountValues={onPayloadInput} surpriseTripPayload={surpriseTripPayload} />
                        </div>
                    </div>
                    <div className="input_section_title" style={{ margin: '32px 0 0 0 ' }}>Trip Budget</div>
                    <div className="surprise_section_subtitle">Approximately how much would you like to spend on flights, hotels and activities?</div>
                    <InputField
                        placeholder="Budget"
                        type="number"
                        value={surpriseTripPayload.budget ? surpriseTripPayload.budget : ''}
                        inputStyling={{ width: '15rem', marginTop: '18px' }}
                        onChange={(e) => onPayloadInput('budget', Number(e.target.value).toFixed(2))}
                    />
                </div>
                : step === 'preferences' ?
                    <div className="surprise_content_container">
                        <div className="input_section_title">How would you like to spend your time?</div>
                        <div className="surprise_tags_container">
                            {PREFERENCE_TAGS.map((tag, i) => {
                                return (
                                    <div
                                        className={classNames("surprise_tag", selectedTags.includes(tag) ? 'selected_tag' : '')}
                                        onClick={() => onPreferenceSelection(tag)}
                                        key={i}>
                                        {tag}
                                    </div>
                                )
                            })}
                        </div>
                        <div style={{ marginTop: '24px' }}>
                            <div className="surprise_section_subtitle">Anything else you would like our AI to know? (optional)</div>
                            <textarea
                                value={surpriseTripPayload.preferences || ''}
                                style={{ resize: 'none', width: '100%', outline: 'none' }}
                                onChange={(e) => onPayloadInput('preferences', e.target.value)}
                            />
                        </div>
                        <div className="input_section_title" style={{ margin: '32px 0 0 0 ' }}>When would you like to receive your trip details?</div>
                        <div className="surprise_section_subtitle">That is when we will send you an email with all your trip details. Don't worry, you'll be able to track everything in our portal.</div>
                        <div className="surprise_tags_container" style={{ marginTop: '18px' }}>
                            <div
                                className={classNames("surprise_tag", surpriseTripPayload.receiveDetailsDate === 'ASAP' ? 'selected_tag' : '')}
                                onClick={() => onPayloadInput('receiveDetailsDate', 'ASAP')}>
                                ASAP
                            </div>
                            <div
                                className={classNames("surprise_tag", surpriseTripPayload.receiveDetailsDate === '72 hours before' ? 'selected_tag' : '')}
                                onClick={() => onPayloadInput('receiveDetailsDate', '72 hours before')}>
                                72 hours in advance
                            </div>
                            <div
                                className={classNames("surprise_tag", surpriseTripPayload.receiveDetailsDate === '24 hours before' ? 'selected_tag' : '')}
                                onClick={() => onPayloadInput('receiveDetailsDate', '24 hours before')}>
                                24 hours in advance
                            </div>
                        </div>
                    </div> : step === 'passengers' ?
                        <div className="surprise_checkout_container surprise_content_container">
                            <SurpriseCheckoutSummary surpriseTripPayload={surpriseTripPayload} />
                            <div className="hotel_passenger_container">
                                <HotelPassengerForm
                                    passengers={surpriseTripPayload.adult}
                                    setPassengerDetails={setPassengerDetails}
                                />
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                                    <DefaultButton
                                        buttonLabel={processing ? "Processing" : "Proceed to Checkout"}
                                        customButtonClass="secondary_button_styling"
                                        buttonStyle={{ width: '100%', padding: '12px' }}
                                        onClick={handleOnNext}
                                        disabled={nextButtonDisabled}
                                    />
                                </div>
                            </div>
                        </div> : step === 'checkout' ?
                            <div className="surprise_checkout_container surprise_content_container">
                                <SurpriseCheckoutSummary surpriseTripPayload={surpriseTripPayload} />
                                <div className='checkout_card_wrapper'>
                                    <div className="surprise_price_container">
                                        <div>Due Now</div>
                                        <div>${surpriseTripPayload.budget}</div>
                                    </div>
                                    <div className="surprise_payment_fine_print">Get a 100% refund if you change your mind within 48 hours.</div>
                                    {
                                        <div style={{ position: 'relative' }}>
                                            {paymentInProgress && (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        width: '100%',
                                                        height: '100%',
                                                        backgroundColor: 'rgb(186 184 184 / 50%)', // Semi-transparent black overlay
                                                        zIndex: 9999, // Ensure the overlay is on top of other content
                                                        borderRadius: '8px'
                                                    }}
                                                />
                                            )}
                                            <div style={paymentInProgress ? { opacity: '0.8' } : {}}>
                                                <div>
                                                    <p className='fs-3 fw-bolder text-start mb-2'>Billing Information</p>
                                                </div>
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    {
                                                        clientToken &&
                                                        <DuffelPayments
                                                            className="custom_duffel_styling"
                                                            paymentIntentClientToken={clientToken}
                                                            onSuccessfulPayment={handlePaymentSuccess}
                                                            onFailedPayment={console.log}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    }
                                    {
                                        paymentInProgress && (
                                            <div className='alert_modal'>
                                                <div className=''>
                                                    <Lottie animationData={alertAnimation} loop={true} className='alert_animation' />
                                                    <div className='alert_message'>{alertMessage}</div>
                                                </div>
                                                {
                                                    paymentComplete &&
                                                    <DefaultButton
                                                        buttonLabel={"Go Home"}
                                                        customButtonClass="inverted_secondary_button_styling"
                                                        onClick={() => { navigate("/"); sendGACustomEvent({ category: 'Button Click', action: "Route user back to itinerary after hotel booking", label: 'Go to itinerary button click' }) }}
                                                    />
                                                }
                                            </div>
                                        )
                                    }
                                    <div className="surprise_how_it_works_container">
                                        <div style={{ fontSize: '18px', color: '#035EDD', fontWeight: '800', margin: '12px 0 0 0' }}>How Payment Works</div>
                                        <ul>
                                            <li>The amount you pay now will be used to book flight, stays and activities (if possible) + fees & taxes.</li>
                                            <li>The remaining amount (if any) will be <strong>refunded</strong> to you. You will <strong>not</strong> be charged more than the amount you are paying above.</li>
                                            <li>You will be notified with a detailed itinerary <strong>{surpriseTripPayload.receiveDetailsDate === 'ASAP' ? 'as soon as possible' : surpriseTripPayload.receiveDetailsDate}</strong> before your trip starts.</li>
                                            <li>We will send you a set of instructions that will help you to prepare and pack for the trip.</li>
                                            <li>We hope you enjoy your surprise trip!</li>
                                        </ul>
                                    </div>
                                </div>
                            </div> : null}
            {step === 'details' || step === 'preferences' ?
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                    <DefaultButton
                        buttonLabel={step === 'checkout' ? "Checkout" : "Next"}
                        customButtonClass="secondary_button_styling"
                        buttonStyle={{ width: '10rem', padding: '12px' }}
                        onClick={handleOnNext}
                        disabled={nextButtonDisabled}
                    />
                </div> : null}
        </div>
    )
}

export default Surprise;
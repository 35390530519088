export const PREFERENCE_TAGS = [
    'Beach',
    'Mountains',
    'Cityscape',
    'Countryside',
    'Vegan',
    'Halal',
    'Seafood',
    'Gluten-free',
    'Vegetarian',
    'Dairy-free',
    'Water Activities',
    'Hiking',
    'Outdoor',
    'Museums',
    'Tourist Attractions',
    'Cultural Tours',
    'Historical Sites'
]
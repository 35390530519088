import React from 'react'
import './userTripCard.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatDate, sendGACustomEvent } from '../../utils/helpers'
import DefaultButton from '../DefaultButton/defaultButton'

function UserTripCard({ trip, onClick }) {
    return (
        <div className='user_trip_card'>
            <div className='trip_img_container'>
                <img src={trip.trip_image} width="100%" height="100%" style={{ objectFit: 'cover', borderRadius: '8px 8px 0 0' }} />
            </div>
            <div className='trip_info_container'>
                <div style={{ fontSize: '18px', fontWeight: '700', marginBottom: '8px' }}>
                    {trip.destination_name}
                </div>
                <div className='icon_with_info_cell'>
                    <FontAwesomeIcon icon="fa-solid fa-calendar-days" />
                    <div>{formatDate(trip.start_date)} - {formatDate(trip.end_date)}</div>
                </div>
                <div className='icon_with_info_cell'>
                    <FontAwesomeIcon icon="fa-regular fa-calendar" />
                    <div>Total Days: {trip.trip_days}</div>
                </div>
                <div className='icon_with_info_cell'>
                    <FontAwesomeIcon icon="fa-regular fa-user" />
                    <div>Passengers: {trip.adult + trip.child}</div>
                </div>
            </div>
            <div style={{ margin: '12px' }}>
                <DefaultButton
                    buttonLabel="View Trip"
                    buttonStyle={{ width: '100%' }}
                    customButtonClass="inverted_secondary_button_styling"
                    onClick={(trip) => { onClick(trip); sendGACustomEvent({ category: 'Button Click', action: `Shows trip card from user dashboard`, label: 'View Trip button click from logged in user' }) }}
                />
            </div>
        </div>
    )
}

export default UserTripCard
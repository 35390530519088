import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion'
import { getSessionData } from '../../context/api';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { emailRegex } from '../../utils/helpers';

const HotelPassengerForm = ({ passengers, setPassengerDetails }) => {
    const defaultFormData = {
        phone_number: '',
        email: '',
        family_name: '',
        given_name: ''
    }

    const initialFormData = Array.from({ length: passengers }, (_, index) => ({
        id: index + 1,
        ...defaultFormData,
    }))

    const [formData, setFormData] = useState(initialFormData);
    const [isValidPhone, setIsValidPhone] = useState(false);

    function isValidInput(inputValue, dialCode) {
        const digitsOnly = inputValue.replace(/\D/g, '');
        const dialCodedigitsOnly = dialCode.replace(/\D/g, '');
        const digitsAfterDialCode = digitsOnly.substring(dialCodedigitsOnly.length);

        // Check if the remaining value has exactly 10 digits
        return digitsAfterDialCode.length === 10;
    }

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedFormData = [...formData];
        updatedFormData[index] = { ...updatedFormData[index], [name]: value };
        setFormData(updatedFormData);
    };

    const handleEmailChange = (e, index) => {
        const { name, value } = e.target
        const updatedFormData = [...formData];
        updatedFormData[index] = { ...updatedFormData[index], [name]: value };
        setFormData(updatedFormData);
        if (emailRegex.test(value)) {
            setFormData(updatedFormData);
        }
    }

    const handlePhoneChange = (phone, index, inputValue) => {
        const updatedFormData = [...formData]
        updatedFormData[index] = { ...updatedFormData[index], phone_number: phone }
        setIsValidPhone(isValidInput(inputValue.inputValue, inputValue.country.dialCode))
        setFormData(updatedFormData);
    };

    useEffect(() => {
        setPassengerDetails(formData)
    }, [formData])

    useEffect(() => {
        const deets = JSON.parse(sessionStorage.getItem('tripDetails'))
        if (deets && deets.trip_id) {
            getSessionData({ trip_id: deets.trip_id })
                .then((res) => {
                    if (res.hotel_details && res.hotel_details.passenger_details) {
                        setFormData(res.hotel_details.passenger_details)
                    }
                })
        }
    }, [])

    return (
        <div className="">
            <Accordion className="itinerary_accordian" defaultActiveKey={0}>
                {formData.map((passenger, index) => (
                    <Accordion.Item eventKey={index} key={index}>
                        <Accordion.Header>Customer {index + 1}</Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor={`family_name_${index}`} className="form-label">
                                            Family Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id={`family_name_${index}`}
                                            name="family_name"
                                            value={passenger.family_name}
                                            onChange={(e) => handleInputChange(e, index)}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor={`given_name_${index}`} className="form-label">
                                            Given Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id={`given_name_${index}`}
                                            name="given_name"
                                            value={passenger.given_name}
                                            onChange={(e) => handleInputChange(e, index)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor={`phone_number_${index}`} className="form-label">
                                            Phone Number
                                        </label>
                                        <PhoneInput
                                            defaultCountry="us"
                                            className="form-control"
                                            name="phone_number"
                                            value={passenger.phone_number}
                                            onChange={(phone, inputValue) => handlePhoneChange(phone, index, inputValue)}
                                            style={!isValidPhone ? { border: "1px solid red", padding: '0px' } : { border: 'none', padding: '0px' }}
                                        />
                                        {!isValidPhone && (
                                            <small className="text-danger" style={{ fontSize: '10px' }}>Phone is not valid</small>
                                        )}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor={`email_${index}`} className="form-label">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id={`email_${index}`}
                                            name="email"
                                            value={passenger.email}
                                            onChange={(e) => handleEmailChange(e, index)}
                                            style={(passenger.email.length > 1 && !emailRegex.test(passenger.email)) ? { border: "1px solid red" } : {}}
                                        />

                                        {passenger.email.length > 1 && !emailRegex.test(passenger.email) && (
                                            <small className="text-danger" style={{ fontSize: '10px' }}>Invalid email format</small>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
    )
}

export default HotelPassengerForm;
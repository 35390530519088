import './navbar.scss'
import FullLogo from '../../assets/full_logo_horizontal.png'
import { useNavigate } from 'react-router-dom'
import { GoogleLogin } from '@react-oauth/google'
import { login } from '../../context/api'
import { isUserLoggedIn, sendGACustomEvent } from '../../utils/helpers'
import { useContext } from 'react'
import { Context } from '../../context/context'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function NavBar({ getLoadingState }) {
    const { state, setState } = useContext(Context)
    const navigate = useNavigate()

    const onLogin = (credentialResponse) => {
        getLoadingState(true)
        const payload = {
            token: credentialResponse.credential,
            client_id: credentialResponse.clientId,
            session_id: sessionStorage.getItem('user_session_id'), // this should only be present when user is unregistered
            trip_id: sessionStorage.getItem('user_session_id') ? state.tripDetails.trip_id : null
        }
        login(payload)
            .then((res) => {
                // save user details in session storage
                sessionStorage.setItem('user', JSON.stringify(res))
                setState({ ...state, userInfo: res })

                // save the user id in session storage:
                sessionStorage.setItem('user_id', res.user_id)

                // we need to update the trip id here b/c before login we had an "unregistered trip_id", and this is an updated one:
                let tripSession = JSON.parse(sessionStorage.getItem('tripDetails'))
                if (tripSession) {
                    tripSession['trip_id'] = res.trip_id
                    // update session storage:
                    sessionStorage.setItem('tripDetails', JSON.stringify(tripSession))
                }

                getLoadingState(false)

                // reroute user based on their current location
                if (window.location.pathname === '/') {
                    navigate('/my_trips')
                }
            })
    }

    const handleLogout = () => {
        sendGACustomEvent({
            category: 'Button Click',
            action: 'Logs user out',
            label: 'Logout button'
        })
        sessionStorage.clear();
        navigate('/');
    }

    const onLogoClick = () => {
        sendGACustomEvent({
            category: 'Button Click',
            action: 'Takes user home',
            label: 'Logo Click'
        })
        navigate('/')
    }

    const onNavClick = (navLink) => {
        navigate(navLink)
        sendGACustomEvent({
            category: 'Button Click',
            action: `Navigates user to ${navLink}`,
            label: 'Nav Tab Click'
        })
    }

    return (
        <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary navbar_styling">
            <Navbar.Brand style={{ marginRight: '50px' }}>
                <img src={FullLogo} style={{ width: '10rem', cursor: 'pointer' }} onClick={() => onLogoClick()} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link className='nav_link_styling' onClick={() => onNavClick('/')}>Home</Nav.Link>
                    <Nav.Link className='nav_link_styling' onClick={() => onNavClick('/blogs')}>Blogs</Nav.Link>
                    {isUserLoggedIn() ? <Nav.Link className='nav_link_styling' onClick={() => onNavClick('/my_trips')}>My Trips</Nav.Link> : null}
                </Nav>
                <Nav className='nav_right_button'>
                    <Nav.Link>
                        {
                            isUserLoggedIn() ?
                                // <div className='logout_btn' onClick={handleLogout}>Logout</div> :
                                <div className="popup">
                                    <div className='nav_link_styling'>My Account</div>
                                    <div className="popup-content">
                                        <Nav.Link className='nav_link_styling' onClick={() => onNavClick('/account')}>My Account</Nav.Link>
                                        <Nav.Link className='nav_link_styling' onClick={() => onNavClick('/contact')}>Contact Support</Nav.Link>
                                        <Nav.Link className='nav_link_styling' onClick={handleLogout}>Logout</Nav.Link>
                                    </div>
                                </div> :
                                <GoogleLogin
                                    onSuccess={credentialResponse => { onLogin(credentialResponse) }}
                                    onError={() => { console.log('Login Failed') }}
                                />
                        }
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default NavBar
import React, { useEffect, useState } from 'react';
import NavBar from '../../components/Navbar/navbar';
import DefaultButton from '../../components/DefaultButton/defaultButton';
import './contact.scss'
import { sentQueryToEmail } from '../../context/api';
import Footer from '../../components/Footer/footer';
import { sendGACustomEvent, sendGAPageEvent } from '../../utils/helpers';

function Contact() {
    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false)
    const [formData, setFormData] = useState({
        case: '',
        email: '',
        phone: '',
        reference: '',
        message: '',
    });

    useEffect(() => {
        sendGAPageEvent({ hitType: 'pageview', page: '/contact', title: 'Landing on contact us page' })
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = () => {
        sendGACustomEvent({ category: 'Button Click', action: `Sends a contact us email`, label: 'Send a contact us Email' })
        if (formData.case == "" || formData.email == "" || formData.message == "")
            return alert("Please fill in all required fields")

        if ((formData.case == "Flight" || formData.case == "Hotel") && formData.reference == "")
            return alert("Please enter a confirmation code")

        setFormData({
            case: '',
            email: '',
            phone: '',
            reference: '',
            message: ''
        });

        setSending(true)
        sentQueryToEmail(formData).then((res) => {
            alert("Thank you for reaching out to us. A member of our team will get back to you in 3-5 business days")
            setSending(false)
        })
    };

    const isBtnDisabled = () => {
        // let bothDateRanges = dateRange.filter(d => d !== null)
        return (formData.case == "" || formData.email == "" || formData.message == "")
    }


    return (
        <div className=''>
            <NavBar getLoadingState={val => setLoading(val)} />
            <div className='contact_page py-5'>
                <div className='container'>
                    <p className='fs-1 fw-bolder mb-1'>Contact Support</p>
                    <p className='fs-6'>
                        Please fill out the form below and someone from our
                        team will reach out to you soon
                    </p>
                    <div className='card contact_form_container'>

                        <div className='row mb-3'>
                            <div className='col-sm-12 col-md-6'>
                                <div className="input-group-lg">
                                    <label className="form-label fw-bold">Your email address</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        aria-label="Sizing example input"
                                        aria-describedby="inputGroup-sizing-lg"
                                        placeholder='Your email address'
                                        name='email'
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6'>
                                <div className="input-group-lg">
                                    <label className="form-label fw-bold">Your phone number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        aria-label="Sizing example input"
                                        aria-describedby="inputGroup-sizing-lg"
                                        placeholder='Your phone number'
                                        name='phone'
                                        value={formData.phone}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-sm-12'>
                                <div className="input-group-lg">
                                    <label className="form-label fw-bold">Query Regarding</label>
                                    <select
                                        className="form-select form-select-lg mb-3"
                                        aria-label=".form-select-lg example"
                                        name='case'
                                        value={formData.case}
                                        onChange={handleChange}
                                    >
                                        <option value="">-- Select the service --</option>
                                        <option value="Flight">Flight</option>
                                        <option value="Hotel">Hotel</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {
                            (formData.case == "Flight" || formData.case == "Hotel") &&
                            <div className='row mb-3'>
                                <div className='col'>
                                    <div className="input-group-lg">
                                        <label className='form-label fw-bold'>{formData.case} Confirmation Number</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Confirmation Number'
                                            name='reference'
                                            value={formData.reference}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className='row mb-3'>
                            <div className='col'>
                                <div className="input-group-lg">
                                    <label className='form-label fw-bold'>Additional Information</label>
                                    <textarea
                                        className='form-control'
                                        placeholder='Your message'
                                        name='message'
                                        value={formData.message}
                                        onChange={handleChange}
                                        rows={6}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col d-flex justify-content-center'>
                                {/* <button className='btn btn-primary' onClick={handleSubmit}>Submit</button> */}
                                <DefaultButton
                                    buttonLabel={!sending ? "Submit" : "Sending..."}
                                    buttonStyle={{ padding: '10px', width: '10rem' }}
                                    onClick={handleSubmit}
                                    disabled={isBtnDisabled()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Contact;

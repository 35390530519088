import Footer from "../components/Footer/footer";
import NavBar from "../components/Navbar/navbar";

function HeaderLayout({ children }) {
    return (
        <div>
            <NavBar />
            <div>{children}</div>
            <Footer />
        </div>
    )
}

export default HeaderLayout;
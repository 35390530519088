import { DuffelPayments } from '@duffel/components'
import Lottie from 'lottie-react'
import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useNavigate, useParams } from 'react-router-dom'
import paymentSuccess from '../../assets/animations/paymentSuccess.json'
import paymentProcessing from '../../assets/animations/processing.json'
import { createHotelBooking, createHotelQuotes, getPaymentIntent, updateSessionData, getWPFlightPrice } from '../../context/api'
import { Context } from '../../context/context'
import { emailRegex, sendGACustomEvent } from '../../utils/helpers'
import DefaultButton from '../DefaultButton/defaultButton'
import { HotelDetails } from '../HotelDetailedOverview/hotelDetailedOverview'
import HotelPolicies from '../HotelPolicies/hotelPolicies'
import HotelPassengerForm from '../PassengerForm/hotelPassengerForm'
import './hotelCheckoutDetails.scss'

function HotelCheckoutDetails({ hotelDetails, rateId }) {
    const { state, setState } = useContext(Context)
    const [showModal, setShowModal] = useState(false)
    const [payment, setPayment] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [clientToken, setClientToken] = useState('')
    const [passengerDetails, setPassengerDetails] = useState()
    const [acceptTerms, setAcceptTerms] = useState(false)
    const [paymentInProgress, setPaymentInProgress] = useState(false)
    const [paymentComplete, setPaymentComplete] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [alertAnimation, setAlertAnimation] = useState("")
    const [tripDetails, setTripDetails] = useState(state.tripDetails)
    const { step } = useParams();
    const navigate = useNavigate()

    useEffect(() => {
        // if there aren't any trip details then fetch them and reshow itinerary
        if (!Object.keys(tripDetails).length) {
            let deets = JSON.parse(sessionStorage.getItem('tripDetails'))
            setTripDetails(deets)
        }
    }, [tripDetails])

    useEffect(() => {
        if (hotelDetails.client_token) {
            setClientToken(hotelDetails.client_token)
        }
    }, [hotelDetails])

    const handleCheckout = () => {
        sendGACustomEvent({ category: 'Button Click', action: `Click on Checkout button for on hotel`, label: 'Hotel Checkout Button Click' })
        setProcessing(true)
        const deets = JSON.parse(sessionStorage.getItem('tripDetails'))
        getPaymentIntent({ amount: hotelDetails.total_amount, currency: hotelDetails.total_amount_currency })
            .then((res) => {
                getPaymentIntent({ amount: hotelDetails.room_details[0].rates[0].total_amount, currency: hotelDetails.total_amount_currency })
                    .then((res) => {
                        setClientToken(res.client_token)
                        setProcessing(false)
                        updateSessionData({ trip_id: deets.trip_id, session_data: { hotel_details: { ...hotelDetails, passenger_details: passengerDetails, client_token: res.client_token } } })
                        navigate('/hotels/checkout')
                    }).catch((e) => {
                        setProcessing(false)
                    })
            })
    }

    const handlePaymentSuccess = () => {
        const deets = JSON.parse(sessionStorage.getItem('tripDetails'))
        setPaymentInProgress(true)
        setAlertAnimation(paymentProcessing)
        setAlertMessage("Payment in progress")
        createHotelQuotes({ "rate_id": rateId })
            .then((res) => {
                // Upon successful response, extract quote_id and proceed to createHotelBooking
                createHotelBooking({
                    "trip_id": deets.trip_id,
                    "quote_id": res.data.id,
                    "phone_number": passengerDetails[0].phone_number,
                    "guests": passengerDetails.map(({ given_name, family_name }) => ({ given_name, family_name })),
                    "email": passengerDetails[0].email,
                    "accommodation_special_requests": ""
                })
                    .then((bookingRes) => {
                        // get pricing info for LeadDyno fee:
                        getWPFlightPrice({
                            "base_amount": hotelDetails.room_details[0].rates[0].base_amount,
                            "total_amount": hotelDetails.room_details[0].rates[0].total_amount,
                            "total_currency": hotelDetails.room_details[0].rates[0].total_currency,
                            "tax_amount": hotelDetails.room_details[0].rates[0].tax_amount,
                            "baggage_amount": "0.00",
                            "seats_amount": "0.00",
                            "CFAR_amount": "0.00",
                            "num_baggage": "0",
                            "num_seats": "0"
                        }).then((pricingRes) => {
                            setState(prevState => ({ ...prevState, hotelDetails: bookingRes }))
                            setAlertAnimation(paymentSuccess)
                            setPaymentComplete(true)
                            setAlertMessage("Hotel Booked Successfully")
                            // LeadDyno affiliate tracking
                            // LeadDyno.recordPurchase(passengerDetails[0].email, {
                            //     purchase_amount: pricingRes.wp_fee
                            // })
                        })
                    })
                    .catch((error) => {
                        // Handle any errors from createHotelBooking
                    });
            })
            .catch((error) => {
                // Handle any errors from createHotelQuotes
            });
    }

    const isBtnDisabled = () => {
        const hasEmptyField = passengerDetails && passengerDetails.some(passenger => {
            return (
                Object.values(passenger).some(value => value === "") ||
                !emailRegex.test(passenger.email) // Check for empty email and invalid format
            );
        });
        return (hasEmptyField || !acceptTerms)
    }

    return (
        <div className='hotel_checkout_page_container'>
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Cancellation and Booking Policies</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HotelPolicies policies={hotelDetails.room_details[0].rates[0]} />
                </Modal.Body>
            </Modal>

            <div className='hotel_details_checkout'>
                {
                    hotelDetails.room_details &&
                    <div className='hotel_detail_wrapper_card'>
                        <div className='hotel_overview'>
                            <div className='hotel_image_container'>
                                <img src={hotelDetails.photo[0].url} alt={hotelDetails.name} className='hotel_checkout_image' />
                            </div>
                            <HotelDetails hotel={hotelDetails} />
                            <hr />
                            {/* Divider */}

                            {/* View Details and Price */}
                            <div className='price_container_checkout'>
                                <div className='price_amount_checkout'>${hotelDetails.room_details[0].rates[0].total_amount}</div>
                                <div style={{ fontSize: '10px', marginBottom: '12px' }}> Includes taxes and fees</div>
                            </div>
                        </div>
                    </div>
                }
            </div>

            <div className='checkout_details'>

                {
                    step == 'customer' &&
                    <div className='checkout_card_wrapper'>
                        <HotelPassengerForm
                            passengers={hotelDetails.adults}
                            setPassengerDetails={setPassengerDetails}
                        />
                        <div className='row'>
                            <p>
                                <div class="input-group-text">
                                    <input
                                        class="form-check-input mt-0"
                                        type="checkbox"
                                        value={acceptTerms}
                                        onChange={(e) => {
                                            setAcceptTerms(!acceptTerms)
                                        }}
                                        aria-label="Checkbox for following text input"
                                    /> &nbsp;<span style={{ fontSize: '12px' }}>I have read the <a onClick={() => setShowModal(true)} style={{ cursor: 'pointer', color: '#035EDD' }}>Terms and Policies</a></span>
                                </div>
                            </p>
                        </div>
                        <div className='row checkout_button_container'>
                            <DefaultButton
                                buttonLabel={!processing ? "Checkout" : "Processing..."}
                                customButtonClass="secondary_button_styling"
                                disabled={isBtnDisabled()}
                                onClick={handleCheckout}
                            />
                        </div>
                    </div>
                }
                {
                    step == 'checkout' &&
                    <div className='checkout_card_wrapper'>
                        <div className='mb-4'>
                            <p className="fs-3 fw-bolder text-start mb-2">Checkout</p>
                            <div className='bill'>
                                <div className='billing_items mx-4'>
                                    <div className='d-flex justify-content-between mb-2'>
                                        <p>Base Fare</p>
                                        <p>${hotelDetails.room_details[0].rates[0].base_amount}</p>
                                    </div>
                                    {
                                        hotelDetails.room_details[0].rates[0].fee_amount !== "0.00" &&
                                        <div className='d-flex justify-content-between mb-2'>
                                            <p>Fees</p>
                                            <p>${hotelDetails.room_details[0].rates[0].fee_amount}</p>
                                        </div>
                                    }
                                    <div className='d-flex justify-content-between mb-2'>
                                        <p>Taxes</p>
                                        <p>${hotelDetails.room_details[0].rates[0].tax_amount}</p>
                                    </div>
                                    {
                                        (hotelDetails.room_details[0].rates[0].due_at_accommodation_amount) &&
                                        <div className='d-flex justify-content-between mb-2'>
                                            <div style={{ display: 'flex', flexDirection: 'column ' }}>
                                                Accomodation Fee
                                                <span style={{ fontSize: '12px', color: '636363' }}>Due on site</span>
                                            </div>
                                            <p>${Number(hotelDetails.room_details[0].rates[0].due_at_accommodation_amount)}</p>
                                        </div>
                                    }
                                    <hr />
                                    <div className='d-flex justify-content-between mb-2 text-primary'>
                                        <p className='fw-bold'>Due Now</p>
                                        <p className='fw-bold'>${hotelDetails.room_details[0].rates[0].total_amount}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            <div style={{ position: 'relative' }}>
                                {paymentInProgress && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: 'rgb(186 184 184 / 50%)', // Semi-transparent black overlay
                                            zIndex: 9999, // Ensure the overlay is on top of other content
                                            borderRadius: '8px'
                                        }}
                                    />
                                )}
                                <div style={paymentInProgress ? { opacity: '0.8' } : {}}>
                                    <div>
                                        <p className='fs-3 fw-bolder text-start mb-2'>Billing Information</p>
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        {
                                            clientToken &&
                                            < DuffelPayments
                                                className="custom_duffel_styling"
                                                paymentIntentClientToken={clientToken}
                                                onSuccessfulPayment={handlePaymentSuccess}
                                                onFailedPayment={console.log}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>

                        }
                        {
                            paymentInProgress && (
                                <div className='alert_modal'>
                                    <div className=''>
                                        <Lottie animationData={alertAnimation} loop={true} className='alert_animation' />
                                        <div className='alert_message'>{alertMessage}</div>
                                    </div>
                                    {
                                        paymentComplete &&
                                        <DefaultButton
                                            buttonLabel={"Go to Itinerary"}
                                            customButtonClass="inverted_secondary_button_styling"
                                            onClick={() => { navigate(`/trip/${tripDetails.trip_id}`); sendGACustomEvent({ category: 'Button Click', action: "Route user back to itinerary after hotel booking", label: 'Go to itinerary button click' }) }}
                                        />
                                    }
                                </div>
                            )
                        }
                    </div>
                }
            </div>
        </div >
    )
}

export default HotelCheckoutDetails
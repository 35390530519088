import { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import "react-multi-carousel/lib/styles.css"
import { useNavigate } from "react-router-dom"
import HowItWorks from '../../components/HowItWorks/howItWorks'
import NavBar from '../../components/Navbar/navbar'
import TripCollectionCard from '../../components/TripCollectionCard/tripCollectionCard'
import TripModify from '../../components/TripModify/tripModify'
import TripSearch from '../../components/TripSearch/tripSearch'
import {
    createItinerary,
    fetchAllTourOptions,
    fetchItinerary,
    fetchTripCollection,
    fetchTripDetails,
    getBookedFlightData,
    getBookedHotelData,
    makeItineraryFromCollection,
    login
} from '../../context/api'
import { Context } from '../../context/context'
import { capitalizeFirstLetter, sendGACustomEvent, sendGAPageEvent } from '../../utils/helpers'
import Loading from '../Loading/loading'
import "react-multi-carousel/lib/styles.css"
import './home.scss'
import CustomCarousel from '../../components/CustomCarousel/customCarousel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Footer from '../../components/Footer/footer'
import '../../css/common.scss';
import NewsletterBanner from '../../components/NewsletterBanner/newsletterBanner'
import DefaultButton from '../../components/DefaultButton/defaultButton'

const prePlannedTripsBreakpoints = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const BUDGET_MAPPINGS = {
    "cheap": "Budget-Friendly",
    "moderate": "Best Value",
    "luxery": "Luxurious",
    "Cheap": "Budget-Friendly",
    "Moderate": "Best Value",
    "Luxery": "Luxurious"
}

function Home() {
    const [loading, setLoading] = useState(false)
    const [firstCollection, setFirstCollection] = useState({})
    const [secondCollection, setSecondCollection] = useState({})
    const [thirdCollection, setThirdCollection] = useState({})
    const [selectedDestination, setSelectedDestination] = useState({})
    const [showModal, setShowModal] = useState(false)
    const { state, setState } = useContext(Context)
    const navigate = useNavigate();

    useEffect(() => {
        sendGAPageEvent({ hitType: 'pageview', page: '/', title: 'Landing on home page' })
        if (window.location.pathname === '/my_trips') {
            window.location.pathname = '/'
        }
        // we will be showing the collections in different places so splitting them here
        fetchTripCollection().then((res) => {
            Object.keys(res).forEach((key, i) => {
                if (i === 0) {
                    setFirstCollection({ [key]: res[key] })
                } else if (i === 1) {
                    setSecondCollection({ [key]: res[key] })
                } else if (i === 2) {
                    setThirdCollection({ [key]: res[key] })
                }
            })
        })
    }, [])

    const handleSearchClick = (tripDetailsPayload) => {
        sendGACustomEvent({
            category: 'Button Click',
            action: 'Creates Itinerary',
            label: 'Lets Go button'
        })

        setLoading(true)
        setState(prevState => ({
            ...prevState,
            flightDetails: {},
            hotelDetails: {},
        }))

        fetchTripDetails(tripDetailsPayload)
            .then(res => {
                // save trip details in session storage
                sessionStorage.setItem('tripDetails', JSON.stringify(res))
                setState(prevState => ({ ...prevState, tripDetails: res }))
                // if the itinerary is being created for the first time:
                if (!res.itinerary_id) {
                    let itineraryPayload = {
                        user_id: tripDetailsPayload.user_id,
                        trip_id: res.trip_id,
                        destination_name: res.destination_name,
                        destination_id: tripDetailsPayload.destination_id,
                        iata_code: tripDetailsPayload.destination_iata_code,
                        start_date: tripDetailsPayload.start_date,
                        end_date: tripDetailsPayload.end_date,
                        itinerary_collection: "base",
                        adult: tripDetailsPayload.adult
                    }
                    createItinerary(itineraryPayload)
                        .then(itRes => {
                            if (itRes) {
                                let toursPayload = {
                                    "destination_id": tripDetailsPayload.destination_id,
                                    "itinerary": itRes,
                                    "members": { "ADULT": tripDetailsPayload.adult },
                                    "budget": 0
                                }
                                fetchAllTourOptions(toursPayload)
                                    .then(toursRes => {
                                        setState(prevState => ({ ...prevState, tripItinerary: toursRes }))
                                        navigate(`trip/${res.trip_id}`)
                                    })
                            }
                        })
                } else {
                    fetchItinerary({ trip_id: res.trip_id })
                        .then(itRes => {
                            if (itRes) {
                                let toursPayload = {
                                    "destination_id": tripDetailsPayload.destination_id,
                                    "itinerary": itRes,
                                    "members": { "ADULT": tripDetailsPayload.adult },
                                    "budget": 0
                                }
                                fetchAllTourOptions(toursPayload)
                                    .then(toursRes => {
                                        setState(prevState => ({ ...prevState, tripItinerary: toursRes }))
                                        if (res.flight_booking_id) {
                                            getBookedFlightData({ id: res.flight_booking_id })
                                                .then((flightResponseData) => {
                                                    setState(prevState => ({
                                                        ...prevState,
                                                        flightDetails: flightResponseData,
                                                    }))
                                                })
                                        }
                                        if (res.hotel_booking_id) {
                                            getBookedHotelData({ id: res.hotel_booking_id, email: "", reference: "" })
                                                .then((hotelResponseData) => {
                                                    setState(prevState => ({
                                                        ...prevState,
                                                        hotelDetails: hotelResponseData,
                                                    }))
                                                })
                                        }
                                        navigate(`trip/${res.trip_id}`)
                                    })
                            }
                        })
                }
            })
    }


    const handleModifyClick = (tripDetailsPayload) => {
        sendGACustomEvent({
            category: 'Button Click',
            action: 'Creates Itinerary',
            label: 'Lets Go button'
        })

        setLoading(true)
        let makeItineraryFromCollectionPayload = {
            "user_id": tripDetailsPayload.user_id,
            "itinerary_id": selectedDestination.itinerary_id,
            "destination_id": selectedDestination.destination_id,
            "destination_name": selectedDestination.destination_name,
            "iata_code": selectedDestination.destination_iata_code,
            "start_date": tripDetailsPayload.start_date,
            "end_date": tripDetailsPayload.end_date
        }

        let tripDetailsRequestPayload = {
            "user_id": tripDetailsPayload.user_id,
            "origin_name": "",
            "origin_id": "",
            "destination_id": selectedDestination.destination_id,
            "destination_name": selectedDestination.destination_name,
            "iata_code": selectedDestination.destination_iata_code,
            "adult": tripDetailsPayload.adult,
            "child": tripDetailsPayload.child,
            "start_date": tripDetailsPayload.start_date,
            "end_date": tripDetailsPayload.end_date,
            "rooms": tripDetailsPayload.rooms
        }

        setState(prevState => ({
            ...prevState,
            flightDetails: {},
            hotelDetails: {},
        }))

        fetchTripDetails(tripDetailsRequestPayload)
            .then(res => {
                // save trip details in session storage
                sessionStorage.setItem('tripDetails', JSON.stringify(res))
                setState(prevState => ({ ...prevState, tripDetails: res }))

                // if the itinerary is being created for the first time:
                makeItineraryFromCollection({ ...makeItineraryFromCollectionPayload, trip_id: res.trip_id })
                    .then(itRes => {
                        if (itRes) {
                            let toursPayload = {
                                "destination_id": itRes.destination_id,
                                "itinerary": itRes.data,
                                "members": { "ADULT": itRes.adult },
                                "budget": 5000
                            }
                            fetchAllTourOptions(toursPayload)
                                .then(toursRes => {
                                    setState(prevState => ({ ...prevState, tripItinerary: toursRes }))
                                    if (res.flight_booking_id) {
                                        getBookedFlightData({ id: res.flight_booking_id })
                                            .then((flightResponseData) => {
                                                setState(prevState => ({
                                                    ...prevState,
                                                    flightDetails: flightResponseData,
                                                }))
                                            })
                                    }
                                    if (res.hotel_booking_id) {
                                        getBookedHotelData({ id: res.hotel_booking_id, email: "", reference: "" })
                                            .then((hotelResponseData) => {
                                                setState(prevState => ({
                                                    ...prevState,
                                                    hotelDetails: hotelResponseData,
                                                }))
                                            })
                                    }
                                    navigate(`trip/${res.trip_id}`)
                                })
                        }
                    })
            })
    }

    return (
        <div>
            <NavBar getLoadingState={val => setLoading(val)} />
            {loading ? <Loading spinnerStyle="classic" /> :
                <div className='home_page_wrapper'>
                    <div className='home_top_banner'>
                        <div>From beginning to end,</div>
                        <div>we got you all planned out</div>
                    </div>
                    <div className='home_bottom_half'>
                        <TripSearch onSearch={handleSearchClick} topPlacement={'350px'} usage='trip_destination' />
                        <div className='home_guided_trips_banner'>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div className='new_feature_tag'>
                                    <FontAwesomeIcon icon="fa-solid fa-fire" style={{ marginRight: '5px' }} />
                                    Trending
                                </div>
                                <div className='surprise_trips_title'>Explore our international guided tours</div>
                                <div className='surprise_trips_subtitle'>Our international partners will make sure you have the best trip 🤝</div>
                            </div>
                            <DefaultButton
                                buttonLabel="Explore Now"
                                buttonStyle={{ padding: '12px', backgroundColor: '#035EDD', width: '150px' }}
                                onClick={() => { navigate('/international_guided_tours') }}
                            />
                        </div>
                        <div className='home_surprise_trips_banner'>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div className='new_feature_tag'>
                                    <FontAwesomeIcon icon="fa-solid fa-star" style={{ marginRight: '5px' }} />
                                    New Feature
                                </div>
                                <div className='surprise_trips_title'>Explore our surprise trips</div>
                                <div className='surprise_trips_subtitle'>Tell us your preferences and let our AI work its magic 🔮</div>
                            </div>
                            <DefaultButton
                                buttonLabel="Surprise Me"
                                buttonStyle={{ padding: '12px', backgroundColor: '#db48a5', width: '150px' }}
                                onClick={() => { navigate('/surprise') }}
                            />
                        </div>
                        <HowItWorks />
                        <div className='home_carousel_container'>
                            <div className='home_carousel_title'>{Object.keys(firstCollection)[0]}</div>
                            <CustomCarousel responsiveBreakpoints={prePlannedTripsBreakpoints}>
                                {Object.values(firstCollection).flat().map((destination, i) => {
                                    return (
                                        <div
                                            style={{ margin: '0 18px 32px 0' }}
                                            onClick={() => {
                                                setSelectedDestination(destination)
                                                setShowModal(true)
                                            }}
                                        >
                                            <TripCollectionCard destination={destination} key={i} />
                                        </div>
                                    )
                                })}
                            </CustomCarousel>
                        </div>
                        <NewsletterBanner />
                        <div className='home_carousel_container'>
                            <div className='home_carousel_title'>{Object.keys(secondCollection)[0]}</div>
                            <CustomCarousel responsiveBreakpoints={prePlannedTripsBreakpoints}>
                                {Object.values(secondCollection).flat().map((destination, i) => {
                                    return (
                                        <div
                                            style={{ margin: '0 18px 32px 0' }}
                                            onClick={() => {
                                                setSelectedDestination(destination);
                                                setShowModal(true)
                                            }}
                                        >
                                            <TripCollectionCard destination={destination} key={i} />
                                        </div>
                                    )
                                })}
                            </CustomCarousel>
                        </div>
                        <div className='home_carousel_container'>
                            <div className='home_carousel_title'>{Object.keys(thirdCollection)[0]}</div>
                            <CustomCarousel responsiveBreakpoints={prePlannedTripsBreakpoints}>
                                {Object.values(thirdCollection).flat().map((destination, i) => {
                                    return (
                                        <div
                                            style={{ margin: '0 18px 32px 0' }}
                                            onClick={() => {
                                                setSelectedDestination(destination);
                                                setShowModal(true)
                                            }}
                                        >
                                            <TripCollectionCard destination={destination} key={i} />
                                        </div>
                                    )
                                })}
                            </CustomCarousel>
                        </div>
                    </div>
                    <Modal
                        show={showModal}
                        onHide={() => setShowModal(false)}
                        size="l"
                    >
                        <Modal.Header closeButton />
                        <Modal.Body>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ fontWeight: '800', color: '#035EDD', fontSize: '26px', marginBottom: '5px' }}>
                                    Pre-Curated {selectedDestination.trip_days} day guide to {selectedDestination.destination_name}
                                </div>
                                <div style={{ fontSize: '14px', fontWeight: '500', marginBottom: '18px', color: '#636363', backgroundColor: 'rgb(211, 211, 211, 0.1)', borderRadius: '8px' }}>
                                    Our AI has searched and curated the perfect getaway for you. You are clicks away from your next dream vacation.
                                </div>
                                <div className='curated_trips_info_container'>
                                    <img src={selectedDestination.destination_image_url} className='curated_trips_img' />
                                    <div className='curated_trips_info_cell'>
                                        <div style={{ fontSize: '12px', fontWeight: '300', color: '#636363' }}>
                                            {selectedDestination.about_destination}
                                        </div>
                                        <div style={{ fontWeight: 600, fontSize: '14px' }}>Trip Highlights:</div>
                                        <div className='icon_subtext_card_info' style={{ fontSize: '12px' }}>
                                            <FontAwesomeIcon icon="fa-regular fa-calendar" />
                                            Trip Duration: {selectedDestination.trip_days} {selectedDestination.trip_days === 1 ? 'day' : 'days'}
                                        </div>
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {selectedDestination.budget && <div className='tagCell' style={{ margin: '0 8px 0 0' }}>{BUDGET_MAPPINGS[selectedDestination.budget]}</div>}
                                            {selectedDestination.season && <div className='tagCell' style={{ margin: '0 8px 0 0' }}>{capitalizeFirstLetter(selectedDestination.season)}</div>}
                                            {selectedDestination.trip_type && <div className='tagCell' style={{ margin: '0 8px 0 0' }}>{capitalizeFirstLetter(selectedDestination.trip_type)}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ borderTop: '2px dotted #D9D9D9', marginTop: '14px' }}>
                                    <div style={{ fontSize: '20px', fontWeight: '800', margin: '12px 0 8px 0', color: '#FD905E', textAlign: 'center' }}>Work with our AI agent to plan your perfect vacation</div>
                                    <div style={{ fontSize: '16px', color: '#636363', fontWeight: '300', textAlign: 'center' }}>We just need a couple more details to get going.</div>
                                    <div style={{ fontSize: '16px', marginBottom: '12px', fontWeight: '400', textAlign: 'center' }}>Next Step: Select your flights and hotels</div>
                                    <TripModify onSearch={handleModifyClick} topPlacement={'0px'} selectedDestination={selectedDestination} />
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>}
            <Footer />
        </div>
    )
}

export default Home
import axios from 'axios'

// load profile
const profile = process.env.REACT_APP_PROFILE;

// for prod:
const DATA_SERVICE_API_URL = 'https://data-service-vdrbzmu6pa-uw.a.run.app'
const PLANNING_ENGINE_API_URL = 'https://planning-engine-vdrbzmu6pa-uw.a.run.app'

// for dev:
// const DATA_SERVICE_API_URL = 'https://data-service-dev-vdrbzmu6pa-uw.a.run.app'
// const PLANNING_ENGINE_API_URL = 'https://planning-engine-dev-vdrbzmu6pa-uw.a.run.app'

// for local:
// const DATA_SERVICE_API_URL = 'http://127.0.0.1:8000'
// const PLANNING_ENGINE_API_URL = 'http://127.0.0.1:8000'

export function makeItineraryFromCollection(payload) {
    return axios.post(`${PLANNING_ENGINE_API_URL}/make_itinerary_from_collection/`, payload)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

export function fetchTripCollection(payload) {
    return axios.get(`${DATA_SERVICE_API_URL}/trip/get_collections/`, payload)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

export function fetchTripDetails(payload) {
    return axios.post(`${DATA_SERVICE_API_URL}/trip/details/`, payload)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

export function getWPFlightPrice(payload) {
    return axios.post(`${DATA_SERVICE_API_URL}/trip/get_price/`, payload)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

export function createItinerary(payload) {
    return axios.post(`${PLANNING_ENGINE_API_URL}/make_itinerary/`, payload)
        .then((res) => {
            return res.data.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

export function fetchItinerary(payload) {
    return axios.post(`${DATA_SERVICE_API_URL}/trip/fetch/itinerary/`, payload)
        .then((res) => {
            return res.data.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

export function customizeItinerary(payload) {
    return axios.post(`${PLANNING_ENGINE_API_URL}/customize/`, payload)
        .then((res) => {
            return res.data.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

export function login(payload) {
    return axios.post(`${DATA_SERVICE_API_URL}/users/auth/`, payload)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

export function fetchPreviousTrips(payload) {
    return axios.post(`${DATA_SERVICE_API_URL}/users/trips/`, payload)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

export function fetchAllTourOptions(payload) {
    return axios.post(`${PLANNING_ENGINE_API_URL}/get_all_tours/`, payload)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

// Blogs API

export function fetchAllBlogs() {
    return axios.get(`${DATA_SERVICE_API_URL}/blog/get_all_blogs/`,)
        .then((res) => {
            return res.data.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

export function fetchBlogById(payload) {
    return axios.get(`${DATA_SERVICE_API_URL}/blog/get_blog_by_id/?title_id=${payload}`)
        .then((res) => {
            return res.data.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

export function fetchAllBookings(payload) {
    return axios.post(`${DATA_SERVICE_API_URL}/flight/get_trip_data/`, payload)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

export function updateSessionData(payload) {
    return axios.post(`${DATA_SERVICE_API_URL}/trip/update_session_data/`, payload)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

export function getSessionData(payload) {
    return axios.post(`${DATA_SERVICE_API_URL}/trip/get_session_data/`, payload)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

// Flight API

export function fetchAllFlightOffers(payload) {
    return axios.post(`${DATA_SERVICE_API_URL}/flight/create_offer_requests/`, payload)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

export function getPaymentIntent(payload) {
    return axios.post(`${DATA_SERVICE_API_URL}/flight/payment_intents/`, payload)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

export function getBookedFlightData(payload) {
    return axios.post(`${DATA_SERVICE_API_URL}/flight/get_flightdata/`, payload)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

export function bookFlightOffer(payload) {
    return axios.post(`${DATA_SERVICE_API_URL}/flight/book/`, payload)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

export function bookingConfirmation(payload) {
    return axios.post(`${DATA_SERVICE_API_URL}/flight/confirm_payment/`, payload)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

export function getFlightRefundQuote(payload) {
    return axios.post(`${DATA_SERVICE_API_URL}/flight/cancellation_quote/`, payload)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

export function confirmFlightCancellation(payload) {
    return axios.post(`${DATA_SERVICE_API_URL}/flight/confirm_cancellation/`, payload)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

// Hotel Book

export function fetchAllHotelOffers(payload) {
    return axios.post(`${DATA_SERVICE_API_URL}/hotel/search/`, payload)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

export function fetchHotelById(payload) {
    return axios.post(`${DATA_SERVICE_API_URL}/hotel/search_id/`, payload)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

export function createHotelQuotes(payload) {
    return axios.post(`${DATA_SERVICE_API_URL}/hotel/quotes/`, payload)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

export function createHotelBooking(payload) {
    return axios.post(`${DATA_SERVICE_API_URL}/hotel/bookings/`, payload)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

export function cancelHotelBooking(payload) {
    return axios.post(`${DATA_SERVICE_API_URL}/hotel/confirm_cancellation/`, payload)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

export function getBookedHotelData(payload) {
    return axios.post(`${DATA_SERVICE_API_URL}/hotel/get_hoteldata/`, payload)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

// Contact

export function sentQueryToEmail(payload) {
    return axios.post(`${DATA_SERVICE_API_URL}/contact/email/`, payload)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

export function cancelRequestEmail(payload) {
    return axios.post(`${DATA_SERVICE_API_URL}/contact/cancel_request/`, payload)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            console.error(err)
            window.location.href = '/error'
        })
}

// Airports and Cities endpoint 

export async function fetchCityAndAirports() {
    try {
        const res = await axios.get(`${DATA_SERVICE_API_URL}/db_ops/fetch_origin_airports/`)
        return res.data
    } catch (err) {
        console.error(err)
    }
}

// newsletter api 

export async function newsletterSignUp(payload) {
    try {
        const res = await axios.post(`${DATA_SERVICE_API_URL}/users/newsletter_signup/`, payload)
        return res.data
    } catch (err) {
        console.error(err)
        return err
    }
}

// surprise trips api 

export async function createSurpriseRequest(payload) {
    try {
        const res = await axios.post(`${DATA_SERVICE_API_URL}/trip/submit_surprise_trip/`, payload)
        return res.data
    } catch (err) {
        console.error(err)
        return err
    }
}

// guided trips

export async function createManagedTripRequest(payload) {
    try {
        const res = await axios.post(`${DATA_SERVICE_API_URL}/trip/submit_managed_trip_request/`, payload)
        return res.data
    } catch (err) {
        console.error(err)
        return err
    }
}
import { DuffelAncillaries, DuffelPayments } from '@duffel/components'
import Lottie from "lottie-react"
import React, { useContext, useEffect, useState } from 'react'
import { Stepper } from 'react-form-stepper'
import { useNavigate, useParams } from 'react-router-dom'
import paymentSuccess from '../../assets/animations/paymentSuccess.json'
import paymentProcessing from '../../assets/animations/processing.json'
import CustomModal from '../../components/CustomModal/customModal'
import DefaultButton from '../../components/DefaultButton/defaultButton'
import FlightCard from '../../components/FlightCard/flightCard'
import FlightJourneyCard from '../../components/FlightJourneyCard/flightJourneyCard'
import NavBar from '../../components/Navbar/navbar'
import FlightPassengerForm from '../../components/PassengerForm/flightPassengerForm'
import { bookFlightOffer, bookingConfirmation, fetchAllFlightOffers, getPaymentIntent, getSessionData, getWPFlightPrice, updateSessionData } from '../../context/api'
import { Context } from '../../context/context'
import { emailRegex, sendGACustomEvent, sendGAPageEvent } from '../../utils/helpers'
import Loading from '../Loading/loading'
import './flight.scss'
import '../../css/common.scss'
import '../../css/flightCommon.scss'
import searchDestinations from "../..//constants/searchDestinationsSimplified.json"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FLIGHT_SUBTEXT = {
    "Best Value": "Carefully selected flights offering a balance of price, timing, and quality.",
    "Budget-Friendly": "Affordable flights designed to be light on your wallet.",
    "Eco-Friendly Journeys": "Flights with minimal carbon emissions, promoting environmental sustainability.",
    "For the Early Birds": "Ideal flights for travelers who prefer to depart early in the morning.",
    "Best Late Night Flights": "Convenient flights tailored for travelers preferring to fly after work hours."
}

const flightStepName = [
    {
        id: 0,
        label: "Search",
        title: "Explore Flights",
        step: "search",
    },
    {
        id: 1,
        label: "Passenger Details",
        title: "Passenger Details",
        step: "form",
    },
    {
        id: 2,
        label: "Checkout",
        title: "Book Flight",
        step: "checkout",
    },
]

function Flight() {
    const { state, setState } = useContext(Context)
    const [loading, setLoading] = useState(false)
    const [fetching, setFetching] = useState(false)
    const [view, setView] = useState('flights')
    const [flightDetails, setFlightDetails] = useState({})
    const [paymentComplete, setPaymentComplete] = useState(false)
    const [payment, setPayment] = useState(false)
    const [clientToken, setClientToken] = useState('')
    const [processing, setProcessing] = useState(false)
    const [flightServices, setFlightServices] = useState({
        baggage_services: [],
        cancel_for_any_reason_services: [],
        seat_services: []
    });
    const [baggageTotalAmount, setBaggageTotalAmount] = useState(0);
    const [cancelTotalAmount, setCancelTotalAmount] = useState(0);
    const [seatTotalAmount, setSeatTotalAmount] = useState(0);
    const [serviceCharge, setServiceCharge] = useState(0)
    const [totalFlightCharge, setTotalFlightCharge] = useState(0)
    const [totalFlightChargeWithFees, setTotalFlightChargeWithFees] = useState(0)
    const [passengerDetails, setPassengerDetails] = useState([])
    const [paymentIntentId, setPaymentIntentId] = useState()
    const [flightList, setFlightList] = useState({})
    const [paymentInProgress, setPaymentInProgress] = useState(false)
    const [tripDetails, setTripDetails] = useState(JSON.parse(sessionStorage.getItem('tripDetails')))
    const [alertMessage, setAlertMessage] = useState("")
    const [alertAnimation, setAlertAnimation] = useState("")
    const [wpRecommendedAmount, setWpRecommendedAmount] = useState({
        "base_amount": "0",
        "total_amount": "0",
        "tax_amount": "0",
        "baggage_amount": "0",
        "seats_amount": "0",
        "CFAR_amount": "0",
        "num_baggage": "0",
        "num_seats": "0",
        "wp_fee": "",
        "wp_total_amount": "",
        "wp_fees_and_taxes": "",
        "total_currency": ""
    })
    const { step } = useParams()
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false)
    const [showAirportOptions, setShowAirportOptions] = useState(false)
    const [primaryAirport, setPrimaryAirport] = useState({})
    const [nearbyAirports, setNearbyAirports] = useState([])

    useEffect(() => {
        if (flightDetails?.wp_total_amount) {
            setWpRecommendedAmount({
                ...wpRecommendedAmount,
                base_amount: flightDetails.base_amount,
                total_amount: flightDetails.total_amount,
                tax_amount: flightDetails.tax_amount,
                total_currency: flightDetails.total_currency
            })
        }
    }, [flightDetails])

    useEffect(() => {

        let wpFlightPricePayload = {
            "base_amount": wpRecommendedAmount.base_amount,
            "total_amount": wpRecommendedAmount.total_amount,
            "tax_amount": wpRecommendedAmount.tax_amount,
            "total_currency": wpRecommendedAmount.total_currency,
            "baggage_amount": "0",
            "seats_amount": "0",
            "CFAR_amount": "0",
            "num_baggage": "0",
            "num_seats": "0"
        }

        // Calculate baggage total amount
        if (flightServices.baggage_services && flightServices.baggage_services.length > 0) {
            const totalAmount = flightServices.baggage_services.reduce((acc, service) => acc + parseFloat(service.serviceInformation.total_amount), 0);
            wpFlightPricePayload.baggage_amount = totalAmount.toString(); // Update baggage amount
            wpFlightPricePayload.num_baggage = flightServices.baggage_services.length.toString(); // Update number of baggage
            setBaggageTotalAmount(totalAmount);
        }

        // Calculate cancel total amount
        if (flightServices.cancel_for_any_reason_services && flightServices.cancel_for_any_reason_services.length > 0) {
            const totalAmount = flightServices.cancel_for_any_reason_services.reduce((acc, service) => acc + parseFloat(service.serviceInformation.total_amount), 0);
            wpFlightPricePayload.CFAR_amount = totalAmount.toString(); // Update cancel for any reason amount
            setCancelTotalAmount(totalAmount);
        }

        // Calculate seat total amount
        if (flightServices.seat_services && flightServices.seat_services.length > 0) {
            const totalAmount = flightServices.seat_services.reduce((acc, service) => acc + parseFloat(service.serviceInformation.total_amount), 0);
            wpFlightPricePayload.seats_amount = totalAmount.toString(); // Update seats amount
            wpFlightPricePayload.num_seats = flightServices.seat_services.length.toString(); // Update number of seats    
            setSeatTotalAmount(totalAmount);
        }

        if (wpFlightPricePayload.base_amount != 0) {
            getWPFlightPrice(wpFlightPricePayload).then((res) => {
                setWpRecommendedAmount(res)
            })
        }

    }, [flightServices]);

    useEffect(() => {
        sendGAPageEvent({ hitType: 'pageview', page: `/flight?step=${step}`, title: `Landing on flight ${step} page` })
        // Update services total whenever any of the individual service totals change
        if (step == "form") {
            let tempTotalServiceCharge = baggageTotalAmount + cancelTotalAmount + seatTotalAmount
            let tempTotalFlightCharge = Number(flightDetails.total_amount) + tempTotalServiceCharge
            let tempTotalFlightChargeWithFees = tempTotalFlightCharge + Number((Math.ceil(tempTotalFlightCharge * 0.1 * 100) / 100).toFixed(2))
            setServiceCharge((Math.ceil(tempTotalServiceCharge * 100) / 100).toFixed(2));
            setTotalFlightCharge((Math.ceil(tempTotalFlightCharge * 100) / 100).toFixed(2))
            setTotalFlightChargeWithFees((Math.ceil(tempTotalFlightChargeWithFees * 100) / 100).toFixed(2))
        }
    }, [baggageTotalAmount, cancelTotalAmount, seatTotalAmount, step]);

    useEffect(() => {
        const deets = JSON.parse(sessionStorage.getItem('tripDetails'))
        setTripDetails(deets)
        if (step == "form") {
            setFlightServices({
                baggage_services: [],
                cancel_for_any_reason_services: [],
                seat_services: []
            })
            setSeatTotalAmount(0)
            setCancelTotalAmount(0)
            setBaggageTotalAmount(0)
            if (Object.keys(flightDetails).length == 0) {
                getSessionData({ trip_id: deets.trip_id })
                    .then((res) => {
                        if (res.flight_details) {
                            setFlightDetails(res.flight_details)
                            setWpRecommendedAmount({
                                ...wpRecommendedAmount,
                                wp_fee: res.flight_details.wp_fee,
                                wp_total_amount: res.flight_details.wp_total_amount,
                                wp_fees_and_taxes: res.flight_details.wp_fees_and_taxes,
                                total_currency: res.flight_details.total_currency
                            })
                        }
                    })
            }
        }
        if (step == "checkout") {
            if (Object.keys(flightDetails).length == 0) {
                getSessionData({ trip_id: deets.trip_id })
                    .then((res) => {
                        if (res.flight_details) {
                            setClientToken(res.flight_details.client_key)
                            setFlightDetails(res.flight_details)
                        }
                    })
            }
        }
    }, [step])

    useEffect(() => {
        const deets = JSON.parse(sessionStorage.getItem('tripDetails'))
        if (Object.keys(flightDetails).length > 0) {
            updateSessionData({ trip_id: deets.trip_id, session_data: { flight_details: flightDetails } })
                .then((res) => {
                    if (res.length > 0) res.flight_details && setFlightDetails(res.flight_details)
                })
        }
    }, [flightDetails])

    useEffect(() => {
        const deets = JSON.parse(sessionStorage.getItem('tripDetails'))
        // get the current nearby airports option:
        if (deets) {
            let airportObj = searchDestinations.find(obj => obj.id === deets.destination_id)
            // set the primary airport
            setPrimaryAirport(airportObj.airport_options.primary_airport)
            setNearbyAirports(airportObj.airport_options.secondary_airports)
        }
        if (!Object.keys(tripDetails).length && deets) {
            setTripDetails(deets)
        }
    }, [])

    useEffect(() => {
        if (step == 'search') {
            if (Object.keys(tripDetails).length) {
                const originDetails = JSON.parse(sessionStorage.getItem('originDetails'))
                let flightPayload = {
                    origin_iata: originDetails.origin_iata_code.toString(),
                    destination_iata: tripDetails.destination_iata_code,
                    start_date: tripDetails.start_date,
                    end_date: tripDetails.end_date,
                    adult: tripDetails.adult,
                    child: tripDetails.child
                }
                setFetching(true)
                fetchAllFlightOffers(flightPayload)
                    .then(res => {
                        setFlightList(res)
                        setFetching(false)
                    })
            }
        }
    }, [tripDetails, step])

    const onViewDetails = (flight) => {
        setFlightDetails(flight)
        setShowModal(true)
    }

    const closeModalAndNavigate = (path) => {
        return new Promise((resolve) => {
            setShowModal(false)
            resolve()
        }).then(() => {
            navigate(path)
        })
    }

    const renderFooter = (flight) => {
        if (flight) {
            return (
                <div className="price_btn_wrapper">
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <div className='price_amount'>${flightDetails.wp_total_amount}</div>
                        <div style={{ fontSize: '10px', marginBottom: '12px' }}> Includes taxes and fees</div>
                    </div>
                    <div style={{ width: '80%' }}>
                        <DefaultButton
                            buttonLabel={"Select"}
                            customButtonClass="secondary_button_styling"
                            buttonStyle={{ fontSize: '14px', width: '100%' }}
                            onClick={() => { closeModalAndNavigate('/flights/form'); sendGACustomEvent({ category: 'Button Click', action: `navigates user to flight/form page`, label: 'On Select flight button click' }) }}
                        />
                    </div>
                </div>
            )
        }
    }

    const handleCheckout = () => {
        sendGACustomEvent({ category: 'Button Click', action: `handles flight checkout`, label: 'On Flight Checkout button click' })
        setProcessing(true)
        setFlightDetails({ ...flightDetails, passengers: passengerDetails })
        let updateSesisonDataPayload = {
            trip_id: tripDetails.trip_id,
            session_data: {
                flight_details: {
                    ...flightDetails,
                    passengers: passengerDetails,
                }
            }
        }

        let intentPayload = { amount: wpRecommendedAmount.wp_total_amount, currency: wpRecommendedAmount.total_currency }
        updateSessionData(updateSesisonDataPayload)
            .then((sessionResponse) => {
                getPaymentIntent(intentPayload).then((res) => {
                    setPaymentIntentId(res.id)
                    setClientToken(res.client_token)
                    setProcessing(false)
                    navigate('/flights/checkout')
                }).catch((e) => {
                    setProcessing(false)
                })
            })
    }

    const handlePaymentSuccess = (e) => {
        setPaymentInProgress(true)
        setAlertAnimation(paymentProcessing)
        setAlertMessage("Payment in progress")
        const totalDuffleFlightAmount = Number(wpRecommendedAmount.total_amount) + Number(wpRecommendedAmount.baggage_amount) + Number(wpRecommendedAmount.CFAR_amount) + Number(wpRecommendedAmount.seats_amount)
        bookingConfirmation({ id: paymentIntentId })
            .then((confirmationRes) => {
                if (confirmationRes.id) {
                    let bookingRequestData = {
                        "trip_id": tripDetails.trip_id,
                        "services": flightServices,
                        "selected_offers": [
                            flightDetails.offer_id
                        ],
                        "payments": [
                            {
                                "type": "balance",
                                "currency": wpRecommendedAmount.total_currency,
                                "amount": (Math.ceil(totalDuffleFlightAmount * 100) / 100).toFixed(2).toString()
                            }
                        ],
                        "passengers": passengerDetails,
                        "metadata": {
                            "payment_intent_id": confirmationRes.id
                        },
                        "pricing": wpRecommendedAmount
                    }
                    bookFlightOffer(bookingRequestData).then((res) => {
                        setState(prevState => ({ ...prevState, flightDetails: res }));
                        setAlertAnimation(paymentSuccess)
                        setPaymentComplete(true)
                        setAlertMessage("Flight Booked Successfully")
                        // LeadDyno affiliate tracking
                        // LeadDyno.recordPurchase(passengerDetails[0].email, {
                        //     purchase_amount: wpRecommendedAmount.wp_fee
                        // })
                    })
                }
            })
    }

    const isBtnDisabled = () => {
        if (passengerDetails && Object.keys(passengerDetails).length > 0) {
            const hasEmptyField = passengerDetails.some(passenger => {
                return (
                    Object.values(passenger).some(value => value === "") ||
                    !emailRegex.test(passenger.email) // Check for empty email and invalid format
                );
            });
            return hasEmptyField
        }
        return true
    }

    const onSelectAirportClick = (airport) => {
        // remove incoming airport from nearby airports:
        let nearbyAirportsCopy = [...nearbyAirports]
        let nearbyAiportsFiltered = nearbyAirportsCopy.filter(air => air.iata_code !== airport.iata_code)
        // add CURRENT primary airport to nearby airports
        nearbyAiportsFiltered = [primaryAirport, ...nearbyAiportsFiltered]
        setNearbyAirports(nearbyAiportsFiltered)
        // set new primary airport:
        setPrimaryAirport(airport)

        // close the dropdown:
        setShowAirportOptions(false)

        // trigger a new flight search with new primary airport:
        if (Object.keys(tripDetails).length) {
            const originDetails = JSON.parse(sessionStorage.getItem('originDetails'))
            let flightPayload = {
                origin_iata: originDetails.origin_iata_code.toString(),
                destination_iata: airport.iata_code,
                start_date: tripDetails.start_date,
                end_date: tripDetails.end_date,
                adult: tripDetails.adult,
                child: tripDetails.child
            }
            setFetching(true)
            fetchAllFlightOffers(flightPayload)
                .then(res => {
                    setFlightList(res)
                    setFetching(false)
                })
        }
    }

    return (
        <div className='flight_wrapper'>
            <NavBar getLoadingState={val => setLoading(val)} />

            {(Object.keys(tripDetails).length) &&
                <div className='hotel_flight_selection_wrapper'>
                    {
                        fetching ?
                            <Loading /> :
                            <div>
                                <Stepper
                                    styleConfig={{
                                        activeBgColor: '#FD905E',
                                        completedBgColor: '#FD905E'
                                    }}
                                    connectorStyleConfig={{
                                        activeColor: '#FD905E',
                                        completedColor: '#FD905E'
                                    }}
                                    steps={flightStepName.map(step => ({ label: step.label }))}
                                    activeStep={flightStepName.find(item => item.step === step).id}
                                />
                                <div className='hotel_flight_page_title'>{flightStepName.find(item => item.step === step).title}</div>
                                {step === 'search' && <div className='flight_hotel_subtitle'>Explore our unique AI-curated categories for a more personalized experience</div>}
                                {Object.keys(primaryAirport).length ?
                                    <div className='arrival_airport_container'>
                                        <div className='icon_subtext_card_info'>
                                            <FontAwesomeIcon icon="fa-solid fa-plane-arrival" />
                                            <div style={{ marginLeft: '10px' }}>
                                                <span style={{ fontSize: '18px', fontWeight: '900' }}>Arrival Airport:</span>
                                                <span style={{ fontSize: '18px', fontWeight: '300', marginLeft: '10px' }}>
                                                    {primaryAirport.airport_name} • {primaryAirport.iata_code}
                                                </span>
                                                <div style={{ color: '#636363', fontSize: '12px' }}>Approximately {primaryAirport.distance} miles away</div>
                                            </div>
                                        </div>
                                        <div className='explore_arrival_airport_options' onClick={() => setShowAirportOptions(!showAirportOptions)}>{showAirportOptions ? 'Hide' : 'Explore'} Other Options</div>
                                        {showAirportOptions ?
                                            <div>
                                                {nearbyAirports.map((airport, i) => {
                                                    return (
                                                        <div key={i} style={{ borderBottom: '1px solid #D9D9D9', padding: '10px 5px', display: 'flex', justifyContent: 'space-between' }}>
                                                            <div>
                                                                <span style={{ fontSize: '16px', fontWeight: '300', marginBottom: '10px' }}>{airport.airport_name} • {airport.iata_code}</span>
                                                                <div style={{ color: '#636363', fontSize: '12px' }}>Approximately {airport.distance} miles away</div>
                                                            </div>
                                                            <DefaultButton
                                                                buttonLabel="Select This Airport"
                                                                customButtonClass="inverted_secondary_button_styling"
                                                                buttonStyle={{ fontSize: '13px', height: '30px', padding: '0 10px' }}
                                                                onClick={() => onSelectAirportClick(airport)}
                                                            />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            : null}
                                    </div> : null}
                                <div>
                                    {
                                        step == "search" &&
                                        <div className='hotel_flight_cards_container'>
                                            {Object.entries(flightList).map((flights, index) => {
                                                return (
                                                    flights[1].length ?
                                                        <div key={index}>
                                                            <div className='flight_hotel_category_title'>{flights[0]}</div>
                                                            <div style={{ fontWeight: '300', color: '#636363', fontSize: '16px', marginBottom: '12px' }}>{FLIGHT_SUBTEXT[flights[0]]}</div>
                                                            <div className='flight_hotel_category_wrapper'>
                                                                {flights[1].map((flight, i) => {
                                                                    return (
                                                                        <FlightCard
                                                                            key={i}
                                                                            flight={flight}
                                                                            setFlightDetails={setFlightDetails}
                                                                            onViewDetails={onViewDetails}
                                                                        />
                                                                    )
                                                                })}
                                                            </div>
                                                        </div> : null
                                                )

                                            })}
                                        </div>
                                    }
                                    {
                                        (step == "form" && Object.keys(flightDetails).length > 0) &&
                                        <div className='flight_details_container'>
                                            <div>
                                                <div className='flight_journey_card_container'>
                                                    <FlightJourneyCard setFlightDetails={setFlightDetails} flight={flightDetails} />
                                                </div>
                                                <div style={{ border: '1px solid #D9D9D9', padding: '12px', marginTop: '10px', textAlign: 'end', borderRadius: '8px', fontSize: '28px', fontWeight: '800' }}>
                                                    Total: ${wpRecommendedAmount.wp_total_amount}
                                                </div>
                                            </div>
                                            <div className='checkout_card_wrapper'>
                                                <div>
                                                    <FlightPassengerForm passengers={flightDetails.passengers} setPassengerDetails={setPassengerDetails} />
                                                    <DuffelAncillaries
                                                        debug={false}
                                                        offer_id={flightDetails.offer_id}
                                                        client_key={flightDetails.client_key}
                                                        services={["bags", "seats", "cancel_for_any_reason"]}
                                                        passengers={flightDetails.passengers}
                                                        onPayloadReady={(data, metadata) => {
                                                            setFlightServices(metadata)
                                                        }}
                                                    />
                                                </div>
                                                <div className='row justify-content-end my-4 mx-1'>
                                                    <DefaultButton
                                                        buttonLabel={!processing ? "Checkout" : "Processing..."}
                                                        customButtonClass="secondary_button_styling"
                                                        disabled={isBtnDisabled()}
                                                        onClick={handleCheckout}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        (step == "checkout" && Object.keys(flightDetails).length > 0) &&
                                        <div className='flight_details_container'>
                                            <div className='flight_journey_card_container'>
                                                <FlightJourneyCard setFlightDetails={setFlightDetails} flight={flightDetails} />
                                            </div>
                                            <div className='checkout_card_wrapper'>
                                                <div className='mb-4'>
                                                    <p className="fs-3 fw-bolder text-start mb-2">Checkout</p>
                                                    <div className='bill'>
                                                        <div className='billing_items mx-4'>
                                                            <div className='d-flex justify-content-between mb-2'>
                                                                <p>Flight Charge</p>
                                                                <p>${wpRecommendedAmount.base_amount}</p>
                                                            </div>
                                                            {
                                                                wpRecommendedAmount.baggage_amount > 0 &&
                                                                <div className='d-flex justify-content-between mb-2'>
                                                                    <p>Baggage</p>
                                                                    <p>${wpRecommendedAmount.baggage_amount}</p>
                                                                </div>
                                                            }
                                                            {
                                                                wpRecommendedAmount.seats_amount > 0 &&
                                                                <div className='d-flex justify-content-between mb-2'>
                                                                    <p>Seats</p>
                                                                    <p>${wpRecommendedAmount.seats_amount}</p>
                                                                </div>
                                                            }
                                                            {
                                                                wpRecommendedAmount.CFAR_amount > 0 &&
                                                                <div className='d-flex justify-content-between mb-2'>
                                                                    <p>Cancellation Charges</p>
                                                                    <p>${wpRecommendedAmount.seats_amount}</p>
                                                                </div>
                                                            }
                                                            <div className='d-flex justify-content-between mb-2'>
                                                                <p>Fees</p>
                                                                <p>${wpRecommendedAmount.wp_fee}</p>
                                                            </div>
                                                            <div className='d-flex justify-content-between mb-2'>
                                                                <p>Taxes</p>
                                                                <p>${wpRecommendedAmount.wp_tax}</p>
                                                            </div>
                                                            <hr />
                                                            <div className='col d-flex justify-content-between mb-2 text-primary'>
                                                                <p className='fw-bold'>Total</p>
                                                                <p className='fw-bold'>${wpRecommendedAmount.wp_total_amount}</p>
                                                            </div>
                                                        </div>
                                                        {
                                                            <div style={{ position: 'relative' }}>
                                                                {paymentInProgress && (
                                                                    <div
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: 0,
                                                                            left: 0,
                                                                            width: '100%',
                                                                            height: '100%',
                                                                            backgroundColor: 'rgb(186 184 184 / 50%)', // Semi-transparent black overlay
                                                                            zIndex: 9999, // Ensure the overlay is on top of other content
                                                                            borderRadius: '8px'
                                                                        }}
                                                                    />
                                                                )}
                                                                <div style={paymentInProgress ? { opacity: '0.8' } : {}}>
                                                                    <div>
                                                                        <p className='fs-3 fw-bolder text-start'>Billing Information</p>
                                                                    </div>
                                                                    <div className='d-flex justify-content-center align-items-center'>
                                                                        {
                                                                            clientToken &&
                                                                            <DuffelPayments
                                                                                paymentIntentClientToken={clientToken}
                                                                                onSuccessfulPayment={() => {
                                                                                    handlePaymentSuccess()
                                                                                }}
                                                                                onFailedPayment={console.log}
                                                                            />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            paymentInProgress && (
                                                                <div className='alert_modal'>
                                                                    <div className=''>
                                                                        <Lottie animationData={alertAnimation} loop={true} className='alert_animation' />
                                                                        <div className='alert_message'>{alertMessage}</div>
                                                                    </div>
                                                                    {
                                                                        paymentComplete &&
                                                                        <DefaultButton
                                                                            buttonLabel={"Go to Itinerary"}
                                                                            customButtonClass="inverted_secondary_button_styling"
                                                                            onClick={() => { navigate(`/trip/${tripDetails.trip_id}`); sendGACustomEvent({ category: 'Button Click', action: `navigates user to itinerary page after flight booking`, label: 'On Go To Itinerary button click' }) }}
                                                                        />
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    }
                                </div>
                            </div>
                    }
                </div>
            }
            <CustomModal
                title={"Booking Details"}
                showModal={showModal}
                setShowModal={setShowModal}
                onHide={() => setShowModal(false)}
                modalFooter={renderFooter(flightDetails)}
                centered={true}>
                <FlightJourneyCard setFlightDetails={setFlightDetails} flight={flightDetails} />
            </CustomModal>
        </div>
    )
}

export default Flight
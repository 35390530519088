import { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHourglassStart, faHourglassHalf, faHourglassEnd } from '@fortawesome/free-solid-svg-icons'
import './eventCard.scss'
import DefaultButton from '../DefaultButton/defaultButton'
import ActivityBookingCard from '../ActivityBookingCard/activityBookingCard'
import { Context } from '../../context/context'
import CustomModal from '../CustomModal/customModal'
import { sendGACustomEvent } from '../../utils/helpers'

function EventCard({ event, id }) {
    const { state, setState } = useContext(Context)
    const [formattedTags, setFormattedTags] = useState([])
    const [borderStyle, setBorderStyle] = useState('')
    const [rating, setRating] = useState('')
    const [attractionStatus, setAttractionStatus] = useState('')
    const [standardTime, setStandardTime] = useState('')
    const [eventDuration, setEventDuration] = useState('')
    const [showModal, setShowModal] = useState(false)
    const dollarIcon = '\u{1F4B2}';

    useEffect(() => {
        if (Array.isArray(event.available_tours) && event.available_tours.length) {
            const uniqueToursObj = {}
            event.available_tours.forEach(tour => {
                if (!uniqueToursObj[tour.product_code]) {
                    uniqueToursObj[tour.product_code] = tour
                }
            })
            const uniqueToursArray = Object.values(uniqueToursObj)
            event.available_tours = [...uniqueToursArray]
        }

        if (event.product_tags.length) {
            let eventName = event.title.toLowerCase()
            let tags = event.product_tags.filter(m => m !== event.category && m !== eventName && m !== "").slice(0, 5)
            setFormattedTags(tags)
        }
        // calculate rank:
        setRating(Math.ceil(event.rating))

        setAttractionStatus(getAttractionStatus())

        setStandardTime(formatTime(event.start_time))

        setEventDuration(formatDuration(event.duration))

        // set border color as the page loads:
        setBorderStyle(() => {
            return event.customized ? '2px solid #FD905E' : ''
        })
        // clear the color:
        const timeout = setTimeout(() => {
            setBorderStyle('')
        }, 10000)
        return () => clearTimeout(timeout)
    }, [])

    const formatToUppercase = (word) => {
        if (word) return word.charAt(0).toUpperCase() + word.slice(1)
    }

    const formatTime = (militaryTime) => {
        // Parse military time
        var militaryHour = parseInt(militaryTime.substring(0, 2));
        var militaryMinute = militaryTime.substring(3);

        // Convert to standard time
        var standardHour = (militaryHour % 12) || 12;
        var ampm = militaryHour < 12 ? "AM" : "PM";

        // Format minutes with leading zero if necessary
        var standardMinute = militaryMinute.length === 1 ? "0" + militaryMinute : militaryMinute;

        // Construct standard time string
        var standardTime = standardHour + ":" + standardMinute + " " + ampm;

        return standardTime;
    }

    const formatDuration = (duration) => {
        if (duration !== null) {
            if (duration < 60) {
                return `${duration} minutes`
            } else {
                let d = Math.ceil(duration / 60)
                return (d < 2) ? `${d} hour` : `${d} hours`
            }
        }
    }

    const getIconByTimeOfDay = (time) => {
        if (time === 'morning') {
            return faHourglassStart
        } else if (time === 'afternoon') {
            return faHourglassHalf
        } else {
            return faHourglassEnd
        }
    }

    const displayRating = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ fontSize: '18px', fontWeight: '700', color: '#9D9696' }}>⭐ {rating}</div>
            </div>
        )
    }

    const getAttractionStatus = () => {
        if (event.category === 'attraction') {
            if (rating > 2) return 'Top Attraction'
        } else if (event.category === 'restaurant') {
            if (rating > 7) return 'Highly Reccommend'
        }
    }

    return (
        <div className='eventCardWrapper' style={{ border: borderStyle }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px', width: '100%' }}>
                <div className='row'>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FontAwesomeIcon icon={getIconByTimeOfDay(event.time_of_day)} style={{ marginRight: '5px', fontSize: '14px', color: '#035EDD' }} />
                        <div className='timeOfDay'>{formatToUppercase(event.time_of_day)}</div>
                    </div>
                </div>
                <div style={{ margin: '0 5px 0 auto' }}>
                    {attractionStatus &&
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FontAwesomeIcon icon="fa-solid fa-fire" style={{ fontSize: '14px', color: '#7C3BBC', marginRight: '5px' }} />
                            <div style={{ fontWeight: 700, fontSize: '14px', color: '#7C3BBC' }}>{attractionStatus}</div>
                        </div>}
                </div>
                <div className='tour_id'>{id}</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div className='cardTitle'>
                    <a style={{ color: '#000000' }} href={`https://google.com/maps/search/${event.title}, ${state.tripDetails.destination_name}`} target='_blank'>
                        {event.title}
                    </a>
                </div>
                {rating && rating !== '0' ? displayRating() : null}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div className='cardSubtext'>{formatToUppercase(event.category)}</div>
                <div className='cardSubtext'>{event.budget ? (dollarIcon).repeat(event.budget) : ''}</div>
            </div>
            <div className='cardDescription'>{event.description}</div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <FontAwesomeIcon icon="fa-solid fa-clock" style={{ fontSize: '14px', color: '#035eed', marginRight: '5px' }} />
                <div style={{ fontWeight: '700', fontSize: '12px', margin: '0 5px', color: '#9D9696' }}>Start Time:</div>
                <div style={{ fontSize: '12px' }}>{standardTime}</div>
                <div style={{ margin: '0px 10px', height: '18px', width: ' 1.3px', backgroundColor: 'rgb(0, 0, 0)' }} />
                {eventDuration ? <div style={{ fontWeight: '700', fontSize: '12px', color: '#9D9696' }}>Duration:</div> : null}
                {eventDuration ? <div style={{ fontSize: '12px', margin: '0 5px' }}>{eventDuration}</div> : null}
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {formattedTags.map((tag, i) => {
                    return <div key={i} className='tagCell'>{tag}</div>
                })}
            </div>
            <div className='booking_btn_wrapper'>
                {Array.isArray(event.available_tours) && event.available_tours.length ? <DefaultButton buttonLabel="Book Tickets" buttonStyle={{ width: '80%' }} onClick={() => { setShowModal(true); sendGACustomEvent({ category: 'Button Click', action: 'Opens Book Tickets Modal for activity', label: 'Book Tickets button on event card' }) }} /> :
                    <div style={{ fontWeight: '600', fontSize: '12px', textAlign: 'center' }}>No booking options available at this time</div>}
            </div>
            <CustomModal
                title={"View Activity Options"}
                showModal={showModal}
                setShowModal={setShowModal}
                centered={true}
                onHide={() => setShowModal(false)}
            >
                <div style={{ borderBottom: '1px solid #eeeeee', padding: '12px 0 12px 32px' }}>
                    <div className='modalTitle' style={{ marginBottom: '8px' }}>{event.title}</div>
                    <div className='cardSubtext' style={{ marginBottom: '8px' }}>{formatToUppercase(event.category)}</div>
                    {rating && rating !== '0' ? displayRating() : null}
                    <div className='cardSubtext' style={{ marginBottom: '8px' }}>{event.budget ? (dollarIcon).repeat(event.budget) : ''}</div>
                    <div className='cardDescription' style={{ marginBottom: '8px' }}>{event.description}</div>
                </div>
                <div className='modalToursWrapper'>
                    {Array.isArray(event.available_tours) && event.available_tours.map((activity, index) => {
                        return (
                            <ActivityBookingCard key={index} activity={activity} />
                        )
                    })}
                </div>
            </CustomModal>
        </div>
    )
}

export default EventCard
import { useState } from "react";
import CustomModal from '../../components/CustomModal/customModal'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DefaultButton from "../DefaultButton/defaultButton";
import InputField from "../InputField/inputField";
import newsletterGraphic from "../../assets/newsletter.png";
import './newsletterBanner.scss';
import { newsletterSignUp } from "../../context/api";
import { sendGACustomEvent } from "../../utils/helpers";

function NewsletterBanner() {
    const [showModal, setShowModal] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [mode, setMode] = useState('signup');

    const onNewsletterSignUp = () => {
        sendGACustomEvent({ category: 'Button Click', action: `Signs user up for newsletter`, label: 'Click on newsletter sign up button' })
        if (validateEmail(userEmail)) {
            setEmailError(false)
            newsletterSignUp({ email: userEmail })
                .then((res) => {
                    setMode('registered')
                })
        } else {
            setEmailError(true)
        }
    }

    function validateEmail() {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        return regex.test(userEmail)
    }

    const renderSignUpSlide = () => {
        return (
            <div className="newsletter_wrapper">
                <img src={newsletterGraphic} width="100px" />
                <div style={{ fontWeight: '800', fontSize: '28px', fontWeight: '500', color: '#035EDD' }}>Be the first to know</div>
                <div style={{ textAlign: 'center' }}>Sign up to our newsletter to be the first to know about new features, great offers and more</div>
                <InputField
                    onChange={(e) => { setUserEmail(e.target.value) }}
                    placeholder="Enter email address"
                    value={userEmail}
                    type="email"
                    inputStyling={{ width: '100%' }}
                    isError={emailError}
                    showErrorText="Enter a valid email"
                />
                <DefaultButton
                    buttonLabel="Sign Up"
                    buttonStyle={{ width: '100%' }}
                    onClick={() => onNewsletterSignUp()}
                />
            </div>
        )
    }

    const renderRegisterdSlide = () => {
        return (
            <div className="newsletter_wrapper">
                <img src={newsletterGraphic} width="100px" />
                <div style={{ fontWeight: '800', fontSize: '28px', fontWeight: '500', color: '#035EDD' }}>You're all set!</div>
                <div style={{ textAlign: 'center', color: '#636363', fontWeight: '600' }}>Thank you for signing up</div>
            </div>
        )
    }

    return (
        <div className='home_newsletter_banner'>
            <FontAwesomeIcon icon="fa-regular fa-newspaper" style={{ fontSize: '40px', color: '#78cfcf' }} />
            <div className='newsletter_banner_body'>
                <div style={{ fontWeight: '800', color: '#FD905E', fontSize: '22px' }}>Stay up to date</div>
                <div style={{ color: '#636363', fontWeight: '600' }}>Don't worry, we'll only bug you the important stuff. Sign up for our newsletter today!</div>
            </div>
            <DefaultButton
                buttonLabel="Sign me up"
                buttonStyle={{ marginLeft: 'auto' }}
                onClick={() => { setShowModal(true); sendGACustomEvent({ category: 'Button Click', action: `Opens Newsletter modal`, label: 'Sign me up button click' }) }}
            />
            <CustomModal
                title={"Join our newsletter"}
                showModal={showModal}
                setShowModal={setShowModal}
                centered={true}
                onHide={() => setMode("signup")}
            >
                {mode === "signup" ? renderSignUpSlide() : renderRegisterdSlide()}
            </CustomModal>
        </div>
    )
}

export default NewsletterBanner;
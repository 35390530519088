
import './searchAutoComplete.scss'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

function SearchAutoComplete({ onSelect, onClear, placeholder, maxResults, autoFocus, items, usage }) {
    const formatResult = (item) => {
        if (usage === 'trip_destination') {
            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ fontWeight: '500', fontSize: '14px' }}>{item.name}</div>
                    <div style={{ fontSize: '12px', color: '#636363' }}>{item.parent_name}</div>
                </div>
            )
        } else if (usage === 'international_destination') {
            return (
                <div style={{ fontWeight: '500', fontSize: '14px' }}>{item.name}</div>
            )
        } else {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', width: '50px' }}>
                    <div style={{ fontWeight: '600', fontSize: '14px' }}>{item.iata_code}</div>
                    <div style={{ fontSize: '12px', color: '#636363' }}>{item.airport_name}</div>
                </div>
            )
        }
    }

    const options = {
        includeScore: true,
        keys: ['nearby_cities', 'airport_name', 'iata_code', 'name']
    }
    return (
        <div className='search_autocomplete_wrapper'>
            <ReactSearchAutocomplete
                placeholder={placeholder}
                items={items}
                resultStringKeyName={usage === 'origin_search' ? 'airport_name' : 'name'}
                fuseOptions={options}
                onSelect={onSelect}
                autoFocus={autoFocus}
                formatResult={formatResult}
                maxResults={maxResults}
                onClear={onClear}
                className='custom_autocomplete_styling'
            />
        </div>
    )
}

export default SearchAutoComplete;
import React, { useContext, useEffect, useState } from 'react'
import { Stepper } from 'react-form-stepper'
import { useParams } from 'react-router-dom'
import HotelCard from '../../components/HotelCard/hotelCard'
import HotelCheckoutDetails from '../../components/HotelCheckoutDetails/hotelCheckoutDetails'
import HotelDetailedOverview from '../../components/HotelDetailedOverview/hotelDetailedOverview'
import NavBar from '../../components/Navbar/navbar'
import { fetchAllHotelOffers, fetchHotelById, getSessionData, updateSessionData } from '../../context/api'
import { Context } from '../../context/context'
import '../../css/common.scss'
import Loading from '../Loading/loading'
import './hotel.scss'
import Footer from '../../components/Footer/footer'
import { sendGAPageEvent } from '../../utils/helpers'
import NoResults from '../NoResults/noResults'

const hotelStepName = [
    {
        id: 0,
        label: "Search Hotels",
        title: "Explore Hotel",
        step: "search",
    },
    {
        id: 1,
        label: "Select Rooms",
        title: "Select Rooms",
        step: "details",
    },
    {
        id: 2,
        label: "Customer Details",
        title: "Customer Details",
        step: "customer",
    },
    {
        id: 3,
        label: "Checkout",
        title: "Book Hotel",
        step: "checkout",
    },
]

function Hotel() {
    const { state, setState } = useContext(Context)
    const [loading, setLoading] = useState(false)
    const [fetching, setFetching] = useState(false)
    const [view, setView] = useState('hotels')
    const [hotelList, setHotelList] = useState([])
    const [tripDetails, setTripDetails] = useState(state.tripDetails)
    const [hotelDetails, setHotelDetails] = useState({})
    const [availableRooms, setAvailableRooms] = useState([])
    const [rateId, setRateId] = useState()
    const { step } = useParams()

    useEffect(() => {
        const deets = JSON.parse(sessionStorage.getItem('tripDetails'))
        if (step == 'details') {
            if (hotelDetails.id) {
                fetchHotelById({
                    search_result_id: hotelDetails.id
                }).then((searchRes) => {
                    setHotelDetails(searchRes)
                    setAvailableRooms(searchRes.room_details)
                    setFetching(false)
                })
            }
            if (Object.keys(hotelDetails).length == 0) {
                getSessionData({ trip_id: deets.trip_id })
                    .then((res) => {
                        if (res.hotel_details) {
                            setHotelDetails(res.hotel_details)
                            fetchHotelById({
                                search_result_id: res.hotel_details.id
                            }).then((searchRes) => {
                                setHotelDetails(searchRes)
                                setAvailableRooms(searchRes.room_details)
                                setFetching(false)
                            })
                        }
                    })
            }
        }
        if (step == 'customer' || step == 'checkout') {
            if (Object.keys(hotelDetails).length == 0) {
                getSessionData({ trip_id: deets.trip_id })
                    .then((res) => {
                        if (res.hotel_details) {
                            setRateId(res.hotel_details.room_details[0].rates[0].id)
                            setHotelDetails(res.hotel_details)
                        }
                    })
            }
        }
    }, [step])

    useEffect(() => {
        sendGAPageEvent({ hitType: 'pageview', page: `/hotel?step=${step}`, title: `Landing on hotel ${step} page` })
        setFetching(true)
        // if there aren't any trip details then fetch them and reshow itinerary
        if (!Object.keys(tripDetails).length) {
            let deets = JSON.parse(sessionStorage.getItem('tripDetails'))
            setTripDetails(deets)
        }
        if (Object.keys(tripDetails).length) {
            let hotelPayload = {
                "rooms": tripDetails.rooms.toString(),
                "location": {
                    "geographic_coordinates": {
                        "longitude": tripDetails.longitude,
                        "latitude": tripDetails.latitude,
                    }
                },
                "check_out_date": tripDetails.end_date,
                "check_in_date": tripDetails.start_date,
                "adults": tripDetails.adult.toString()
            }
            fetchAllHotelOffers(hotelPayload).then((res) => {
                setHotelList(res)
                setFetching(false)
            })
        }
    }, [tripDetails])

    useEffect(() => {
        const deets = JSON.parse(sessionStorage.getItem('tripDetails'))
        if (Object.keys(hotelDetails).length > 0) {
            updateSessionData({ trip_id: deets.trip_id, session_data: { hotel_details: hotelDetails } })
                .then((res) => {
                    if (res.length > 0) res.hotel_details && setHotelDetails(res.hotel_details)
                })
        }
    }, [hotelDetails])

    return (
        <div className='hotel_wrapper'>
            <NavBar getLoadingState={val => setLoading(val)} />
            {(Object.keys(tripDetails).length) &&
                <div className='hotel_flight_selection_wrapper'>
                    {
                        fetching ?
                            <Loading /> :
                            Object.entries(hotelList).length === 0 ?
                                <NoResults /> :
                                <div>
                                    <Stepper
                                        styleConfig={{
                                            activeBgColor: '#FD905E',
                                            completedBgColor: '#FD905E'
                                        }}
                                        connectorStyleConfig={{
                                            activeColor: '#FD905E',
                                            completedColor: '#FD905E'
                                        }}
                                        steps={hotelStepName.map(step => ({ label: step.label }))}
                                        activeStep={hotelStepName.find(item => item.step === step).id}
                                    />
                                    <div className='hotel_flight_page_title'>{hotelStepName.find(item => item.step === step).title}</div>
                                    <div>
                                        {
                                            step == "search" &&
                                            <div className='hotel_flight_cards_container'>
                                                {Object.entries(hotelList).map((hotel, index) => {
                                                    return (
                                                        hotel[1].length ?
                                                            <div key={index}>
                                                                <div className='flight_hotel_category_title'>{hotel[0]}</div>
                                                                <div className='flight_hotel_category_wrapper'>
                                                                    {hotel[1].map((hotel, i) => {
                                                                        return (
                                                                            <HotelCard
                                                                                key={i}
                                                                                hotel={hotel}
                                                                                setView={setView}
                                                                                setHotelDetails={setHotelDetails}
                                                                                setRateId={setRateId}
                                                                                setFetching={setFetching}
                                                                            />
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div> : null
                                                    )

                                                })}
                                            </div>
                                        }
                                        {
                                            (step == "details" && Object.keys(hotelDetails).length > 0) &&
                                            <div className=''>
                                                {
                                                    hotelDetails &&
                                                    <div className=''>
                                                        <HotelDetailedOverview
                                                            hotel={hotelDetails}
                                                            availableRooms={availableRooms}
                                                            setRateId={setRateId}
                                                            setHotelDetails={setHotelDetails}
                                                            setView={setView}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {
                                            ((step == 'checkout' || step == 'customer') && Object.keys(hotelDetails).length > 0 && rateId !== '') &&
                                            <HotelCheckoutDetails
                                                hotelDetails={hotelDetails}
                                                rateId={rateId}
                                            />
                                        }
                                    </div>
                                </div>
                    }
                </div>
            }
            <Footer />
        </div>
    )
}

export default Hotel

import './howItWorks.scss'
import firstImg from '../../assets/howitworks1.png'
import secondImg from '../../assets/howitworks2.png'
import thirdImg from '../../assets/howitworks3.png'
import fourthImg from '../../assets/howitworks4.png'
import fifthImg from '../../assets/howitworks5.png'

function HowItWorks() {
    const summary = [
        {
            src: firstImg,
            tagLine: 'Select a destination'
        },
        {
            src: fifthImg,
            tagLine: 'Book your flights'

        },
        {
            src: fourthImg,
            tagLine: 'Book your stay'
        },
        {
            src: secondImg,
            tagLine: 'Review your curated trip'
        },
        {
            src: thirdImg,
            tagLine: 'Customize with AI'

        }
    ]

    return (
        <div className='how_it_works_wrapper'>
            <div className='how_it_works_title'>Getting Started</div>
            <div className='summary_wrapper'>
                {summary.map((item, index) => {
                    return (
                        <div key={index} className='summary_cell'>
                            <img src={item.src} style={{ width: '70px' }} />
                            <div className='tag_line'>{item.tagLine}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default HowItWorks
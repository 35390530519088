import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import defaultImage from '../../assets/default_placeholder.png'
import './blogCard.scss'
import { truncateDescription } from '../../utils/helpers'

function BlogCard({ post }) {
    return (
        <div className="blog_card">
            <div className="row no-gutters">
                <div className="col-sm-12 col-md-3 col-lg-4">
                    {/* If no image is present use generic image */}
                    <img src={post.thumbnail_image ? post.thumbnail_image : defaultImage} className="blog_img" alt={post.title} />
                </div>
                <div className="col-sm-12 col-md-7 col-lg-6">
                    <div className="blog_card_body">
                        <Link to={`/blogs/${post.title_id}`} className='text-decoration-none' style={{ color: '#000' }}>
                            <h5 className="blog_title">{post.title}</h5>
                        </Link>
                        <p className="card-text"><small className="text-muted">{post.date}</small></p>
                        <p className="card-text">{truncateDescription(post.description, 230)}</p>
                    </div>
                </div>
                <div className="col-sm-12 col-md-2 pl-0 d-flex justify-content-end">
                    <div className="row mt-auto">
                        <div className="col pl-0 blog_read_more">
                            <Link to={`/blogs/${post.title_id}`} className='text-decoration-none'>
                                <p>Read More <FontAwesomeIcon icon="fa-solid fa-arrow-right-long" /></p>
                            </Link>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default BlogCard
import { useEffect, useState } from "react"
import DatePicker from 'react-datepicker'

function DateRangePicker({ getDateRangeValues, surpriseTripPayload }) {
    const [dateRanges, setDateRanges] = useState({
        firstDateRange: [],
        secondDateRange: [],
        thirdDateRange: [],
    })

    const { firstDateRange, secondDateRange, thirdDateRange } = dateRanges

    const [firstStartDate, firstEndDate] = firstDateRange
    const [secondStartDate, secondEndDate] = secondDateRange
    const [thirdStartDate, thirdEndDate] = thirdDateRange

    const updateDateRange = (rangeName, newRange) => {
        setDateRanges(prevRanges => ({
            ...prevRanges,
            [rangeName]: newRange,
        }))
        getDateRangeValues(rangeName, newRange)
    }

    // for then a user goes back in the flow, pre-populate data
    useEffect(() => {
        if (surpriseTripPayload.firstDateRange) {
            updateDateRange('firstDateRange', surpriseTripPayload.firstDateRange)
        }
        if (surpriseTripPayload.secondDateRange) {
            updateDateRange('secondDateRange', surpriseTripPayload.secondDateRange)
        }
        if (surpriseTripPayload.thirdDateRange) {
            updateDateRange('thirdDateRange', surpriseTripPayload.thirdDateRange)
        }
    }, [])

    return (
        <div className="surprise_row">
            <div className="date_range_picker_wrapper">
                <DatePicker
                    showIcon
                    selectsRange={true}
                    startDate={firstStartDate}
                    minDate={new Date()}
                    showDisabledMonthNavigation
                    popperPlacement='top'
                    placeholderText='Select your dates'
                    endDate={firstEndDate}
                    onChange={(update) => {
                        updateDateRange('firstDateRange', update)
                    }}
                    isClearable
                    className='date_range_picker'
                />
            </div>
            <div className="date_range_picker_wrapper">
                <DatePicker
                    showIcon
                    selectsRange={true}
                    startDate={secondStartDate}
                    minDate={new Date()}
                    showDisabledMonthNavigation
                    popperPlacement='top'
                    placeholderText='Select your dates'
                    endDate={secondEndDate}
                    onChange={(update) => {
                        updateDateRange('secondDateRange', update)
                    }}
                    isClearable
                    className='date_range_picker'
                />
            </div>
            <div className="date_range_picker_wrapper">
                <DatePicker
                    showIcon
                    selectsRange={true}
                    startDate={thirdStartDate}
                    minDate={new Date()}
                    showDisabledMonthNavigation
                    popperPlacement='top'
                    placeholderText='Select your dates'
                    endDate={thirdEndDate}
                    onChange={(update) => {
                        updateDateRange('thirdDateRange', update)
                    }}
                    isClearable
                    className='date_range_picker'
                />
            </div>
        </div>
    )
}

export default DateRangePicker;
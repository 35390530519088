import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './customModal.scss';

function CustomModal({ children, title, showModal, setShowModal, onHide, centered, size = "l", modalFooter }) {
    return (
        <Modal
            show={showModal}
            onHide={() => { setShowModal(false); onHide() }}
            centered={centered}
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal_body'>
                {children}
            </Modal.Body>
            {modalFooter ?
                <Modal.Footer>
                    {modalFooter}
                </Modal.Footer>
                : null}
        </Modal>
    )
}

export default CustomModal
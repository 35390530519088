import React from 'react'
import './hotelPolicies.scss'

function HotelPolicies({ policies }) {
    return (
        <div>
            <div className='room_policies'>
                {
                    policies.cancellation_timeline.length > 0 &&
                    <div>
                        <div className='room_policy_title'>Cancellation Timeline</div>
                        <ul className="timeline">
                            {policies.cancellation_timeline.map((timelineItem, index) => (
                                <li key={index}>
                                    <p className="timeline-date">{new Date(timelineItem.before).toLocaleDateString()}</p>
                                    <div className="timeline-content">
                                        <div className='refund_amount'>
                                            <strong>Refundable Amount : </strong>{timelineItem.refund_amount} {timelineItem.currency}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                }
                <div className='room_conditions'>
                    <div className='room_policy_title'>Conditions</div>
                    {
                        policies.conditions.map((condition) => (
                            <div className='condition_items'>
                                <p className='condition_title'>{condition.title}</p>
                                <p className='condition_description'>{condition.description}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default HotelPolicies
import { useEffect, useState } from "react"
import InputField from "../../components/InputField/inputField"
import PersonCountDropdown from "../../components/PersonCountDropdown/personCountDropdown"
import SearchAutoComplete from "../../components/SearchAutoComplete/searchAutoComplete"
import DateRangePicker from "../SurpriseTrips/dateRangePicker"
import { createManagedTripRequest, fetchCityAndAirports } from "../../context/api"
import { useNavigate, useSearchParams } from "react-router-dom"
import { PREFERENCE_TAGS } from "../../constants/constants"
import './internationalGuidedTours.scss'
import DefaultButton from "../../components/DefaultButton/defaultButton"
import countries from '../../constants/countries.json'
import CustomModal from "../../components/CustomModal/customModal"
import { PropagateLoader } from "react-spinners"
import { emailRegex } from '../../utils/helpers'

const classNames = require('classnames')

function GuidedToursForm() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [internationalTripPayload, setInternationalTripPayload] = useState({})
    const [citiesAndAirports, setCitiesAndAirports] = useState([])
    const step = searchParams.get('step') || 'info'
    const [selectedTags, setSelectedTags] = useState([])
    const [nextButtonDisabled, setNextButtonDisabled] = useState(false)
    const [showSubmittedModal, setShowSubmittedModal] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)

    const navigate = useNavigate();

    const onPayloadInput = (name, value) => {
        setInternationalTripPayload(prevPayload => ({
            ...prevPayload,
            [name]: value
        }))
    }

    const handleOnSelectOrigin = (item) => {
        onPayloadInput('iata_code', item.iata_code)
        onPayloadInput('originName', item.airport_name)
        return `${item.airport_name}`
    }

    const handleOnDestination = (item) => {
        onPayloadInput('destination_country', item.name)
        return `${item.name}`
    }

    const onPreferenceSelection = (tag) => {
        setSelectedTags(prevTags => {
            if (prevTags.includes(tag)) {
                return prevTags.filter(t => t !== tag);
            } else {
                return [...prevTags, tag];
            }
        });
    }

    const onSubmit = () => {
        setShowSubmittedModal(true)
        setSubmitLoading(true)
        let payload = { user_id: sessionStorage.getItem('user_id'), data: internationalTripPayload }
        createManagedTripRequest(payload)
            .then(() => {
                setSubmitLoading(false)
            })
    }

    useEffect(() => {
        if (step === 'form') {
            onPayloadInput('budget', Number('500').toFixed(2))
            fetchCityAndAirports()
                .then((res) => {
                    setCitiesAndAirports(res)
                })
        }

    }, [])

    useEffect(() => {
        setNextButtonDisabled(true)
        if ((internationalTripPayload.firstDateRange && internationalTripPayload.firstDateRange.length === 2)
            && internationalTripPayload.budget && internationalTripPayload.originName && internationalTripPayload.familyName
            && internationalTripPayload.givenName && internationalTripPayload.email && emailRegex.test(internationalTripPayload.email)) {
            setNextButtonDisabled(false)
        }
    }, [internationalTripPayload])

    return (
        <div>
            <div className="surprise_page_title" style={{ fontSize: '24px' }}>Let's plan your trip</div>
            <div className="surprise_page_subtitle" style={{ fontSize: '16px' }}>We just need a couple more details from you</div>
            <div className="surprise_content_container">
                <div className="input_section_title">Trip Details</div>
                <div className="guided_tours_row">
                    <SearchAutoComplete
                        placeholder='Your Destination'
                        items={countries}
                        onSelect={handleOnDestination}
                        autoFocus={true}
                        maxResults={5}
                        showSubtext={false}
                        usage='international_destination'
                        surpriseTripPayload={internationalTripPayload}
                    />
                    <SearchAutoComplete
                        placeholder='Your Origin'
                        items={citiesAndAirports}
                        onSelect={handleOnSelectOrigin}
                        autoFocus={true}
                        maxResults={5}
                        showSubtext={false}
                        usage='origin_search'
                        surpriseTripPayload={internationalTripPayload}
                    />
                    <div className="person_count_dropdown_wrapper">
                        <PersonCountDropdown getRoomPersonCountValues={onPayloadInput} surpriseTripPayload={internationalTripPayload} />
                    </div>
                </div>
                <div className="input_section_title">Give us up to 3 date ranges that best work for you</div>
                <DateRangePicker getDateRangeValues={onPayloadInput} surpriseTripPayload={internationalTripPayload} />
                <div className="input_section_title" style={{ margin: '32px 0 0 0 ' }}>Trip Budget</div>
                <div className="surprise_section_subtitle">Approximately how much would you like to spend on flights, hotels and activities?</div>
                <InputField
                    placeholder="Budget"
                    type="number"
                    value={internationalTripPayload.budget ? internationalTripPayload.budget : ''}
                    inputStyling={{ width: '15rem', marginTop: '18px' }}
                    onChange={(e) => onPayloadInput('budget', Number(e.target.value).toFixed(2))}
                />
            </div>
            <div className="input_section_title" style={{ margin: '32px 0 0 0 ' }}>User Details</div>
            <div className="surprise_section_subtitle">Please provide some personal details so our team can reach out to you.</div>
            <div className="guided_tours_row">
                <InputField
                    placeholder="First Name"
                    type="text"
                    value={internationalTripPayload.givenName ? internationalTripPayload.givenName : ''}
                    inputStyling={{ width: '15rem', marginTop: '18px' }}
                    onChange={(e) => onPayloadInput('givenName', e.target.value)}
                />
                <InputField
                    placeholder="Last Name"
                    type="text"
                    value={internationalTripPayload.familyName ? internationalTripPayload.familyName : ''}
                    inputStyling={{ width: '15rem', marginTop: '18px' }}
                    onChange={(e) => onPayloadInput('familyName', e.target.value)}
                />
                <InputField
                    placeholder="Email"
                    type="email"
                    value={internationalTripPayload.email ? internationalTripPayload.email : ''}
                    inputStyling={{ width: '15rem', marginTop: '18px' }}
                    onChange={(e) => onPayloadInput('email', e.target.value)}
                />
            </div>
            <div className="surprise_content_container">
                <div className="input_section_title">How would you like to spend your time?</div>
                <div className="surprise_tags_container">
                    {PREFERENCE_TAGS.map((tag, i) => {
                        return (
                            <div
                                className={classNames("surprise_tag", selectedTags.includes(tag) ? 'selected_tag' : '')}
                                onClick={() => onPreferenceSelection(tag)}
                                key={i}>
                                {tag}
                            </div>
                        )
                    })}
                </div>
                <div style={{ marginTop: '24px' }}>
                    <div className="surprise_section_subtitle">Anything else you would like our AI to know? (optional)</div>
                    <textarea
                        value={internationalTripPayload.preferences || ''}
                        style={{ resize: 'none', width: '100%', outline: 'none' }}
                        onChange={(e) => onPayloadInput('preferences', e.target.value)}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                <DefaultButton
                    buttonLabel="Submit"
                    customButtonClass="secondary_button_styling"
                    buttonStyle={{ width: '10rem', padding: '12px' }}
                    disabled={nextButtonDisabled}
                    onClick={onSubmit}
                />
            </div>
            <CustomModal showModal={showSubmittedModal} centered={true} setShowModal={setShowSubmittedModal} onHide={() => setShowSubmittedModal(false)}>
                <div className='guided_submit_modal'>
                    {submitLoading ? <PropagateLoader color="#FD905E" style={{ padding: '30px 0' }} /> :
                        <div className='guided_submit_modal'>
                            <div className='guided_submit_title'>Your request is successfully submitted!</div>
                            <div style={{ fontSize: '18px', fontWeight: '300', color: '#636363', marginBottom: '24px' }}>
                                You will be connected with one of our trusted partners shortly, who will be in touch with you to start planning your dream trip.
                            </div>
                            <DefaultButton buttonLabel="Done" onClick={() => { setShowSubmittedModal(false); navigate('/') }} buttonStyle={{ width: '10rem' }} />
                        </div>}
                </div>
            </CustomModal>
        </div>
    )
}

export default GuidedToursForm;
import { useNavigate } from 'react-router-dom'
import { formatDateAndTime, formatDuration, formatFinalPrice, formatStops, sendGACustomEvent } from '../../utils/helpers'
import DefaultButton from '../DefaultButton/defaultButton'
import './flightCard.scss'

function FlightCard({ flight, onViewDetails, setFlightDetails }) {
    const navigate = useNavigate()

    const renderFlightSegmentDetails = (flight) => {
        let firstOutboundFlight = flight.segments[0]
        let lastOutboundFlight = flight.segments[flight.segments.length - 1]
        return (
            <div className='flight_trip_details'>
                <div className='airline_details'>
                    <img src={firstOutboundFlight.marketing_carrier.logo_symbol_url} className='airline_logo' />
                    <div className='airline_name'>{firstOutboundFlight.marketing_carrier.name}</div>
                </div>
                <div className='airline_trip_details'>
                    <div className='boarding_details_container'>
                        <div className='airport_code'>{firstOutboundFlight.origin.iata_code}</div>
                        <div>{formatDateAndTime(firstOutboundFlight.departing_at, 'Time')}</div>
                        <div className='place_text'>
                            {firstOutboundFlight.origin.city_name}
                        </div>
                    </div>
                    <div className='airline_arrow_container'>
                        <div style={{ fontSize: '14px', fontWeight: '600', marginTop: '5px' }}>{formatDuration(flight.duration)}</div>
                        <div className="arrow_right"></div>
                        <div style={{ fontSize: '14px', fontWeight: '600', marginTop: '5px' }}>{formatStops(flight.segments.length - 1)}</div>
                    </div>
                    <div className='landing_details_container'>
                        <div className='airport_code'>{lastOutboundFlight.destination.iata_code}</div>
                        <div style={{ fontWeight: '500' }}>{formatDateAndTime(lastOutboundFlight.arriving_at, 'Time')}</div>
                        <div className='place_text'>
                            {lastOutboundFlight.destination.city_name}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='flight_card_wrapper'>
            <div className='flight_card_info_container'>
                {renderFlightSegmentDetails(flight.outbound)}
                {renderFlightSegmentDetails(flight.inbound)}
            </div>
            <div className='price_container'>
                <div className='price_conditions_wrapper'>
                    <div className='price_amount'>${formatFinalPrice(flight.wp_total_amount)}</div>
                    <div style={{ fontSize: '10px', marginBottom: '12px' }}> Includes taxes and fees</div>
                </div>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <DefaultButton
                        buttonLabel={"View Details"}
                        customButtonClass="inverted_secondary_button_styling"
                        buttonStyle={{ fontSize: '14px', marginRight: '12px', width: '100%' }}
                        onClick={() => { onViewDetails(flight); sendGACustomEvent({ category: 'Button Click', action: `Click on View Details on flight card`, label: 'Click on View Details on flight card' }) }}
                    />
                    <DefaultButton
                        buttonLabel={"Select"}
                        customButtonClass="secondary_button_styling"
                        buttonStyle={{ fontSize: '14px', width: '100%' }}
                        onClick={() => { navigate('/flights/form'); setFlightDetails(flight); sendGACustomEvent({ category: 'Button Click', action: `Click on Select button for flight`, label: 'Click on select button for flight' }) }}
                    />
                </div>
            </div>
        </div>
    )
}

export default FlightCard

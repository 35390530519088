import DefaultButton from "../../components/DefaultButton/defaultButton";
import cityScape from '../../assets/Cityscapes.png'
import { useNavigate } from "react-router-dom";

function NoResults() {
    const navigate = useNavigate()

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 32px', height: '83vh' }}>
            <img src={cityScape} style={{ width: '23rem', marginTop: '5rem' }} />
            <div style={{ fontSize: '2.0rem', fontWeight: '900', margin: '10px 10px 20px 10px' }}>No hotels to display for this destination</div>
            <DefaultButton buttonLabel="Go Back" buttonStyle={{ width: '10rem' }} onClick={() => navigate('/')} />
        </div>
    )
}

export default NoResults;
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Context from './context/context'
import RoutesProvider from './router/routesProvider'
import { GoogleOAuthProvider } from '@react-oauth/google'
import ReactGA from "react-ga4"

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUtensils, faListCheck, faWandMagicSparkles, faUmbrellaBeach, faPlaneArrival, faHandHoldingDollar, faMapLocationDot, faRoute, faFire, faGreaterThan, faLocationDot, faTimes, faClock, faStar, faStarHalf, faArrowRightLong, faArrowLeft, faBowlRice, faCalendarDays, faSuitcaseRolling, faPlus, faMinus, faSquareXmark, faCheckSquare, faBed, faParking, faDumbbell, faWifi, faDog, faTable, faEarDeaf, faBriefcase, faCircleInfo, faPhone, faMoon } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope, faUser, faNewspaper, faCalendar, faCircleCheck, faCircleXmark, faGem } from '@fortawesome/free-regular-svg-icons'

library.add(faUtensils, faListCheck, faUmbrellaBeach, faWandMagicSparkles, faPlaneArrival, faHandHoldingDollar, faMapLocationDot, faRoute, faFire, faGem, faGreaterThan, faLocationDot, faTimes, faClock, faStar, faStarHalf, faArrowRightLong, faArrowLeft, faBowlRice, faCalendarDays, faSuitcaseRolling, faUser, faPlus, faMinus, faSquareXmark, faCheckSquare, faBed, faParking, faDumbbell, faWifi, faDog, faTable, faEarDeaf, faBriefcase, faCircleInfo, faEnvelope, faPhone, faCircleCheck, faCircleXmark, faCalendar, faNewspaper, faMoon)

const GOOGLE_AUTH_CLIENT_ID = '877741821377-qsgdsecnoloe23f3arleh821kk43qakm.apps.googleusercontent.com';

ReactGA.initialize("G-ZDFGR5MHZ0")

function App() {
  return (
    <div className="App">
      <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_ID}>
        <Context>
          <RoutesProvider />
        </Context>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;

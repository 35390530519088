import './userDashboard.scss';

import { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import TripSearch from "../../components/TripSearch/tripSearch";
import UserTripCard from '../../components/UserTripCard/userTripCard';
import { createItinerary, fetchAllBookings, fetchItinerary, fetchPreviousTrips, fetchTripDetails, getBookedFlightData, getBookedHotelData } from "../../context/api";
import { Context } from '../../context/context';
import HeaderLayout from "../../layouts/headerLayout";
import { sendGACustomEvent, sendGAPageEvent } from '../../utils/helpers';
import Loading from "../Loading/loading";

function Home() {
    const [loading, setLoading] = useState(false)
    const [previousTrips, setPreviousTrips] = useState([])
    const [upcomingTrips, setUpcomingTrips] = useState([])
    const { state, setState } = useContext(Context)
    const [userFirstName, setUserFirstName] = useState('')
    const navigate = useNavigate()

    useEffect(() => {
        sendGAPageEvent({ hitType: 'pageview', page: '/my_trips', title: 'Landing on user dashboard' })
        setLoading(true)
        if (!sessionStorage.getItem('user_id')) {
            setLoading(false)
            navigate('/')
            return
        }
        const user = JSON.parse(sessionStorage.getItem('user'))
        setUserFirstName(user.given_name)
        const payload = { user_id: sessionStorage.getItem('user_id') }
        fetchPreviousTrips(payload)
            .then((res) => {
                let tripData = res.filter((trip) => (new Date(trip.start_date) > new Date()) && (trip.hotel_booking_id || trip.flight_booking_id))
                setUpcomingTrips(tripData)
                setPreviousTrips(res.filter((trip) => {
                    const currentDate = new Date();
                    const tripStartDate = new Date(trip.start_date);
                    return tripStartDate > currentDate && !trip.hotel_booking_id && !trip.flight_booking_id;
                }));
                setLoading(false)
            })
    }, [])

    const handleSearchClick = (tripDetailsPayload) => {
        setLoading(true)
        fetchTripDetails(tripDetailsPayload)
            .then(res => {
                // save trip details in session storage
                sessionStorage.setItem('tripDetails', JSON.stringify(res))
                setState(prevState => ({ ...prevState, tripDetails: res }))
                // if the itinerary is being created for the first time:
                if (!res.itinerary_id) {
                    let itineraryPayload = {
                        user_id: sessionStorage.getItem('user_id'),
                        trip_id: res.trip_id,
                        destination_id: tripDetailsPayload.destination_id,
                        start_date: tripDetailsPayload.start_date,
                        end_date: tripDetailsPayload.end_date
                    }
                    createItinerary(itineraryPayload)
                        .then(itRes => {
                            if (itRes) {
                                setState(prevState => ({ ...prevState, tripItinerary: itRes }))
                                navigate(`/trip/${res.trip_id}`)
                            }
                        })
                } else {
                    fetchItinerary({ trip_id: res.trip_id })
                        .then(itRes => {
                            if (itRes) {
                                setState(prevState => ({ ...prevState, tripItinerary: itRes }))
                                setLoading(false)
                                navigate(`/trip/${res.trip_id}`)
                            }
                        })
                }
            })
    }

    const handleOnCellClick = (trip) => {
        sendGACustomEvent({
            category: 'Button Click',
            action: 'Navigates user to precreated itinerary',
            label: 'Itinerary Cell Click'
        })

        setLoading(true)
        setState(prevState => ({
            ...prevState,
            flightDetails: {},
            hotelDetails: {},
        }))
        // save trip details in session storage
        sessionStorage.setItem('tripDetails', JSON.stringify(trip))
        setState(prevState => ({ ...prevState, tripDetails: trip }))
        fetchItinerary({ trip_id: trip.trip_id })
            .then(itRes => {
                if (itRes) {
                    setState(prevState => ({ ...prevState, tripItinerary: itRes }))
                    setLoading(false)
                    if (trip.flight_booking_id) {
                        getBookedFlightData({ id: trip.flight_booking_id })
                            .then((flightResponseData) => {
                                setState(prevState => ({
                                    ...prevState,
                                    flightDetails: flightResponseData,
                                }))
                            })
                    }
                    if (trip.hotel_booking_id) {
                        getBookedHotelData({ id: trip.hotel_booking_id, email: "", reference: "" })
                            .then((hotelResponseData) => {
                                setState(prevState => ({
                                    ...prevState,
                                    hotelDetails: hotelResponseData,
                                }))
                            })
                    }
                    navigate(`/trip/${trip.trip_id}`)
                }
            })
    }

    return (
        <HeaderLayout>
            {loading ? <Loading /> :
                <div className='user_dashboard_wrapper'>
                    <div className='home_banner'>
                        <div>Hey there,  {userFirstName} 👋</div>
                    </div>
                    <div className='dashboard_trip_search'>
                        <TripSearch topPlacement={'225px'} onSearch={handleSearchClick} usage='trip_destination' />
                    </div>
                    <div className='home_bottom'>
                        <div className="home_title">Your Trips</div>
                        {
                            upcomingTrips.length > 0 &&
                            <div className='trip_section'>
                                <div className='home_section_title'>Your Upcoming Trips</div>
                                <div className='trip_cards_container'>
                                    {upcomingTrips.map((trip, index) =>
                                        <div key={index}>
                                            <UserTripCard trip={trip} onClick={() => handleOnCellClick(trip)} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                        {
                            previousTrips.length > 0 &&
                            <div className='trip_section'>
                                <div className='home_section_title'>Pick up where you left off</div>
                                <div className='trip_cards_container'>
                                    {
                                        previousTrips.map((trip, index) => (
                                            <div key={index} onClick={() => handleOnCellClick(trip)} >
                                                <UserTripCard trip={trip} onClick={() => handleOnCellClick(trip)} />
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>}
        </HeaderLayout>
    )
}

export default Home
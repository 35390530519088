import 'react-datepicker/dist/react-datepicker.css';

import DefaultButton from '../DefaultButton/defaultButton'
import './tripSearch.scss'
import searchDestinations from '../../constants/searchDestinationsSimplified.json'
import { useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { generateRandomId, sendGACustomEvent } from '../../utils/helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchAutoComplete from '../SearchAutoComplete/searchAutoComplete';

function TripSearch({ onSearch, setLoading, topPlacement, usage }) {
  const [searchDestinationId, setSearchDestinationId] = useState('')
  const [adult, setAdult] = useState(1)
  const [child, setChild] = useState(0)
  const [rooms, setRooms] = useState(1)
  const [searchDestinationName, setSearchDestinationName] = useState('')
  const [searchDestinationIataCode, setSearchDestinationIataCode] = useState('')

  const [dateRange, setDateRange] = useState([])
  const [showPersonsDropdown, setShowPersonDropdown] = useState(false)
  const [startDate, endDate] = dateRange
  const [showRoomMoreThanAdultsMessage, setShowRoomMoreThanAdultsMessage] = useState(false)

  const increaseAdult = () => {
    setAdult(adult + 1);
  };

  const decreaseAdult = () => {
    if (adult > 1) {
      setAdult(adult - 1);
    }
  };

  const increaseChild = () => {
    setChild(child + 1);
  };

  const decreaseChild = () => {
    if (child > 0) {
      setChild(child - 1);
    }
  }

  const increaseRooms = () => {
    setRooms(rooms + 1);
  }

  const decreaseRooms = () => {
    if (rooms > 1) {
      setRooms(rooms - 1);
    }
  }

  const ITEMS = [
    {
      title: 'Adult',
      decreaseFunc: decreaseAdult,
      increaseFunc: increaseAdult,
      value: adult
    },
    {
      title: 'Children',
      decreaseFunc: decreaseChild,
      increaseFunc: increaseChild,
      value: child
    },
    {
      title: 'Rooms',
      decreaseFunc: decreaseRooms,
      increaseFunc: increaseRooms,
      value: rooms
    }
  ]

  const handleOnSelect = (item) => {
    setSearchDestinationId(item.id)
    setSearchDestinationName(item.name)
    setSearchDestinationIataCode(item.iata_code)
    return `${item.name}`
  }

  const formatDateForPayload = (d) => {
    let date = new Date(d);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${year}-${month}-${day}`
  }

  const handleSearchClick = () => {
    sendGACustomEvent({ category: 'Button Click', action: `Creates itinerary for user and navigate to itinerary page`, label: 'On lets go button click from home page' })
    let userId = sessionStorage.getItem('user_id')
    // check if there is a user id, otherwise set unregistered id as user id:
    if (userId === null) {
      let unregisteredTempId = generateRandomId(8)
      sessionStorage.setItem('user_session_id', unregisteredTempId)
    }
    const tripDetailsPayload = {
      user_id: userId || sessionStorage.getItem('user_session_id'),
      destination_name: searchDestinationName,
      destination_id: searchDestinationId,
      destination_iata_code: searchDestinationIataCode,
      adult: adult,
      child: child,
      start_date: formatDateForPayload(dateRange[0]),
      end_date: formatDateForPayload(dateRange[1]),
      rooms: rooms
    }
    onSearch(tripDetailsPayload)
  }

  const isBtnDisabled = () => {
    let bothDateRanges = dateRange.filter(d => d !== null)
    return (!searchDestinationId || bothDateRanges.length !== 2 || !(adult != 0 || child != 0))
  }

  const handleGuestsAndRoomsAdd = () => {
    if (rooms > (adult + child)) {
      setShowRoomMoreThanAdultsMessage(true)
      return
    }
    setShowRoomMoreThanAdultsMessage(false)
    setShowPersonDropdown(!showPersonsDropdown)
  }

  const renderPersonCountDropdown = () => {
    return (
      <div className='person_count_wrapper'>
        {ITEMS.map((per, i) => {
          return (
            <div key={i} className='person_count_cell'>
              <div className='person_text'>{per.title}</div>
              <div style={{ display: 'flex' }}>
                <div className='person_count_btn' onClick={per.decreaseFunc}>
                  <FontAwesomeIcon icon="fa-solid fa-minus" />
                </div>
                <div className='person_count_val'>{per.value}</div>
                <div className='person_count_btn' onClick={per.increaseFunc}>
                  <FontAwesomeIcon icon="fa-solid fa-plus" />
                </div>
              </div>
            </div>
          )
        })}
        {showRoomMoreThanAdultsMessage ? <div style={{ fontSize: '10px', color: '#D2042D', marginBottom: '5px' }}>Room count cannot be more than guests</div> : null}
        <DefaultButton
          customButtonClass="secondary_button_styling"
          buttonLabel="Done"
          buttonStyle={{ width: '100%' }}
          onClick={() => handleGuestsAndRoomsAdd()}
        />
      </div>
    )
  }

  return (
    <div className='trip_search_wrapper' style={{ top: topPlacement }}>
      <div className='search_actions_wrapper'>
        <SearchAutoComplete
          placeholder='Your Destination'
          items={searchDestinations}
          onSelect={handleOnSelect}
          autoFocus
          maxResults={5}
          onClear={() => setSearchDestinationId('')}
          usage={usage}
        />
        <div>
          <DatePicker
            showIcon
            selectsRange={true}
            startDate={startDate}
            minDate={new Date()}
            showDisabledMonthNavigation
            popperPlacement='top'
            placeholderText='Select your dates'
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update)
            }}
            isClearable
            className='date_range_picker'
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 48 48"
              >
                <mask id="ipSApplication0">
                  <g fill="none" stroke="#fff" strokeLinejoin="round" strokeWidth="4">
                    <path strokeLinecap="round" d="M40.04 22v20h-32V22"></path>
                    <path
                      fill="#fff"
                      d="M5.842 13.777C4.312 17.737 7.263 22 11.51 22c3.314 0 6.019-2.686 6.019-6a6 6 0 0 0 6 6h1.018a6 6 0 0 0 6-6c0 3.314 2.706 6 6.02 6c4.248 0 7.201-4.265 5.67-8.228L39.234 6H8.845l-3.003 7.777Z"
                    ></path>
                  </g>
                </mask>
                <path
                  fill="#9D9696"
                  d="M0 0h48v48H0z"
                  mask="url(#ipSApplication0)"
                ></path>
              </svg>
            }
          />
        </div>
        <div className='person_input_wrapper'>
          <input
            placeholder='Travelers'
            value={
              (adult > 0 ? adult + (adult === 1 ? " Adult" : " Adults") : "") +
              (adult > 0 && ((child > 0) || (rooms > 0)) ? ", " : "") +
              (child > 0 ? child + (child === 1 ? " Child" : " Children") : "") +
              (child > 0 && (rooms > 0) ? ", " : "") +
              (rooms > 0 ? rooms + (rooms === 1 ? " Room" : " Rooms") : "")
            }
            onClick={() => setShowPersonDropdown(!showPersonsDropdown)}
            className='person_input'
          />
          {showPersonsDropdown ? renderPersonCountDropdown() : null}
        </div>
        <DefaultButton
          buttonLabel="Let's Go"
          buttonStyle={{ padding: '10px', width: '10rem' }}
          onClick={handleSearchClick}
          disabled={isBtnDisabled()}
        />
      </div>
    </div>
  )
}

export default TripSearch
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { cancelHotelBooking, cancelRequestEmail } from '../../context/api';
import '../../css/common.scss';
import { RenderHotelCheckDetails, formatDate, sendGACustomEvent } from '../../utils/helpers';
import CustomModal from '../CustomModal/customModal';
import DefaultButton from '../DefaultButton/defaultButton';
import HotelReservationCard from '../HotelCard/hotelReservationCard';
import './bookedHotelCard.scss';
import Loading from '../../pages/Loading/loading';
import { Context } from '../../context/context';

function BookedHotelCard({ hotel }) {
    const { state, setState } = useContext(Context)
    const [showModal, setShowModal] = useState(false)
    const [showCancelModal, setShowCancelModal] = useState(false)
    const [cancelInfo, setCancelInfo] = useState(false)
    const [isCancellationPossible, setIsCancellationPossible] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false)
    const [cancelling, setCancelling] = useState(false)
    const [cancelMessage, setCancelMessage] = useState('')

    useEffect(() => {
        setIsCancellationPossible(checkCancellationAndSetState(hotel.cancellation_timeline));
    }, [hotel.cancellation_timeline]);

    // Function to show refund amount
    function showRefundAmount(cancellationTimeline) {
        const currentDate = new Date(); // Get current date and time

        for (const timeline of cancellationTimeline) {
            const beforeDate = new Date(timeline.before); // Parse 'before' date from timeline
            if (currentDate < beforeDate) {
                return timeline; // Return the valid timeline
            }
        }
        return null; // Return null if no valid timeline found
    }

    // Function to check if cancellation is possible and set cancelInfo state
    function checkCancellationAndSetState(cancellationTimeline) {
        if (!cancellationTimeline || cancellationTimeline.length === 0) {
            // If cancellation timeline is empty or undefined, return false
            return false;
        }

        const currentDate = new Date(); // Get current date and time
        const lastCancellation = cancellationTimeline[cancellationTimeline.length - 1]; // Get the last cancellation timeline

        if (!lastCancellation.before) {
            // If 'before' date is missing, return false
            return false;
        }

        const lastBeforeDate = new Date(lastCancellation.before); // Parse 'before' date

        if (currentDate < lastBeforeDate) {
            const cancelInfo = showRefundAmount(cancellationTimeline); // Get the valid timeline and refund amount
            if (cancelInfo) {
                // Set the cancelInfo state only once, outside the loop
                setCancelInfo(cancelInfo); // Set the cancelInfo state
                return true; // Return true if cancellation is possible
            }
        }

        return false; // Return false if no valid timeline found or cancellation not possible
    }

    const handleCancellation = (hotel) => {
        sendGACustomEvent({ category: 'Button Click', action: `Cancelling Hotel Booking for: ${hotel.booking_reference}`, label: 'Cancel Hotel booking button click' })
        setCancelling(true)
        const user = JSON.parse(sessionStorage.getItem('user'))
        cancelHotelBooking({ id: hotel.id, email: user.email, reference: hotel.booking_reference })
            .then((res) => {
                setIsCancelled(true)
                setCancelling(false)
                setCancelMessage("Your Hotel Cancelled Successfully. We will refund the amount in 6 business days")
                // setState(prevState => ({ ...prevState, hotelDetails: {} }))
                // LeadDyno.recordCancellation()
            })
    }

    const handleRequestCancellation = () => {
        sendGACustomEvent({ category: 'Button Click', action: `Send request to cancel hotel booking for: ${hotel.booking_reference}`, label: 'Request Hotel Cancelation' })
        setShowModal(false)
        setShowCancelModal(true)
        setCancelling(true)
        const user = JSON.parse(sessionStorage.getItem('user'))
        cancelRequestEmail({ email: user.email, reference: hotel.booking_reference, case: "Hotel" })
            .then((res) => {
                setIsCancelled(true)
                setCancelling(false)
                setCancelMessage("Your request has been successfully been submitted. A member of our team will reach out to you within 72 hours.")
                setCancelling(false)
            })
    }

    const renderFooter = () => {
        return (
            <div className='d-flex justify-content-end'>
                {
                    isCancellationPossible ?
                        <DefaultButton
                            buttonLabel={"Cancel Hotel"}
                            customButtonClass="inverted_secondary_button_styling"
                            onClick={() => { setShowCancelModal(true); setShowModal(false); sendGACustomEvent({ category: 'Button Click', action: 'Cancels hotel booking', label: 'Cancel Hotel Button Click' }) }}
                        /> :
                        <div style={{ textAlign: 'end' }}>
                            <DefaultButton
                                buttonLabel={"Contact Support"}
                                customButtonClass="inverted_secondary_button_styling"
                                onClick={() => { handleRequestCancellation() }}
                            />
                            <div style={{ fontSize: '10px', color: '#636363', marginTop: '5px' }}>Click the button to alert our support team and someone will reach out to you shortly.</div>
                        </div>
                }
            </div>
        )
    }

    return (
        <div className='booked_hotel_wrapper_card'>
            <div className='booked_hotel_top_banner'>Your Stay is Booked</div>
            <div className='hotel_upper_card'>
                <div style={{ width: '25%' }}>
                    <img src={hotel.image[0].url} alt={hotel.name} className='hotel_booked_image' />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '22px' }}>
                    <div style={{ fontWeight: '700', fontSize: '18px', marginBottom: '8px' }}>{hotel.name}</div>
                    <div className='icon_subtext_card_info'><strong style={{ marginRight: '5px' }}>Booking Reference:</strong> {hotel.booking_reference}</div>
                    <div className='icon_subtext_card_info'>
                        <FontAwesomeIcon icon="fa-solid fa-map-location-dot" style={{ marginRight: '8px' }} />
                        <div className='mb-0 pb-0'>{hotel.location.line_one}, {hotel.location.city_name}</div>
                    </div>
                    <div className='icon_subtext_card_info'>
                        <FontAwesomeIcon icon="fa-solid fa-calendar-days" style={{ marginRight: '8px' }} />
                        <div><strong>Check In : </strong><RenderHotelCheckDetails date={formatDate(hotel.check_in_date)} time={hotel.check_in_time} instruction="beafterfore" /></div>
                    </div>
                    <div className='icon_subtext_card_info'>
                        <FontAwesomeIcon icon="fa-solid fa-calendar-days" style={{ marginRight: '8px' }} />
                        <div className='mb-0 pb-0'><strong>Check Out : </strong><RenderHotelCheckDetails date={formatDate(hotel.check_out_date)} time={hotel.check_out_time} instruction="before" /></div>
                    </div>
                </div>
            </div>
            <div className='flight_hotel_lower_card'>
                <div className='total_paid'>Total Paid: <span>${hotel.total_amount}</span></div>
                <div className='booked_hotel_flight_btn_wrapper'>
                    <DefaultButton
                        buttonLabel={"View Details"}
                        customButtonClass="secondary_button_styling"
                        buttonStyle={{ marginRight: '10px' }}
                        onClick={() => { setShowModal(true); sendGACustomEvent({ category: 'Button Click', action: 'Opens modal to show details for booked hotel', label: 'Click on View Details Card for Booked Hotel' }) }}
                    />
                </div>
            </div>
            <CustomModal
                title={"Booking Details"}
                showModal={showModal}
                setShowModal={setShowModal}
                onHide={() => setShowModal(false)}
                modalFooter={renderFooter()}
                centered={true}
            >
                <HotelReservationCard reservationDetails={hotel} />
            </CustomModal>

            <CustomModal
                title={`Cancel Booking : ${hotel.booking_reference}`}
                showModal={showCancelModal}
                setShowModal={setShowCancelModal}
                centered={true}
            >
                {
                    cancelling ?
                        <Loading pageHeight={"250px"} spinnerStyle={"classic"} /> :
                        <div className='cancellation_modal_body'>
                            {
                                !isCancelled ?
                                    <div>
                                        <div className='cancellation_content'>
                                            <div className='cancellation_refund_title'>Refund Amount: </div>
                                            <div className='cancellation_refund_amount'>&nbsp; ${cancelInfo.refund_amount}</div>
                                        </div>
                                        <div className='cancellation_refund_disclaimer'>
                                            By cancelling this booking, you will
                                            no longer be able to check in.
                                            This action cannot be undone.
                                        </div>
                                        <div style={{ marginTop: '18px' }}>
                                            <Accordion className='itinerary_accordian'>
                                                {
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>Refund Policy</Accordion.Header>
                                                        <Accordion.Body>
                                                            {hotel.conditions
                                                                .filter(condition => condition.title === "Refund Policy") // Filter conditions with title "Refund Policy"
                                                                .map((condition, i) => (
                                                                    <div key={i}>
                                                                        <div style={{ fontSize: '12px', marginBottom: '16px' }}>{condition.description}</div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                }
                                            </Accordion>

                                        </div>
                                    </div> :
                                    <div className='cancellation_refund_message'>{cancelMessage}</div>
                            }
                            {
                                !isCancelled ?
                                    <div className='action_button_container'>
                                        <div className='row cancel_dismiss_button'>
                                            <DefaultButton
                                                buttonLabel={"Dismiss"}
                                                buttonStyle={{ marginTop: '12px' }}
                                                customButtonClass="inverted_secondary_button_styling"
                                                onClick={() => { setShowCancelModal(false); sendGACustomEvent({ category: 'Button Click', action: 'clicking dismiss button', label: 'Click on Dismiss button after hotel booking' }) }}
                                            />
                                        </div>
                                        <div className='row cancel_confirm_button'>
                                            <DefaultButton
                                                buttonLabel={"Confirm"}
                                                customButtonClass="secondary_button_styling"
                                                onClick={() => { handleCancellation(hotel) }}
                                            />
                                        </div>
                                    </div> :
                                    <div className='action_button_container'>
                                        <div className='row cancel_confirm_button'>
                                            <DefaultButton
                                                buttonLabel={"Close"}
                                                customButtonClass="secondary_button_styling"
                                                onClick={() => {
                                                    setShowCancelModal(false);
                                                    isCancellationPossible && setState(prevState => ({ ...prevState, hotelDetails: {} }));
                                                    sendGACustomEvent({ category: 'Button Click', action: 'Closes booked hotel card', label: 'Close button from booked hotels view details modal' })
                                                }}
                                            />
                                        </div>
                                    </div>
                            }
                        </div>}
            </CustomModal>
        </div>
    )
}

export default BookedHotelCard
import React from 'react';
import './footer.scss';

function Footer() {
    return (
        <footer className="footer">
            <div style={{ display: 'flex', columnGap: '10px' }}>
                <a href="/about">About</a>
                <a href="/privacy">Privacy</a>
                <a href="/contact ">Contact Us</a>
                <a href="/terms ">Terms Of Use</a>
            </div>
            <div>Made with 💪🏽 in San Diego, California</div>
            <a href="https://www.vecteezy.com/free-vector/travel">Travel Vectors by Vecteezy</a>
            <div>© 2024 WellPlanned Inc. All rights reserved.</div>
        </footer>
    )
}

export default Footer;
import React from 'react'
import Carousel from 'react-multi-carousel'

function CustomCarousel({ children, responsiveBreakpoints }) {
    return (
        <div>
            <Carousel
                swipeable={true}
                draggable={false}
                showDots={false}
                responsive={responsiveBreakpoints}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={false}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}>
                {children}
            </Carousel>
        </div >
    )
}

export default CustomCarousel
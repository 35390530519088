import React, { useContext, useEffect, useState } from 'react';
import { cancelRequestEmail, confirmFlightCancellation, getFlightRefundQuote } from '../../context/api';
import { formatDateAndTime, formatDuration, formatStops, sendGACustomEvent } from '../../utils/helpers';
import DefaultButton from '../DefaultButton/defaultButton';
import CustomModal from '../CustomModal/customModal';
import './flightBookedCard.scss';
import '../../css/flightCommon.scss';
import Loading from '../../pages/Loading/loading';
import FlightJourneyCard from '../FlightJourneyCard/flightJourneyCard';
import { Context } from '../../context/context';

function FlightBookedCard({ flight }) {
    const { state, setState } = useContext(Context)
    const [showModal, setShowModal] = useState(false)
    const [showCancelModal, setShowCancelModal] = useState(false)
    const [cancelInfo, setCancelInfo] = useState(false)
    const [isCancellationPossible, setIsCancellationPossible] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false)
    const [cancelling, setCancelling] = useState(false)
    const [cancelMessage, setCancelMessage] = useState('')

    const outboundFlight = flight[0].outbound
    const inboundFlight = flight[0].inbound

    useEffect(() => {
        if (flight.available_actions && flight.available_actions.length > 0 && flight.available_actions.includes("cancel")) {
            setIsCancellationPossible(true)
        }
    }, [flight.available_actions]);

    const fetchCancellationQuote = () => {
        sendGACustomEvent({ category: 'Button Click', action: `fetches cancellation quotes for flight.id = ${flight.id}`, label: 'Click on Cancel Flight button' })
        getFlightRefundQuote({ order_id: flight.id }).then((res) => {
            setCancelInfo(res)
        })
    }

    const handleCancellation = () => {
        sendGACustomEvent({ category: 'Button Click', action: `Flight Cancelation for = ${flight.booking_reference}`, label: 'Click on Flight Cancelation Button' })
        setCancelling(true)
        const user = JSON.parse(sessionStorage.getItem('user'))
        confirmFlightCancellation({ order_id: cancelInfo.id, email: user.email, reference: flight.booking_reference }).then((res) => {
            setIsCancelled(true)
            setCancelMessage("Your Flight Cancelled Successfully. We will refund the amount in 6 business days")
            setCancelling(false)
            // LeadDyno.recordCancellation()
        })
    }

    const handleRequestCancellation = () => {
        sendGACustomEvent({ category: 'Button Click', action: "Send request cancelation request for light", label: 'Click on Contact Support button' })
        setShowModal(false)
        setShowCancelModal(true)
        setCancelling(true)
        const user = JSON.parse(sessionStorage.getItem('user'))
        cancelRequestEmail({ email: user.email, reference: flight[0].booking_reference, case: "Flight" })
            .then((res) => {
                setIsCancelled(true)
                setCancelling(false)
                setCancelMessage("Your request has been successfully been submitted. A member of our team will reach out to you within 72 hours.")
                setCancelling(false)
            })
    }

    const renderLocationInfo = (flightInfo, dateAndTime) => {
        return (
            <div className='location_info'>
                <div style={{ fontSize: '16px', fontWeight: '700' }}>{flightInfo.iata_code}</div>
                <div style={{ color: '#636363', fontWeight: '500' }}>
                    <span style={{ marginRight: '8px', fontSize: '13px' }}>{formatDateAndTime(dateAndTime, 'Date')}</span>
                    <span style={{ fontSize: '13px' }}>{formatDateAndTime(dateAndTime, 'Time')}</span>
                </div>
            </div>
        )
    }

    const renderFlightSummary = (flight) => {
        let firstSegment = flight.segments[0]
        let lastSegment = flight.segments[flight.segments.length - 1]
        return (
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: '16px' }}>
                <div style={{ width: '100%' }}>{renderLocationInfo(firstSegment.origin, firstSegment.departing_at)}</div>
                <div className='airline_arrow_container' style={{ width: '100%' }}>
                    <div style={{ fontSize: '12px', fontWeight: '600', marginTop: '5px' }}>{formatDuration(flight.duration)}</div>
                    <div className="arrow_right" />
                    <div style={{ fontSize: '12px', fontWeight: '600', marginTop: '5px' }}>{formatStops(flight.segments.length - 1)}</div>
                </div>
                <div style={{ width: '100%', textAlign: 'end' }}>{renderLocationInfo(lastSegment.destination, lastSegment.arriving_at)}</div>
            </div>
        )
    }

    const renderFooter = () => {
        return (
            <div className='d-flex justify-content-end'>
                {
                    isCancellationPossible ?
                        <DefaultButton
                            buttonLabel={"Cancel Flight"}
                            customButtonClass="inverted_secondary_button_styling"
                            onClick={() => { fetchCancellationQuote(flight); setShowCancelModal(true); }}
                        /> :
                        <div style={{ textAlign: 'end' }}>
                            <DefaultButton
                                buttonLabel={"Contact Support"}
                                customButtonClass="inverted_secondary_button_styling"
                                onClick={() => { handleRequestCancellation() }}
                            />
                            <div style={{ fontSize: '10px', color: '#636363', marginTop: '5px' }}>Click the button to alert our support team and someone will reach out to you shortly.</div>
                        </div>

                }
            </div>
        )
    }

    return (
        <div className='booked_flight_wrapper_card'>
            <div className='booked_hotel_top_banner'>Your Flight is Booked</div>
            <div style={{ fontSize: '14px', margin: '12px 0 0 8px' }}><strong>Booking Reference:</strong> {flight[0].booking_reference}</div>
            <div className='flight_top_card'>
                {renderFlightSummary(outboundFlight)}
                {renderFlightSummary(inboundFlight)}
            </div>
            <div className='flight_hotel_lower_card'>
                <div className='total_paid'>Total Paid: ${flight[0].wp_total_amount}</div>
                <div className='booked_hotel_flight_btn_wrapper'>
                    <DefaultButton
                        buttonLabel={"View Details"}
                        customButtonClass="secondary_button_styling"
                        buttonStyle={{ marginRight: '10px' }}
                        onClick={() => { setShowModal(true); sendGACustomEvent({ category: 'Button Click', action: "Opens View Details modal for booked flight", label: 'Click on View Details button for flight' }) }}
                    />

                </div>
            </div>
            <CustomModal
                title={"Booking Details"}
                showModal={showModal}
                setShowModal={setShowModal}
                modalFooter={renderFooter()}
                centered={true}
                onHide={() => setShowModal(false)}
            >
                <FlightJourneyCard flight={flight[0]} />
            </CustomModal >
            <CustomModal
                title={`Cancel Booking : ${flight[0].booking_reference}`}
                showModal={showCancelModal}
                setShowModal={setShowCancelModal}
                centered={true}
                onHide={() => setShowCancelModal(false)}
            >
                <div>
                    {
                        cancelling ?
                            <Loading pageHeight={"250px"} spinnerStyle={"classic"} /> :
                            <div className='cancellation_modal_body'>
                                {
                                    !isCancelled ?
                                        <div>
                                            <div className='cancellation_content'>
                                                <div className='cancellation_refund_title'>Refund Amount: </div>
                                                <div className='cancellation_refund_amount'>&nbsp; ${cancelInfo.refund_amount}</div>
                                            </div>
                                            <div className='cancellation_refund_disclaimer'>
                                                By cancelling this booking, you
                                                will no longer be registered on this
                                                flight. This action cannot be undone.
                                            </div>
                                        </div> :
                                        <div className='cancellation_refund_message'>{cancelMessage}</div>
                                }
                                {
                                    !isCancelled ?
                                        <div className='action_button_container'>
                                            <div className='row cancel_dismiss_button'>
                                                <DefaultButton
                                                    buttonLabel={"Dismiss"}
                                                    customButtonClass="inverted_secondary_button_styling"
                                                    onClick={() => { setShowCancelModal(false); sendGACustomEvent({ category: 'Button Click', action: `Dismiss button click for flight cancelation`, label: 'Dismiss button click for flight cancelatio' }) }}
                                                />
                                            </div>
                                            <div className='row cancel_confirm_button'>
                                                <DefaultButton
                                                    buttonLabel={"Confirm"}
                                                    customButtonClass="secondary_button_styling"
                                                    onClick={() => { handleCancellation() }}
                                                />
                                            </div>
                                        </div> :
                                        <div className='action_button_container'>
                                            <div className='row cancel_confirm_button'>
                                                <DefaultButton
                                                    buttonLabel={"Close"}
                                                    customButtonClass="secondary_button_styling"
                                                    onClick={() => {
                                                        setShowCancelModal(false)
                                                        isCancellationPossible && setState(prevState => ({ ...prevState, flightDetails: {} }));
                                                        sendGACustomEvent({ category: 'Button Click', action: `Clicking close button on booked flight card`, label: 'Clicking close button on booked flight' })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                }
                            </div>
                    }
                </div>
            </CustomModal>
        </div >
    )
}

export default FlightBookedCard;

import { createContext, useState } from "react";

export const Context = createContext();

export const Store = ({ children }) => {
    const [state, setState] = useState({
        tripDetails: {},
        tripItinerary: {},
        userInfo: {},
        flightDetails: {},
        hotelDetails: {},
    })

    return (
        <Context.Provider value={{ state, setState }}>
            {children}
        </Context.Provider>
    )
}

export default Store
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useNavigate, useParams } from 'react-router-dom'; // Import useParams
import BlogCard from '../../components/BlogCard/blogCard';
import NavBar from '../../components/Navbar/navbar';
import { fetchAllBlogs, fetchBlogById } from '../../context/api';
import './blog.scss';
import Loading from '../Loading/loading';
import Footer from '../../components/Footer/footer';
import { sendGAPageEvent } from '../../utils/helpers';

function Blogs() {
    const [loading, setLoading] = useState(false);
    const [fetchingBlog, setFetchingBlog] = useState(false)
    const [markdownContent, setMarkdownContent] = useState(null);
    const [blogList, setBlogList] = useState([])
    const [blog, setBlog] = useState()
    const { blogId } = useParams(); // Extract blogId from URL
    const navigate = useNavigate();

    useEffect(() => {
        if (blogId) {
            setFetchingBlog(true)
            fetchBlogById(blogId).then((res) => {
                setBlog(res)
                setFetchingBlog(false)
            })
        }
    }, [blogId]); // Trigger effect when blogId changes

    useEffect(() => {
        sendGAPageEvent({ hitType: 'pageview', page: '/blogs', title: 'Landing on blog page' })
        setFetchingBlog(true)
        fetchAllBlogs().then((res) => {
            setBlogList(res)
            setFetchingBlog(false)
        })
    }, [])

    return (
        <div>
            <NavBar getLoadingState={val => setLoading(val)} />
            {blogId ? (
                <div>
                    {
                        fetchingBlog ?
                            <Loading pageHeight={'70vh'} spinnerStyle={'classic'} /> :
                            <div className='blogpost my-sm-2 my-md-5'>
                                <div className='blogpost_back_btn' onClick={() => navigate(-1)}>
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" /> &nbsp; Back
                                </div>
                                {blog && <ReactMarkdown>{blog.content}</ReactMarkdown>}
                            </div>
                    }
                </div>
            ) : (
                <div>
                    <div className='blog_header'>
                        <p className='blog_header_title'>Blogs</p>
                        <p className='blog_header_helper_text'>Read what all the rave is about</p>
                    </div>
                    {
                        fetchingBlog ?
                            <Loading pageHeight={'70vh'} spinnerStyle={'classic'} /> :
                            <div className='travel_blog'>
                                {blogList.map((post) => (
                                    <BlogCard key={post.id} post={post} />
                                ))}
                            </div>}
                </div>
            )}
            <Footer />
        </div>
    );
}

export default Blogs;

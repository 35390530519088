import './inputField.scss'
import Form from 'react-bootstrap/Form'

function InputField({ id, type = 'text', value, onChange, placeholder, inputStyling, onClick, isError, showErrorText }) {
    return (
        <div style={inputStyling} className={isError ? "error_field_wrapper" : "input_field_wrapper"}>
            <Form.Group>
                <Form.Control
                    id={id}
                    type={type}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    onClick={onClick}
                />
            </Form.Group>
            {isError && <div className='error_text'>{showErrorText}</div>}
        </div>
    )
}

export default InputField
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { capitalizeFirstLetter, sendGACustomEvent } from '../../utils/helpers';
import DefaultButton from '../DefaultButton/defaultButton';
import HotelPolicies from '../HotelPolicies/hotelPolicies';
import './hotelRoomCard.scss';
import { useNavigate } from 'react-router-dom';
import { updateSessionData } from '../../context/api';

function HotelRoomCard({ room, hotel, setRateId, setHotelDetails, setView }) {

    const [showModal, setShowModal] = useState(false)
    const navigate = useNavigate()

    const saveRoomDetails = () => {
        const deets = JSON.parse(sessionStorage.getItem('tripDetails'))
        updateSessionData({ trip_id: deets.trip_id, session_data: { hotel_details: { ...hotel, room_details: [room] } } })
            .then((res) => {
                setHotelDetails({ ...hotel, room_details: [room] });
                setRateId(room.rates[0].id);
                setView('checkout');
                navigate('/hotels/customer')
            })
    }

    return (
        <div className='room_card card m-2'>
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Cancellation and Booking Policies</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HotelPolicies policies={room.rates[0]} />
                </Modal.Body>
            </Modal>
            <div className='card-body d-flex flex-column'>
                <img src={room.photo.url} width="265px" style={{ borderRadius: '8px', marginBottom: '12px' }} />
                <div className='room_name_container'>
                    <div class="card-title">{room.name}</div>
                </div>
                <div className='room_facilities_container'>
                    {
                        room.beds && room.beds.length > 0 &&
                        <div className='room_facility'>
                            <FontAwesomeIcon icon="fa-solid fa-bed" style={{ width: '30px', marginRight: '5px', fontSize: '14px', color: '#035EDD' }} />
                            {room.beds[0].count}  {capitalizeFirstLetter(room.beds[0].type)} {room.beds[0].count === 1 ? 'bed' : 'beds'}
                        </div>
                    }
                </div>
                <div className='room_rates_container'>
                    <div style={{ fontSize: '10px', fontWeight: 300 }}>{hotel.adults} {hotel.adults === 1 ? 'Adult' : 'Adults'}, {hotel.rooms} {hotel.rooms === 1 ? 'Room' : 'Rooms'}</div>
                    <div className='fs-5 fw-bold mb-0 pb-0'>${room.rates[0].total_amount}</div>
                    <div style={{ fontSize: '10px' }}> Includes taxes and fees</div>
                </div>
            </div>
            <div className='card-footer'>
                <DefaultButton
                    buttonLabel={"Book Now"}
                    customButtonClass="secondary_button_styling"
                    buttonStyle={{ width: '100%' }}
                    onClick={() => {
                        setHotelDetails({ ...hotel, room_details: [room] });
                        setRateId(room.rates[0].id);
                        setView('checkout');
                        navigate('/hotels/customer');
                        sendGACustomEvent({ category: 'Button Click', action: `Book Now button click on hotel room card`, label: 'Book Now button click on hotel room card' })
                    }}
                />
            </div>
        </div >
    )
}

export default HotelRoomCard
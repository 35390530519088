import { useEffect, useState } from 'react'
import './loading.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PropagateLoader } from 'react-spinners'

function Loading({ pageHeight, spinnerStyle }) {
    const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0)

    const options = [
        {
            value: 'Gathering data',
            icon: 'fa solid fa-list-check'
        },
        {
            value: 'Selecting your restaurants',
            icon: 'fa-solid fa-utensils'
        },
        {
            value: 'Choosing your attractions',
            icon: 'fa-solid fa-umbrella-beach',
        },
        {
            value: 'Mapping it all out',
            icon: 'fa-solid fa-map-location-dot'
        },
        {
            value: 'Putting it all together',
            icon: 'fa-solid fa-route'
        }
    ]

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % options.length)
        }, 4000)
        return () => {
            clearInterval(interval)
        }
    }, [])

    return (
        <div className="loadingWrapper" style={{ height: pageHeight || '100vh' }}>
            {spinnerStyle = 'classic' ?
                <PropagateLoader color="#FD905E" />
                :
                <div>
                    <FontAwesomeIcon icon={options[currentPhraseIndex].icon} style={{ fontSize: '50px', color: '#FD905E', marginBottom: '16px' }} />
                    <div className='loadingText'>{options[currentPhraseIndex].value}</div>
                </div>}
        </div>
    )
}

export default Loading
import './customizer.scss'

import { useState } from 'react'
import InputField from '../InputField/inputField'
import DefaultButton from '../DefaultButton/defaultButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { sendGACustomEvent } from '../../utils/helpers'
import ReactLoader from '../Loader/loader'

function Customizer({ placeholder, inputStyling, onCustomizeClick, loading }) {
    const [userInput, setUserInput] = useState('')
    const [showDropdown, setShowDropdown] = useState(false)

    const onBtnClick = () => {
        sendGACustomEvent({
            category: 'Button Click',
            action: 'Customize Itinerary',
            label: 'Update button'
        })
        onCustomizeClick(userInput)
        setUserInput('')
    }

    return (
        <div className='customizer_wrapper'>
            <div>
                <InputField
                    onClick={() => setShowDropdown(true)}
                    placeholder={placeholder}
                    inputStyling={inputStyling}
                    value={userInput}
                    onChange={(e) => { setUserInput(e.target.value); setShowDropdown(false) }}
                />
                {showDropdown ? <div className='customize_dropdown'>
                    <FontAwesomeIcon
                        icon="fa-solid fa-times"
                        style={{ fontSize: '16px', cursor: 'pointer', marginLeft: 'auto' }}
                        onClick={() => setShowDropdown(false)}
                    />
                    <div>💡 You can say things like:</div>
                    <div className='dropdown_suggestion'>"I am vegan"</div>
                    <div className='dropdown_suggestion'>"I'd like to visit a couple beaches"</div>
                    <div className='dropdown_suggestion'>"I'd like seafood for lunch on day 2"</div>
                </div> : null}
            </div>
            <DefaultButton
                onClick={onBtnClick}
                buttonLabel={loading ? <div className='d-flex align-items-center'>Updating &nbsp; <ReactLoader loading={loading} size={20} /></div> : 'Update'}
                customButtonClass="secondary_button_styling"
                disabled={loading}
            />
 
        </div>
    )
}

export default Customizer
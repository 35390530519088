import './defaultButton.scss'
import Button from 'react-bootstrap/Button'

function DefaultButton({ buttonLabel, onClick, width, disabled, customButtonClass, buttonStyle }) {
    return (
        <Button
            style={buttonStyle}
            className={customButtonClass || 'default_button_styling'}
            disabled={disabled}
            onClick={onClick}>
            {buttonLabel}
        </Button>
    )
}

export default DefaultButton